import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginflowService } from 'src/app/data/loginflow.service';
import { ServicesService } from 'src/app/services.service';

@Component({
  selector: 'app-pay-success',
  templateUrl: './pay-success.component.html',
  styleUrls: ['./pay-success.component.scss'],
})
export class PaySuccessComponent implements OnInit {
  constructor(
    public service: ServicesService,
    public router: Router,
    public ActiveRoute: ActivatedRoute,
    public LoginflowService: LoginflowService,
  ) {
    this.ActiveRoute.params.subscribe(async (params) => {
      try {
        let email = params['data'];
        this.getUserDetails(email);
      } catch (error) {
        this.router.navigate(['/login']);
      }
    });
  }
  ngOnInit(): void {}
  async getUserDetails(email: string) {
    await this.LoginflowService.fetch_user_payment(email).then(async (data) => {
      if (data[0].status == 200) {
        var list = JSON.parse(<string>data[0].json);
        if (list.data.pay == true) {
          window.location.replace(list.data.link);
        } else {
          this.service.ShowError('Invalid link');
          this.router.navigate(['/login']);
        }
      } else {
        this.service.ShowError('Invalid link');
        this.router.navigate(['/login']);
      }
    });
  }
}
