import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';
import { ServicesService } from '../services.service';
import { AuthserviceService } from '../authservice.service';
import { CourseserviceService } from '../services/course/courseservice.service';
import { SharedDataService } from '../services/SharedData/shared-data.service';

@Component({
  selector: 'app-enrolledcourse',
  templateUrl: './enrolledcourse.component.html',
  styleUrls: ['./enrolledcourse.component.scss'],
})
export class EnrolledcourseComponent implements OnInit {
  courselist = [];
  userid: any;
  date: any;
  Enrollnofound = false;
  AvailableCourse = false;
  public data: any;
  name: any;
  imgurl: any;
  availabelcourse = [];
  domainsData = [];
  Search: any;
  noFound = false;
  CourseSelectionPagePopup: boolean = false;
  CertificationItem: any;
  constructor(
    public auth: AuthserviceService,
    public service: ServicesService,
    public spinner: NgxSpinnerService,
    public ActiveRoute: ActivatedRoute,
    private route: ActivatedRoute,
    public router: Router,
    public CourseserviceService: CourseserviceService,
    public sharedDataService: SharedDataService,
  ) {
    var setlogin = this.auth.isLoggedIn();
    if (!setlogin) {
      this.router.navigate(['/login']);
    }
    this.service.headershow = false;
    this.service.SearchValue = true;
    this.service.title = '';

    this.service.title = "<p class='HeaderUser'><span>Courses</span></p>";
    this.userid = localStorage.getItem('CisspUserId');
  }
  gotoSearch() {
    if (this.router.url != '/sidenav/search') {
      this.router.navigate(['/sidenav/search']);
    }
  }
  async logout() {
    var data = JSON.parse(window.localStorage.getItem('userdetails'));
    await this.service.setOfflineUser(data.email).then(async (res) => {
      await this.auth.logOut();
      this.sharedDataService.clearSharedData();
      await this.spinner.hide();
      window.localStorage.clear();
      window.sessionStorage.clear();
      await this.router.navigate(['/login']);
    });
  }
  async ngOnInit() {
    var setlogin = this.auth.isLoggedIn();
    if (!setlogin) {
      this.router.navigate(['/login']);
    }
    this.date = new Date().toISOString();
    await this.spinner.show();
    var data = localStorage.getItem('userdetails');
    if (data != undefined || data != null || data != '') {
      this.data = JSON.parse(<string>data);
      this.name = this.data.aliasname ? this.data.aliasname : this.data.name;
      this.imgurl = this.data.avatar_url;
    }
    await this.listCourses();
  }
  async getResult(cid, cohort_id) {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        let user_id = localStorage.getItem('CisspUserId');
        this.spinner.show();
        await this.CourseserviceService.getDashboardPercentage(
          user_id,
          cid,
          cohort_id,
          token,
        ).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.domainsData = list.data;
            let sum = 0;
            let i = 0;
            let w = [9, 11, 12, 12, 12, 12, 12, 12];
            this.domainsData.forEach((element) => {
              element.weight = w[i];
              i++;
              sum += element.totalPro;
            });
            localStorage.setItem(
              'domainsData',
              JSON.stringify(this.domainsData),
            );
            this.spinner.hide();
          } else {
            this.spinner.hide();
          }
        });
      }
    });
  }
  async listCourses() {
    this.courselist = [];
    this.availabelcourse = [];
    try {
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();
            await this.CourseserviceService.knowassquecource(
              this.userid,
              token,
            ).then(async (data) => {
              if (data[0].status == 200) {
                var list = JSON.parse(<string>data[0].json);
                list.data.forEach((element) => {
                  var now = new Date(element.start_date).getTime();
                  var countDownDate = new Date(element.overdue_date).getTime();
                  var distance = countDownDate - now;
                  var days = Math.floor(distance / (1000 * 60 * 60 * 24));
                  if (!days) {
                    days = 0;
                  }
                  element['day'] = Math.abs(days);
                  if (element.access_duration != 0) {
                    element['day'] = element.access_duration;
                  }
                  if (element.course_type == null)
                    element['img_path'] = element.live_course_thumbnail;
                  else element['img_path'] = element.self_course_thumbnail;
                });

                this.courselist = list.data;
              }
              if (this.courselist.length > 0) {
                this.noFound = false;
              } else {
                this.noFound = true;
              }
              await this.spinner.hide();
            });
          }
        });
    } catch (e) {
      await this.spinner.hide();
    }
  }
  async CertificationClick(item) {
    this.CourseSelectionPagePopup = true;
    this.CertificationItem = item;
  }
  enroll(item, index) {
    this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        this.availabelcourse.splice(index, 1);
        item.enrolled = true;
        this.courselist.push(item);
        await this.CourseserviceService.Enrollusercourse(
          item.cource.id,
          this.userid,
          token,
        );
      }
    });
  }
  async ChangeCohortIdFunc() {
    try {
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();
            let course_id = this.CertificationItem.cohort_id;
            await this.CourseserviceService.ChangeCohortId(
              this.data.email,
              course_id,
              token,
            ).then(async (data) => {
              if (data[0].status == 200) {
                var list = JSON.parse(<string>data[0].json);
              }
              await this.spinner.hide();
            });
          }
        });
    } catch (e) {
      await this.spinner.hide();
      this.service.ShowError(e.meassage);
    }
  }
  async CourseSelectionFunc(element) {
    this.sharedDataService.clearSharedData();
    this.CourseSelectionPagePopup = false;
    this.service.courseid = this.CertificationItem.certi_id;
    await this.getResult(
      this.CertificationItem.certi_id,
      this.CertificationItem.cohort_id,
    );
    localStorage.setItem('CisspCourseId', this.CertificationItem.certi_id);
    localStorage.setItem('cohort_id', this.CertificationItem.cohort_id);
    localStorage.setItem('overall', this.CertificationItem.percentage);
    localStorage.setItem('method', this.CertificationItem.method_id);
    localStorage.setItem(
      'course_type',
      this.CertificationItem.course_type
        ? this.CertificationItem.course_type
        : '',
    );
    window.localStorage.setItem(
      'accessitem',
      JSON.stringify(this.CertificationItem.access),
    );
    var uri = this.route.snapshot.queryParams['returnUrl'] || null;
    if (uri) {
      this.router.navigateByUrl(uri);
    } else {
      if (this.CertificationItem.access.show_resources == 1) {
        if (element == 'Yes') {
          this.service.instructionHint = false;
          await this.router.navigate([`/sidenav/dashboard`]);
          await this.ChangeCohortIdFunc();
        } else if (element == 'No') {
          await this.router.navigate([`/sidenav/dashboard`]);
        }
      } else {
        this.service.instructionHint = true;
        await this.auth
          .getAuthenticatedUser()
          .getSession(async (err, session) => {
            if (err) {
              this.spinner.hide();
              this.router.navigate(['/login']);
            } else {
              var token = session.getIdToken().getJwtToken();
              let user_id = localStorage.getItem('CisspUserId');
              this.spinner.show();
              await this.CourseserviceService.updateResourceShow(
                user_id,
                token,
              ).then(async (data) => {
                this.spinner.hide();
                await this.router.navigate([`/sidenav/resource`]);
              });
            }
          });
      }
    }
  }
}
