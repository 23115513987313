import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Http, Headers, RequestOptions } from '@angular/http';
import { CryptoService } from '../crypto/crypto.service';
@Injectable({
  providedIn: 'root',
})
export class KnowledgeserviceService {
  baseurl = environment.baseurl;
  constructor(
    public http: Http,
    private crypto: CryptoService,
  ) {}

  async ListKnowAssCerfication(user_id, certi_id, cohort_id, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          user_id: user_id,
          cource_id: certi_id,
          cohort_id: cohort_id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + 'meeting/get_know_report';

      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async getKnowledgeQuestions(
    ass_id: number,
    user_id: number,
    cohort_id,
    token,
    RowNumber,
  ) {
    let method_id = localStorage.getItem('method');
    let method_type = localStorage.getItem('course_type');
    let material_type = 'Video';

    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          ass_id: ass_id,
          user_id: user_id,
          cohort_id: cohort_id,
          method_id: method_id,
          method_type: method_type,
          material_type: material_type,
          RowNumber: RowNumber,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + 'meeting/filter_knowledge_question';
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err }]);
        },
      );
    });
  }
  async FilterKnowQueResultShow(
    ass_id: number,
    user_id: number,
    cohort_id,
    token,
    RowNumber,
  ) {
    let method_id = localStorage.getItem('method');
    let method_type = localStorage.getItem('course_type');
    let material_type = 'Video';

    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          ass_id: ass_id,
          user_id: user_id,
          cohort_id: cohort_id,
          method_id: method_id,
          method_type: method_type,
          material_type: material_type,
          RowNumber: RowNumber,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + 'knowassque/filter-ques';
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err }]);
        },
      );
    });
  }
  async getKnowResultPagination(
    ass_id: number,
    user_id: number,
    cohort_id,
    token,
    RowNumber,
    _pageNumber,
    _pageSize,
  ) {
    const pageNumber = 1;
    const pageSize = 1000;
    let method_id = localStorage.getItem('method');
    let method_type = localStorage.getItem('course_type');
    let material_type = 'Video';

    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          ass_id: ass_id,
          user_id: user_id,
          cohort_id: cohort_id,
          method_id: method_id,
          method_type: method_type,
          material_type: material_type,
          RowNumber: RowNumber,
          pageNumber: pageNumber,
          pageSize: pageSize,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + 'meeting/knowledge_result_pagination';
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err }]);
        },
      );
    });
  }
  async AnswerKnowAss(
    KAQ_id,
    ans,
    ass_id,
    user_id,
    token,
    correct,
    d_id,
    sd_id,
    topic_id,
    cohort_id,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          KAQ_id: KAQ_id,
          ans: ans.toString(),
          ass_id: ass_id,
          user_id: user_id,
          d_id: d_id,
          sd_id: sd_id,
          topic_id: topic_id,
          cohort_id: cohort_id,
          correct: correct,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + 'knowassqueans/add';
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  Know_Complete(ka_id, user_id, token, cohort_id) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          ka_id: ka_id,
          user_id: user_id,
          cohort_id: cohort_id,
        }),
      ),
    };
    //
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);

      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + 'points/knowasscomplete';
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async KnowDomainReport(
    user_id,
    certi_id,
    ass_id,
    RowNumber,
    cohort_id,
    token,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          user_id: user_id,
          cource_id: certi_id,
          ass_id: ass_id,
          RowNumber: RowNumber,
          cohort_id: cohort_id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + 'knowassqueans/domain-report';
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async ResetKnow(ass_id: number, user_id: number, token, cohort_id) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          ass_id: ass_id,
          user_id: user_id,
          cohort_id: cohort_id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + environment.RESET_EXAM;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async getResetDataUser(user_id: number, cohort_id, test_id, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          user_id: user_id,
          cohort_id: cohort_id,
          test_id: test_id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + 'testlogs/previous_result_ka';
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
}
