<div class="container-fluid">
  <div class="keypad">
    <div
      class="keys numkey one-line"
      style="width: calc(100% - 35px); overflow: hidden"
    >
      <div class="col-sm-12 form-control text-lg-right" type="text">
        {{ input }}
      </div>
    </div>
    <div class="keypad">
      <div class="keys ackey one-line twice" (click)="allClear()">AC</div>
      <div class="keys opkey one-line twice" (click)="pressOperator('/')">
        /
      </div>
      <div class="keys numkey one-line" (click)="pressNum('7')">7</div>
      <div class="keys numkey one-line" (click)="pressNum('8')">8</div>
      <div class="keys numkey one-line" (click)="pressNum('9')">9</div>
      <div class="keys opkey one-line" (click)="pressNum('*')">x</div>
      <div class="keys numkey one-line" (click)="pressNum('4')">4</div>
      <div class="keys numkey one-line" (click)="pressNum('5')">5</div>
      <div class="keys numkey one-line" (click)="pressNum('6')">6</div>
      <div class="keys opkey one-line" (click)="pressNum('-')">-</div>
      <div class="keys numkey one-line" (click)="pressNum('1')">1</div>
      <div class="keys numkey one-line" (click)="pressNum('2')">2</div>
      <div class="keys numkey one-line" (click)="pressNum('3')">3</div>
      <div class="keys opkey one-line" (click)="pressNum('+')">+</div>
      <div class="keys numkey zerokey one-line twice" (click)="pressNum('0')">
        0
      </div>
      <div class="keys numkey one-line" (click)="pressNum('.')">.</div>
      <div class="keys equalkey one-line" (click)="getAnswer()">=</div>
    </div>
  </div>
</div>
