<div class="logo-div">
  <img src="../../../assets/images/sidebar-logo.png" alt="Logo" />
</div>

<div class="thankyou-notice">
  <img src="../../../assets/images/welcome_journey.svg" alt="Thank You" />
  <p>
    You are now officially starting your {{ cohortName }} journey. We’re excited
    to be your guides!
  </p>
</div>

<div class="form-button-next">
  <button
    type="button"
    class="frm-next-btn"
    (click)="Continue()"
    [disabled]="buttondisabled"
  >
    <span class="dashbord-view">View your dashboard</span>
    <span class="view-dashboard">Click here to view your dashboard</span>
  </button>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
