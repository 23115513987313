import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AuthserviceService } from '../../authservice.service';
import { ServicesService } from '../../services.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginflowService } from '../../data/loginflow.service';

@Component({
  selector: 'app-aliasname',
  templateUrl: './aliasname.component.html',
  styleUrls: ['./aliasname.component.scss'],
})
export class AliasnameComponent implements OnInit {
  name: any;
  public LoginForm: FormGroup;
  errorshow = false;
  perfectShow: boolean = false;
  data: any;
  AllAlias = [];
  CurrentName = '';
  cohort_id: any;
  constructor(
    public LoginflowService: LoginflowService,
    public ActiveRoute: ActivatedRoute,
    public fb: FormBuilder,
    public auth: AuthserviceService,
    public service: ServicesService,
    public spinner: NgxSpinnerService,
    public router: Router,
  ) {
    this.ActiveRoute.params.subscribe(async (params) => {
      try {
        this.data = JSON.parse(atob(decodeURIComponent(params['data'])));
        await this.LoginflowService.versions_getUserByEmail(
          this.data.email,
        ).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);

            this.name = list.data[0].aliasname;
            this.CurrentName = list.data[0].aliasname;
          }
        });
        this.spinner.hide();
      } catch (error) {
        this.router.navigate(['/login']);
      }
    });
  }

  async ngOnInit() {
    this.spinner.show();
    await this.getAllAlias();
    this.spinner.hide();
  }
  async getAllAlias() {
    await this.LoginflowService.getAllAlias().then((data) => {
      if (data[0].status == 200) {
        var list = JSON.parse(<string>data[0].json);
        list.data.forEach((element) => {
          this.AllAlias.push(element.aliasname.toLowerCase().trim());
        });
      }
    });
  }
  checkAlias() {
    if (this.AllAlias.indexOf(this.name.toLowerCase().trim()) > -1) {
      if (this.name != this.CurrentName) {
        this.errorshow = true;
        this.perfectShow = false;
      }
    } else {
      this.errorshow = false;
      if (
        this.name.trim() != null &&
        this.name.trim() != '' &&
        this.name.trim() != undefined
      ) {
        this.perfectShow = true;
      } else {
        this.perfectShow = false;
      }
    }
  }
  async alisnamechange() {
    if (this.name == undefined || this.name == '' || this.name == null) {
      return;
    }
    this.name = this.name.trim();
    if (this.name != undefined && this.name != '' && this.name != null) {
      this.spinner.show();
      await this.LoginflowService.UpdateUsernameEmail(
        this.data.email,
        this.name,
      ).then(async (changepas) => {
        if (changepas[0].StatusCode == 1) {
          var list = JSON.parse(<string>changepas[0].data);

          localStorage.setItem(
            'userdetails',
            JSON.stringify(<string>list.data),
          );
          localStorage.setItem('CisspUserId', JSON.stringify(list.data.id));

          this.spinner.hide();
          var userdata = {
            email: this.data.email,
            password: localStorage.getItem('domaindata')
              ? JSON.parse(atob(localStorage.getItem('domaindata')))
              : this.data.password,
            cohort_id: this.data.cohort_id,
          };

          var email = btoa(JSON.stringify(userdata));
          email = encodeURIComponent(email);
          let wel_flow =
            list.data.welcome_flow != undefined ||
            list.data.welcome_flow != null
              ? parseInt(list.data.welcome_flow)
              : 0;
          if (wel_flow > 3) {
            if (wel_flow == 2) {
              this.router.navigate([`/alias/${email}`]);
              return;
            } else if (wel_flow == 3) {
              this.router.navigate([`/timezone/${email}`]);
              return;
            } else if (wel_flow == 4) {
              this.router.navigate([`/protectpass/${email}`]);
              return;
            } else if (wel_flow == 5) {
              this.router.navigate([`/onetimepsas/${email}`]);
              return;
            } else if (wel_flow == 6) {
              this.router.navigate([`/userimage/${email}`]);
              return;
            } else if (wel_flow == 7) {
              this.router.navigate([`/selfpacedvideo/${email}`]);
              return;
            } else if (wel_flow == 8) {
              this.router.navigate([`/customschedule/${email}`]);
              return;
            } else if (wel_flow == 9) {
              this.router.navigate([`/competition/${email}`]);
              return;
            } else if (wel_flow == 10) {
              this.router.navigate([`/learning/${email}`]);
              return;
            } else if (wel_flow == 11) {
              this.router.navigate([`/thankyou/${email}`]);
              return;
            }
          }
          await this.LoginflowService.updateUserWelcomeFlow(
            this.data.email,
            '3',
            this.data.cohort_id,
          ).then((data) => {
            var datas = btoa(JSON.stringify(userdata));
            datas = encodeURIComponent(datas);
            this.router.navigate([`/timezone/${datas}`]);
          });
        } else {
          this.spinner.hide();
          var list = JSON.parse(<string>changepas[0].data);
          if (list.message == 'Validation error') {
            this.errorshow = true;
          } else {
            this.service.ShowError(list.message);
          }
        }
      });
    }
  }
  retuey() {
    window.location.reload();
  }
}
