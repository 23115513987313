import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServicesService } from '../services.service';
import { AuthserviceService } from '../authservice.service';
import { LoginflowService } from '../data/loginflow.service';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss'],
})
export class AuthenticationComponent implements OnInit {
  public AuthForm: FormGroup;
  email: any;
  authCode: any;
  constructor(
    public ActiveRoute: ActivatedRoute,
    public service: ServicesService,
    public auth: AuthserviceService,
    public spinner: NgxSpinnerService,
    public fb: FormBuilder,
    public router: Router,
    public loginFlowService: LoginflowService,
  ) {
    this.ActiveRoute.params.subscribe(async (params) => {
      try {
        this.email = JSON.parse(atob(decodeURIComponent(params['email'])));
      } catch (error) {
        this.router.navigate(['/login']);
      }
    });
  }

  async ngOnInit() {
    this.AuthForm = this.fb.group({
      authCode: ['', Validators.required],
    });
    this.spinner.show();
    if (this.email.email) this.email = this.email.email;
    await this.loginFlowService
      .versions_getUserByEmail(this.email)
      .then(async (data) => {
        if (data[0].status == 200) {
          var list = JSON.parse(<string>data[0].json);
          if (list.data.length > 0) {
            this.email = list.data[0].email;
            await this.auth
              .resendConfirmationCode(this.email)
              .then(async (data) => {
                if (data[0].StatusCode == 1) {
                  this.service.ShowSuccess(data[0].message);
                  this.spinner.hide();
                } else {
                  if (
                    data[0].message ==
                    'Username/client id combination not found.'
                  ) {
                    this.service.ShowError('No user found');
                    this.spinner.hide();
                  } else if (
                    data[0].message ==
                    'User password cannot be reset in the current state.'
                  ) {
                    this.service.ShowError(
                      'You have not completed the onboarding flow. Please check email for temp password.',
                    );
                    this.spinner.hide();
                  } else {
                    this.service.ShowError(data[0].message);
                    this.spinner.hide();
                  }
                }
              });
          } else {
            this.service.ShowError('User not found');
            this.spinner.hide();
          }
        } else {
          this.service.ShowError(data[0].message);
          this.spinner.hide();
        }
      });
    this.spinner.hide();
  }
  dashboard() {
    this.router.navigate(['/sidenav']);
  }
  async confirmCode() {
    try {
      this.spinner.show();
      await this.loginFlowService
        .versions_getUserByEmail(this.email)
        .then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            if (list.data.length > 0) {
              this.email = list.data[0].email;
              await this.auth.confirmAuthCode(this.authCode, this.email).then(
                async (data) => {
                  if (data[0].StatusCode == 1) {
                    this.spinner.hide();
                    await this.router.navigate(['/login']);
                  } else {
                    await this.service.ShowError(data[0].message);
                    this.spinner.hide();
                  }
                },
                (err) => {
                  this.spinner.hide();
                  this.service.ShowError(err);
                },
              );
            } else {
              this.service.ShowError('User not found');
              this.spinner.hide();
            }
          } else {
            this.service.ShowError(data[0].message);
            this.spinner.hide();
          }
        });
    } catch (e) {
      this.service.ShowError(e.message);
      this.spinner.hide();
    }
  }
}
