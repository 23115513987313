<form method="post">
  <div class="form-form-wrapper av">
    <div class="form-row">
      <label
        ><span>Hi {{ name }},</span> Create an avatar for yourself</label
      >
      <div class="sc-avatar-wrapper">
        <img id="userimage" [src]="urlAvatar" />
        <div (click)="tranferbg('Transparent')">
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="20" cy="20" r="20" fill="url(#paint0_linear_0_18713)" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M25.4786 13.1216C25.2245 12.939 24.8683 12.9621 24.64 13.1907L24.5715 13.2713C24.3889 13.5254 24.412 13.8816 24.6406 14.1099L25.8888 15.2935H24.2778L24.0925 15.3C23.3554 15.3516 22.6698 15.7094 22.2057 16.2916L20.2001 18.8071L17.9606 15.998C17.4964 15.4159 16.8109 15.0581 16.0737 15.0065L15.8885 15H12.65C12.291 15 12 15.291 12 15.65C12 15.9763 12.2405 16.2465 12.5539 16.293L12.65 16.3H15.8885C16.248 16.3 16.5904 16.4433 16.842 16.6943L16.9441 16.8084L19.3688 19.8498L16.9441 22.8913L16.842 23.0053C16.5904 23.2564 16.248 23.3997 15.8885 23.3997H12.65L12.5539 23.4067C12.2405 23.4532 12 23.7233 12 24.0497C12 24.4087 12.291 24.6997 12.65 24.6997H15.8885L16.0737 24.6932C16.8109 24.6416 17.4964 24.2838 17.9606 23.7017L20.2001 20.8925L22.2057 23.4081C22.6698 23.9903 23.3554 24.3481 24.0925 24.3997L24.2778 24.4062H25.8888L24.6406 25.5898C24.412 25.8181 24.3889 26.1743 24.5715 26.4284L24.64 26.509C24.8683 26.7376 25.2245 26.7607 25.4786 26.5781L25.5592 26.5096L27.6784 24.4911C28.0498 24.1201 28.0863 23.5384 27.7824 23.1261L27.6993 23.0268L25.5722 20.8812C25.3255 20.6203 24.9141 20.6089 24.6533 20.8555C24.4185 21.0775 24.3858 21.4329 24.5614 21.6919L24.6276 21.7744L25.9526 23.1062H24.2778C23.9182 23.1062 23.5758 22.9629 23.3242 22.7118L23.2222 22.5977L21.0314 19.8498L23.2222 17.102L23.3242 16.9879C23.5758 16.7368 23.9182 16.5935 24.2778 16.5935H25.9526L24.6276 17.9253L24.5614 18.0078C24.3858 18.2668 24.4185 18.6222 24.6533 18.8442C24.9141 19.0908 25.3255 19.0793 25.5722 18.8185L27.6993 16.6729L27.7824 16.5736C28.0863 16.1613 28.0498 15.5796 27.6784 15.2086L25.5592 13.1901L25.4786 13.1216Z"
              fill="white"
            />
            <defs>
              <linearGradient
                id="paint0_linear_0_18713"
                x1="40"
                y1="0"
                x2="0"
                y2="0"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#F473B9" />
                <stop offset="1" stop-color="#F63CB2" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
    </div>
    <div class="pc-tab">
      <input checked="checked" id="tab1" type="radio" name="pct" />
      <input id="tab2" type="radio" name="pct" />
      <input id="tab3" type="radio" name="pct" />
      <input id="tab4" type="radio" name="pct" />
      <input id="tab5" type="radio" name="pct" />
      <input id="tab6" type="radio" name="pct" />
      <input id="tab7" type="radio" name="pct" />
      <input id="tab8" type="radio" name="pct" />
      <nav>
        <ul class="tab-nav-all">
          <li class="tab1">
            <label for="tab1">Skin</label>
          </li>
          <li class="tab2">
            <label for="tab2">Hairstyle</label>
          </li>
          <li class="tab3">
            <label for="tab3">Eye</label>
          </li>
          <li class="tab4">
            <label for="tab4">Eyebrows</label>
          </li>
          <li class="tab5">
            <label for="tab5">Mouth</label>
          </li>
          <li class="tab6">
            <label for="tab6">Facial Hair</label>
          </li>
          <li class="tab7">
            <label for="tab7">Cloths</label>
          </li>
          <li class="tab8">
            <label for="tab8">Accessories</label>
          </li>
        </ul>
      </nav>
      <section>
        <div class="tab1 ss-tag">
          <form method="post">
            <ul class="color-list-wrapper">
              <li (click)="skinClick('Tanned')">
                <input type="radio" value="color" for="color1" name="color1" />
                <label class="color1"></label>
                <span>Tanned</span>
              </li>
              <li (click)="skinClick('Yellow')">
                <input type="radio" value="color" for="color2" name="color1" />
                <label class="color2"></label>
                <span>Yellow</span>
              </li>
              <li (click)="skinClick('Pale')">
                <input type="radio" value="color" for="color3" name="color1" />
                <label class="color3"></label>
                <span>Pale</span>
              </li>
              <li (click)="skinClick('Light')">
                <input type="radio" value="color" for="color4" name="color1" />
                <label class="color4"></label>
                <span>Light</span>
              </li>
              <li (click)="skinClick('Brown')">
                <input type="radio" value="color" for="color5" name="color1" />
                <label class="color5"></label>
                <span>Brown</span>
              </li>
              <li (click)="skinClick('DarkBrown')">
                <input type="radio" value="color" for="color6" name="color1" />
                <label class="color6"></label>
                <span>DarkBrown</span>
              </li>
              <li (click)="skinClick('Black')">
                <input type="radio" value="color" for="color7" name="color1" />
                <label class="color7"></label>
                <span>Black</span>
              </li>
            </ul>
          </form>
        </div>
        <div class="tab2">
          <form method="post">
            <ul class="color-list-wrapper Hairstyle ss-tag">
              <li (click)="hairclick('Auburn')">
                <input type="radio" value="color" for="color1" name="color1" />
                <label class="color1"></label>
                <span>Auburn</span>
              </li>
              <li (click)="hairclick('Black')">
                <input type="radio" value="color" for="color2" name="color1" />
                <label class="color2"></label>
                <span>Black</span>
              </li>
              <li (click)="hairclick('Blonde')">
                <input type="radio" value="color" for="color3" name="color1" />
                <label class="color3"></label>
                <span>Blonde</span>
              </li>
              <li (click)="hairclick('BlondeGolden')">
                <input type="radio" value="color" for="color4" name="color1" />
                <label class="color4"></label>
                <span>Blonde Golden</span>
              </li>
              <li (click)="hairclick('Brown')">
                <input type="radio" value="color" for="color5" name="color1" />
                <label class="color5"></label>
                <span>Brown</span>
              </li>
              <li (click)="hairclick('BrownDark')">
                <input type="radio" value="color" for="color6" name="color1" />
                <label class="color6"></label>
                <span>Dark Brown</span>
              </li>
              <li (click)="hairclick('PastelPink')">
                <input type="radio" value="color" for="color7" name="color1" />
                <label class="color7"></label>
                <span>Pastel Pink</span>
              </li>
              <li (click)="hairclick('Platinum')">
                <input type="radio" value="color" for="color8" name="color1" />
                <label class="color8"></label>
                <span>Platinum</span>
              </li>
              <li (click)="hairclick('Red')">
                <input type="radio" value="color" for="color9" name="color1" />
                <label class="color9"></label>
                <span>Red</span>
              </li>
              <li (click)="hairclick('SilverGray')">
                <input type="radio" value="color" for="color10" name="color1" />
                <label class="color10"></label>
                <span>Silver Gray</span>
              </li>
            </ul>

            <ul class="color-list-wrapper hairstyle hair-list b-list">
              <li (click)="Topclick('NoHair')">
                <input
                  type="radio"
                  value="hairstyle"
                  for="hairstyle1"
                  name="hairstyle"
                />
                <label class="hairstyle1"></label>
                <div class="all-hair">
                  <span class="no-hair">No Hair</span>
                </div>
              </li>
              <li (click)="Topclick('LongHairBigHair')">
                <input
                  type="radio"
                  value="hairstyle"
                  for="hairstyle2"
                  name="hairstyle"
                />
                <label class="hairstyle2">
                  <img
                    src="../../../assets/images/haistyle/hairstyle-1.png"
                    alt="Hairstyle Image"
                  />
                </label>
                <div class="all-hair">
                  <span>Long Hair </span>
                  <span>Big Hair</span>
                </div>
              </li>
              <li (click)="Topclick('LongHairBob')">
                <input
                  type="radio"
                  value="hairstyle"
                  for="hairstyle3"
                  name="hairstyle"
                />
                <label class="hairstyle3">
                  <img
                    src="../../../assets/images/haistyle/hairstyle-2.png"
                    alt="Hairstyle Image"
                  />
                </label>
                <div class="all-hair">
                  <span>Long Hair Bob</span>
                </div>
              </li>
              <li (click)="Topclick('LongHairBun')">
                <input
                  type="radio"
                  value="hairstyle"
                  for="hairstyle4"
                  name="hairstyle"
                />
                <label class="hairstyle4">
                  <img
                    src="../../../assets/images/haistyle/hairstyle-3.png"
                    alt="Hairstyle Image"
                  />
                </label>
                <div class="all-hair">
                  <span>Long Hair Bun</span>
                </div>
              </li>
              <li (click)="Topclick('LongHairCurly')">
                <input
                  type="radio"
                  value="hairstyle"
                  for="hairstyle5"
                  name="hairstyle"
                />
                <label class="hairstyle5">
                  <img
                    src="../../../assets/images/haistyle/hairstyle-4.png"
                    alt="Hairstyle Image"
                  />
                </label>
                <div class="all-hair">
                  <span>Long Hair </span>
                  <span>Curly</span>
                </div>
              </li>
              <li (click)="Topclick('LongHairCurvy')">
                <input
                  type="radio"
                  value="hairstyle"
                  for="hairstyle7"
                  name="hairstyle"
                />
                <label class="hairstyle7">
                  <img
                    src="../../../assets/images/haistyle/hairstyle-5.png"
                    alt="Hairstyle Image"
                  />
                </label>
                <div class="all-hair">
                  <span>Long Hair</span>
                  <span>Curvy</span>
                </div>
              </li>
              <li (click)="Topclick('LongHairDreads')">
                <input
                  type="radio"
                  value="hairstyle"
                  for="hairstyle7"
                  name="hairstyle"
                />
                <label class="hairstyle7">
                  <img
                    src="../../../assets/images/haistyle/hairstyle-6.png"
                    alt="Hairstyle Image"
                  />
                </label>
                <div class="all-hair">
                  <span>Long Hair</span>
                  <span>Dreads</span>
                </div>
              </li>
              <li (click)="Topclick('LongHairFrida')">
                <input
                  type="radio"
                  value="hairstyle"
                  for="hairstyle8"
                  name="hairstyle"
                />
                <label class="hairstyle8">
                  <img
                    src="../../../assets/images/haistyle/hairstyle-7.png"
                    alt="Hairstyle Image"
                  />
                </label>
                <div class="all-hair">
                  <span>Long Hair</span>
                  <span>Frida</span>
                </div>
              </li>
              <li (click)="Topclick('LongHairFro')">
                <input
                  type="radio"
                  value="hairstyle"
                  for="hairstyle9"
                  name="hairstyle"
                />
                <label class="hairstyle9">
                  <img
                    src="../../../assets/images/haistyle/hairstyle-8.png"
                    alt="Hairstyle Image"
                  />
                </label>
                <div class="all-hair">
                  <span>Long Hair Fro</span>
                </div>
              </li>
              <li (click)="Topclick('LongHairFroBand')">
                <input
                  type="radio"
                  value="hairstyle"
                  for="hairstyle10"
                  name="hairstyle"
                />
                <label class="hairstyle10">
                  <img
                    src="../../../assets/images/haistyle/hairstyle-9.png"
                    alt="Hairstyle Image"
                  />
                </label>
                <div class="all-hair">
                  <span>Long Hair Fro+</span>
                  <span>Band</span>
                </div>
              </li>
              <li (click)="Topclick('LongHairNotTooLong')">
                <input
                  type="radio"
                  value="hairstyle"
                  for="hairstyle11"
                  name="hairstyle"
                />
                <label class="hairstyle11">
                  <img
                    src="../../../assets/images/haistyle/hairstyle-10.png"
                    alt="Hairstyle Image"
                  />
                </label>
                <div class="all-hair">
                  <span>Long Hair But</span>
                  <span>Not Too Long</span>
                </div>
              </li>
              <li (click)="Topclick('LongHairMiaWallace')">
                <input
                  type="radio"
                  value="hairstyle"
                  for="hairstyle12"
                  name="hairstyle"
                />
                <label class="hairstyle12">
                  <img
                    src="../../../assets/images/haistyle/hairstyle-11.png"
                    alt="Hairstyle Image"
                  />
                </label>
                <div class="all-hair">
                  <span>Long Hair Mia</span>
                  <span>Walace</span>
                </div>
              </li>
              <li (click)="Topclick('LongHairShavedSides')">
                <input
                  type="radio"
                  value="hairstyle"
                  for="hairstyle13"
                  name="hairstyle"
                />
                <label class="hairstyle13">
                  <img
                    src="../../../assets/images/haistyle/hairstyle-12.png"
                    alt="Hairstyle Image"
                  />
                </label>
                <div class="all-hair">
                  <span>Long Hair</span>
                  <span>Shaved Sides</span>
                </div>
              </li>
              <li (click)="Topclick('LongHairStraight')">
                <input
                  type="radio"
                  value="hairstyle"
                  for="hairstyle14"
                  name="hairstyle"
                />
                <label class="hairstyle14">
                  <img
                    src="../../../assets/images/haistyle/hairstyle-13.png"
                    alt="Hairstyle Image"
                  />
                </label>
                <div class="all-hair">
                  <span>Long Hair</span>
                  <span>Straight1</span>
                </div>
              </li>
              <li (click)="Topclick('LongHairStraight2')">
                <input
                  type="radio"
                  value="hairstyle"
                  for="hairstyle15"
                  name="hairstyle"
                />
                <label class="hairstyle15">
                  <img
                    src="../../../assets/images/haistyle/hairstyle-14.png"
                    alt="Hairstyle Image"
                  />
                </label>
                <div class="all-hair">
                  <span>Long Hair</span>
                  <span>Straight2</span>
                </div>
              </li>
              <li (click)="Topclick('LongHairNotTooLong')">
                <input
                  type="radio"
                  value="hairstyle"
                  for="hairstyle16"
                  name="hairstyle"
                />
                <label class="hairstyle16">
                  <img
                    src="../../../assets/images/haistyle/hairstyle-15.png"
                    alt="Hairstyle Image"
                  />
                </label>
                <div class="all-hair">
                  <span>Long Hair</span>
                  <span>Straight+Stra...</span>
                </div>
              </li>
              <li (click)="Topclick('ShortHairDreads01')">
                <input
                  type="radio"
                  value="hairstyle"
                  for="hairstyle17"
                  name="hairstyle"
                />
                <label class="hairstyle17">
                  <img
                    src="../../../assets/images/haistyle/hairstyle-16.png"
                    alt="Hairstyle Image"
                  />
                </label>
                <div class="all-hair">
                  <span>Long Hair But</span>
                  <span>Dreads1</span>
                </div>
              </li>
              <li (click)="Topclick('ShortHairDreads02')">
                <input
                  type="radio"
                  value="hairstyle"
                  for="hairstyle18"
                  name="hairstyle"
                />
                <label class="hairstyle18">
                  <img
                    src="../../../assets/images/haistyle/hairstyle-17.png"
                    alt="Hairstyle Image"
                  />
                </label>
                <div class="all-hair">
                  <span>Long Hair But</span>
                  <span>Dreads2</span>
                </div>
              </li>
              <li (click)="Topclick('ShortHairFrizzle')">
                <input
                  type="radio"
                  value="hairstyle"
                  for="hairstyle19"
                  name="hairstyle"
                />
                <label class="hairstyle19">
                  <img
                    src="../../../assets/images/haistyle/hairstyle-18.png"
                    alt="Hairstyle Image"
                  />
                </label>
                <div class="all-hair">
                  <span>Short Hair</span>
                  <span>Frizzle</span>
                </div>
              </li>
              <li (click)="Topclick('ShortHairFrizzle')">
                <input
                  type="radio"
                  value="hairstyle"
                  for="hairstyle20"
                  name="hairstyle"
                />
                <label class="hairstyle20">
                  <img
                    src="../../../assets/images/haistyle/hairstyle-19.png"
                    alt="Hairstyle Image"
                  />
                </label>
                <div class="all-hair">
                  <span>Short Hair</span>
                  <span>Shaggy</span>
                </div>
              </li>
              <li (click)="Topclick('ShortHairShaggyMullet')">
                <input
                  type="radio"
                  value="hairstyle"
                  for="hairstyle21"
                  name="hairstyle"
                />
                <label class="hairstyle21">
                  <img
                    src="../../../assets/images/haistyle/hairstyle-20.png"
                    alt="Hairstyle Image"
                  />
                </label>
                <div class="all-hair">
                  <span>Short Hair</span>
                  <span>Shaggy Mullet</span>
                </div>
              </li>
              <li (click)="Topclick('ShortHairShortCurly')">
                <input
                  type="radio"
                  value="hairstyle"
                  for="hairstyle22"
                  name="hairstyle"
                />
                <label class="hairstyle22">
                  <img
                    src="../../../assets/images/haistyle/hairstyle-21.png"
                    alt="Hairstyle Image"
                  />
                </label>
                <div class="all-hair">
                  <span>Short Hair</span>
                  <span>Shaggy Short...</span>
                </div>
              </li>
              <li (click)="Topclick('ShortHairShortFlat')">
                <input
                  type="radio"
                  value="hairstyle"
                  for="hairstyle23"
                  name="hairstyle"
                />
                <label class="hairstyle21">
                  <img
                    src="../../../assets/images/haistyle/hairstyle-22.png"
                    alt="Hairstyle Image"
                  />
                </label>
                <div class="all-hair">
                  <span>Short Hair</span>
                  <span>Short Flat</span>
                </div>
              </li>
              <li (click)="Topclick('ShortHairShortRound')">
                <input
                  type="radio"
                  value="hairstyle"
                  for="hairstyle24"
                  name="hairstyle"
                />
                <label class="hairstyle22">
                  <img
                    src="../../../assets/images/haistyle/hairstyle-23.png"
                    alt="Hairstyle Image"
                  />
                </label>
                <div class="all-hair">
                  <span>Short Hair</span>
                  <span>Short Round</span>
                </div>
              </li>
              <li (click)="Topclick('ShortHairShortWaved')">
                <input
                  type="radio"
                  value="hairstyle"
                  for="hairstyle25"
                  name="hairstyle"
                />
                <label class="hairstyle25">
                  <img
                    src="../../../assets/images/haistyle/hairstyle-24.png"
                    alt="Hairstyle Image"
                  />
                </label>
                <div class="all-hair">
                  <span>Short Hair</span>
                  <span>Short Waved</span>
                </div>
              </li>
              <li (click)="Topclick('ShortHairSides')">
                <input
                  type="radio"
                  value="hairstyle"
                  for="hairstyle26"
                  name="hairstyle"
                />
                <label class="hairstyle26">
                  <img
                    src="../../../assets/images/haistyle/hairstyle-25.png"
                    alt="Hairstyle Image"
                  />
                </label>
                <div class="all-hair">
                  <span>Short Hair</span>
                  <span>Sides</span>
                </div>
              </li>
              <li (click)="Topclick('ShortHairTheCaesar')">
                <input
                  type="radio"
                  value="hairstyle"
                  for="hairstyle27"
                  name="hairstyle"
                />
                <label class="hairstyle27">
                  <img
                    src="../../../assets/images/haistyle/hairstyle-26.png"
                    alt="Hairstyle Image"
                  />
                </label>
                <div class="all-hair">
                  <span>Short Hair</span>
                  <span>Short Caeser</span>
                </div>
              </li>
              <li (click)="Topclick('ShortHairTheCaesarSidePart')">
                <input
                  type="radio"
                  value="hairstyle"
                  for="hairstyle28"
                  name="hairstyle"
                />
                <label class="hairstyle28">
                  <img
                    src="../../../assets/images/haistyle/hairstyle-27.png"
                    alt="Hairstyle Image"
                  />
                </label>
                <div class="all-hair">
                  <span>Short Hair</span>
                  <span>Short Sieds</span>
                </div>
              </li>
            </ul>
          </form>
        </div>
        <div class="tab3">
          <ul class="color-list-wrapper b-list eyes hair-list">
            <li (click)="eyeClick('Close')">
              <input type="radio" value="eyes" for="eyes1" name="eyes" />
              <label class="eyes1">
                <img
                  src="../../../assets/images/eyes/eyes-1.png"
                  alt="eyes Image"
                />
              </label>
              <div class="all-hair">
                <span>Eyes Closed</span>
              </div>
            </li>
            <li (click)="eyeClick('Cry')">
              <input type="radio" value="eyes" for="eyes2" name="eyes" />
              <label class="eyes2">
                <img
                  src="../../../assets/images/eyes/eyes-2.png"
                  alt="eyes Image"
                />
              </label>
              <div class="all-hair">
                <span>Eyes Cried</span>
              </div>
            </li>
            <li (click)="eyeClick('Default')">
              <input type="radio" value="eyes" for="eyes3" name="eyes" />
              <label class="eyes3">
                <img
                  src="../../../assets/images/eyes/eyes-3.png"
                  alt="eyes Image"
                />
              </label>
              <div class="all-hair">
                <span>Eyes Default</span>
              </div>
            </li>
            <li (click)="eyeClick('EyeRoll')">
              <input type="radio" value="eyes" for="eyes4" name="eyes" />
              <label class="eyes4">
                <img
                  src="../../../assets/images/eyes/eyes-4.png"
                  alt="eyes Image"
                />
              </label>
              <div class="all-hair">
                <span>Eyes EyeRoll</span>
              </div>
            </li>
            <li (click)="eyeClick('Happy')">
              <input type="radio" value="eyes" for="eyes5" name="eyes" />
              <label class="eyes5">
                <img
                  src="../../../assets/images/eyes/eyes-5.png"
                  alt="eyes Image"
                />
              </label>
              <div class="all-hair">
                <span>Eyes Happly</span>
              </div>
            </li>
            <li (click)="eyeClick('Hearts')">
              <input type="radio" value="eyes" for="eyes6" name="eyes" />
              <label class="eyes6">
                <img
                  src="../../../assets/images/eyes/eyes-6.png"
                  alt="eyes Image"
                />
              </label>
              <div class="all-hair">
                <span>Eyes Hearts</span>
              </div>
            </li>
            <li (click)="eyeClick('Side')">
              <input type="radio" value="eyes" for="eyes7" name="eyes" />
              <label class="eyes7">
                <img
                  src="../../../assets/images/eyes/eyes-7.png"
                  alt="eyes Image"
                />
              </label>
              <div class="all-hair">
                <span>Eyes Side</span>
              </div>
            </li>
            <li (click)="eyeClick('Squint')">
              <input type="radio" value="eyes" for="eyes8" name="eyes" />
              <label class="eyes8">
                <img
                  src="../../../assets/images/eyes/eyes-8.png"
                  alt="eyes Image"
                />
              </label>
              <div class="all-hair">
                <span>Eyes Squint</span>
              </div>
            </li>
            <li (click)="eyeClick('Surprised')">
              <input type="radio" value="eyes" for="eyes9" name="eyes" />
              <label class="eyes9">
                <img
                  src="../../../assets/images/eyes/eyes-9.png"
                  alt="eyes Image"
                />
              </label>
              <div class="all-hair">
                <span>Eyes Surprised</span>
              </div>
            </li>
            <li (click)="eyeClick('WinkWacky')">
              <input type="radio" value="eyes" for="eyes10" name="eyes" />
              <label class="eyes10">
                <img
                  src="../../../assets/images/eyes/eyes-10.png"
                  alt="eyes Image"
                />
              </label>
              <div class="all-hair">
                <span>Eyes Wink</span>
                <span>Wacky</span>
              </div>
            </li>
            <li (click)="eyeClick('Wink')">
              <input type="radio" value="eyes" for="eyes11" name="eyes" />
              <label class="eyes11">
                <img
                  src="../../../assets/images/eyes/eyes-11.png"
                  alt="eyes Image"
                />
              </label>
              <div class="all-hair">
                <span>Eyes Wink</span>
              </div>
            </li>
            <li (click)="eyeClick('Dizzy')">
              <input type="radio" value="eyes" for="eyes12" name="eyes" />
              <label class="eyes12">
                <img
                  src="../../../assets/images/eyes/eyes-12.png"
                  alt="eyes Image"
                />
              </label>
              <div class="all-hair">
                <span>Eyes XDizzy</span>
              </div>
            </li>
          </ul>
        </div>
        <div class="tab4">
          <ul class="color-list-wrapper b-list eyebrows hair-list">
            <li (click)="eyebrows('FlatNatural')">
              <input
                type="radio"
                value="eyebrows"
                for="eyebrows1"
                name="eyebrows"
              />
              <label class="eyebrows1">
                <img
                  src="../../../assets/images/eyebrows/eyebrows-1.png"
                  alt="eyebrows Image"
                />
              </label>
              <div class="all-hair">
                <span>Eyebrow</span>
                <span>Natural Default</span>
              </div>
            </li>
            <li (click)="eyebrows('DefaultNatural')">
              <input
                type="radio"
                value="eyebrows"
                for="eyebrows2"
                name="eyebrows"
              />
              <label class="eyebrows2">
                <img
                  src="../../../assets/images/eyebrows/eyebrows-2.png"
                  alt="eyebrows Image"
                />
              </label>
              <div class="all-hair">
                <span>Eyebrow</span>
                <span>Natural Flat</span>
              </div>
            </li>
            <li (click)="eyebrows('SadConcerned')">
              <input
                type="radio"
                value="eyebrows"
                for="eyebrows3"
                name="eyebrows"
              />
              <label class="eyebrows3">
                <img
                  src="../../../assets/images/eyebrows/eyebrows-3.png"
                  alt="eyebrows Image"
                />
              </label>
              <div class="all-hair">
                <span>Eyebrow</span>
                <span>Natural Frown</span>
              </div>
            </li>
            <li (click)="eyebrows('Default')">
              <input
                type="radio"
                value="eyebrows"
                for="eyebrows4"
                name="eyebrows"
              />
              <label class="eyebrows4">
                <img
                  src="../../../assets/images/eyebrows/eyebrows-4.png"
                  alt="eyebrows Image"
                />
              </label>
              <div class="all-hair">
                <span>Eyebrow</span>
                <span>Natural Raised</span>
              </div>
            </li>
            <li (click)="eyebrows('SadConcernedNatural')">
              <input
                type="radio"
                value="eyebrows"
                for="eyebrows5"
                name="eyebrows"
              />
              <label class="eyebrows5">
                <img
                  src="../../../assets/images/eyebrows/eyebrows-5.png"
                  alt="eyebrows Image"
                />
              </label>
              <div class="all-hair">
                <span>Eyebrow</span>
                <span>Natural Sad</span>
              </div>
            </li>
            <li (click)="eyebrows('RaisedExcitedNatural')">
              <input
                type="radio"
                value="eyebrows"
                for="eyebrows6"
                name="eyebrows"
              />
              <label class="eyebrows6">
                <img
                  src="../../../assets/images/eyebrows/eyebrows-6.png"
                  alt="eyebrows Image"
                />
              </label>
              <div class="all-hair">
                <span>Eyebrow</span>
                <span>Natural Raised</span>
              </div>
            </li>
            <li (click)="eyebrows('Angry')">
              <input
                type="radio"
                value="eyebrows"
                for="eyebrows7"
                name="eyebrows"
              />
              <label class="eyebrows7">
                <img
                  src="../../../assets/images/eyebrows/eyebrows-7.png"
                  alt="eyebrows Image"
                />
              </label>
              <div class="all-hair">
                <span>Eyebrow</span>
                <span>Natural UpDown</span>
              </div>
            </li>
            <li (click)="eyebrows('AngryNatural')">
              <input
                type="radio"
                value="eyebrows"
                for="eyebrows8"
                name="eyebrows"
              />
              <label class="eyebrows8">
                <img
                  src="../../../assets/images/eyebrows/eyebrows-8.png"
                  alt="eyebrows Image"
                />
              </label>
              <div class="all-hair">
                <span>Eyebrow</span>
                <span>Outline Angry</span>
              </div>
            </li>
            <li (click)="eyebrows('RaisedExcited')">
              <input
                type="radio"
                value="eyebrows"
                for="eyebrows9"
                name="eyebrows"
              />
              <label class="eyebrows9">
                <img
                  src="../../../assets/images/eyebrows/eyebrows-9.png"
                  alt="eyebrows Image"
                />
              </label>
              <div class="all-hair">
                <span>Eyebrow</span>
                <span>Outline Raised</span>
              </div>
            </li>
            <li (click)="eyebrows('UnibrowNatural')">
              <input
                type="radio"
                value="eyebrows"
                for="eyebrows10"
                name="eyebrows"
              />
              <label class="eyebrows10">
                <img
                  src="../../../assets/images/eyebrows/eyebrows-10.png"
                  alt="eyebrows Image"
                />
              </label>
              <div class="all-hair">
                <span>Eyebrow</span>
                <span>Outline Sad</span>
              </div>
            </li>
            <li (click)="eyebrows('UpDown')">
              <input
                type="radio"
                value="eyebrows"
                for="eyebrows11"
                name="eyebrows"
              />
              <label class="eyebrows11">
                <img
                  src="../../../assets/images/eyebrows/eyebrows-11.png"
                  alt="eyebrows Image"
                />
              </label>
              <div class="all-hair">
                <span>Eyebrow</span>
                <span>Outline UpDown</span>
              </div>
            </li>
          </ul>
        </div>
        <div class="tab5">
          <ul class="color-list-wrapper b-list mouth hair-list">
            <li (click)="mounthclick('Concerned')">
              <input type="radio" value="mouth" for="mouth1" name="mouth" />
              <label class="mouth1">
                <img
                  src="../../../assets/images/mouth/mouth-1.png"
                  alt="mouth Image"
                />
              </label>
              <div class="all-hair">
                <span>Mouth</span>
                <span>Concerned</span>
              </div>
            </li>
            <li (click)="mounthclick('Default')">
              <input type="radio" value="mouth" for="mouth2" name="mouth" />
              <label class="mouth2">
                <img
                  src="../../../assets/images/mouth/mouth-2.png"
                  alt="mouth Image"
                />
              </label>
              <div class="all-hair">
                <span>Mouth Default</span>
              </div>
            </li>
            <li (click)="mounthclick('Disbelief')">
              <input type="radio" value="mouth" for="mouth3" name="mouth" />
              <label class="mouth3">
                <img
                  src="../../../assets/images/mouth/mouth-3.png"
                  alt="mouth Image"
                />
              </label>
              <div class="all-hair">
                <span>Mouth</span>
                <span>Disbelief</span>
              </div>
            </li>
            <li (click)="mounthclick('Eating')">
              <input type="radio" value="mouth" for="mouth4" name="mouth" />
              <label class="mouth4">
                <img
                  src="../../../assets/images/mouth/mouth-4.png"
                  alt="mouth Image"
                />
              </label>
              <div class="all-hair">
                <span>Mouth Eating</span>
              </div>
            </li>
            <li (click)="mounthclick('Grimace')">
              <input type="radio" value="mouth" for="mouth5" name="mouth" />
              <label class="mouth5">
                <img
                  src="../../../assets/images/mouth/mouth-5.png"
                  alt="mouth Image"
                />
              </label>
              <div class="all-hair">
                <span>Mouth Grimace</span>
              </div>
            </li>
            <li (click)="mounthclick('Sad')">
              <input type="radio" value="mouth" for="mouth6" name="mouth" />
              <label class="mouth6">
                <img
                  src="../../../assets/images/mouth/mouth-6.png"
                  alt="mouth Image"
                />
              </label>
              <div class="all-hair">
                <span>Mouth Sad</span>
              </div>
            </li>
            <li (click)="mounthclick('ScreamOpen')">
              <input type="radio" value="mouth" for="mouth7" name="mouth" />
              <label class="mouth7">
                <img
                  src="../../../assets/images/mouth/mouth-7.png"
                  alt="mouth Image"
                />
              </label>
              <div class="all-hair">
                <span>Mouth</span>
                <span>Scream Open</span>
              </div>
            </li>
            <li (click)="mounthclick('Serious')">
              <input type="radio" value="mouth" for="mouth8" name="mouth" />
              <label class="mouth8">
                <img
                  src="../../../assets/images/mouth/mouth-8.png"
                  alt="mouth Image"
                />
              </label>
              <div class="all-hair">
                <span>Mouth</span>
                <span>Serious</span>
              </div>
            </li>
            <li (click)="mounthclick('Smile')">
              <input type="radio" value="mouth" for="mouth9" name="mouth" />
              <label class="mouth9">
                <img
                  src="../../../assets/images/mouth/mouth-9.png"
                  alt="mouth Image"
                />
              </label>
              <div class="all-hair">
                <span>Mouth</span>
                <span>Smile</span>
              </div>
            </li>
            <li (click)="mounthclick('Tongue')">
              <input type="radio" value="mouth" for="mouth10" name="mouth" />
              <label class="mouth10">
                <img
                  src="../../../assets/images/mouth/mouth-10.png"
                  alt="mouth Image"
                />
              </label>
              <div class="all-hair">
                <span>Mouth</span>
                <span>Tongue</span>
              </div>
            </li>
            <li (click)="mounthclick('Twinkle')">
              <input type="radio" value="mouth" for="mouth11" name="mouth" />
              <label class="mouth11">
                <img
                  src="../../../assets/images/mouth/mouth-11.png"
                  alt="mouth Image"
                />
              </label>
              <div class="all-hair">
                <span>Mouth</span>
                <span>Twinkle</span>
              </div>
            </li>
            <li (click)="mounthclick('Vomit')">
              <input type="radio" value="mouth" for="mouth12" name="mouth" />
              <label class="mouth12">
                <img
                  src="../../../assets/images/mouth/mouth-12.png"
                  alt="mouth Image"
                />
              </label>
              <div class="all-hair">
                <span>Mouth</span>
                <span>Vomit</span>
              </div>
            </li>
          </ul>
        </div>
        <div class="tab6">
          <ul class="color-list-wrapper b-list facial-hair hair-list">
            <li (click)="facialhairclick('Blank')">
              <input
                type="radio"
                value="facial-hair"
                for="facial-hair1"
                name="facial-hair"
              />
              <label class="facial-hair1"></label>
              <div class="all-hair">
                <span class="no-hair">No Hair</span>
              </div>
            </li>
            <li (click)="facialhairclick('BeardMedium')">
              <input
                type="radio"
                value="facial-hair"
                for="facial-hair2"
                name="facial-hair"
              />
              <label class="facial-hair2">
                <img
                  src="../../../assets/images/facial-hair/facial-hair-1.png"
                  alt="facial-hair Image"
                />
              </label>
              <div class="all-hair">
                <span>BeardMedium</span>
              </div>
            </li>
            <li (click)="facialhairclick('BeardLight')">
              <input
                type="radio"
                value="facial-hair"
                for="facial-hair3"
                name="facial-hair"
              />
              <label class="facial-hair3">
                <img
                  src="../../../assets/images/facial-hair/facial-hair-2.png"
                  alt="facial-hair Image"
                />
              </label>
              <div class="all-hair">
                <span>BeardLight</span>
              </div>
            </li>
            <li (click)="facialhairclick('BeardMagestic')">
              <input
                type="radio"
                value="facial-hair"
                for="facial-hair4"
                name="facial-hair"
              />
              <label class="facial-hair4">
                <img
                  src="../../../assets/images/facial-hair/facial-hair-3.png"
                  alt="facial-hair Image"
                />
              </label>
              <div class="all-hair">
                <span>BeardMagestic</span>
              </div>
            </li>
            <li (click)="facialhairclick('MoustacheFancy')">
              <input
                type="radio"
                value="facial-hair"
                for="facial-hair5"
                name="facial-hair"
              />
              <label class="facial-hair5">
                <img
                  src="../../../assets/images/facial-hair/facial-hair-4.png"
                  alt="facial-hair Image"
                />
              </label>
              <div class="all-hair">
                <span>Moustache</span>
                <span>Fancy</span>
              </div>
            </li>
            <li (click)="facialhairclick('MoustacheMagnum')">
              <input
                type="radio"
                value="facial-hair"
                for="facial-hair6"
                name="facial-hair"
              />
              <label class="facial-hair6">
                <img
                  src="../../../assets/images/facial-hair/facial-hair-5.png"
                  alt="facial-hair Image"
                />
              </label>
              <div class="all-hair">
                <span>Moustache</span>
                <span>Magnum</span>
              </div>
            </li>
          </ul>
        </div>
        <div class="tab7">
          <ul
            class="color-list-wrapper Accessoriescolor ss-tag"
            *ngIf="Usercloth != 'BlazerShirt' && Usercloth != 'BlazerSweater'"
          >
            <li (click)="ClothcolorClick('Black')">
              <input type="radio" value="color" for="color1" name="color1" />
              <label class="color1"></label>
              <span>Black</span>
            </li>
            <li (click)="ClothcolorClick('Blue01')">
              <input type="radio" value="color" for="color2" name="color1" />
              <label class="color2"></label>
              <span>Blue1</span>
            </li>
            <li (click)="ClothcolorClick('Blue02')">
              <input type="radio" value="color" for="color3" name="color1" />
              <label class="color3"></label>
              <span>Blue2</span>
            </li>
            <li (click)="ClothcolorClick('Blue03')">
              <input type="radio" value="color" for="color4" name="color1" />
              <label class="color4"></label>
              <span>Blue3</span>
            </li>
            <li (click)="ClothcolorClick('Gray01')">
              <input type="radio" value="color" for="color5" name="color1" />
              <label class="color5"></label>
              <span>Grey1</span>
            </li>
            <li (click)="ClothcolorClick('Gray02')">
              <input type="radio" value="color" for="color6" name="color1" />
              <label class="color6"></label>
              <span>Grey2</span>
            </li>
            <li (click)="ClothcolorClick('Heather')">
              <input type="radio" value="color" for="color7" name="color1" />
              <label class="color7"></label>
              <span>Heather</span>
            </li>
            <li (click)="ClothcolorClick('PastelBlue')">
              <input type="radio" value="color" for="color8" name="color1" />
              <label class="color8"></label>
              <span>Pastel Blue</span>
            </li>
            <li (click)="ClothcolorClick('PastelGreen')">
              <input type="radio" value="color" for="color9" name="color1" />
              <label class="color9"></label>
              <span>Pastel Green</span>
            </li>
            <li (click)="ClothcolorClick('PastelOrange')">
              <input type="radio" value="color" for="color10" name="color1" />
              <label class="color10"></label>
              <span>Pastel Orange</span>
            </li>
            <li (click)="ClothcolorClick('PastelRed')">
              <input type="radio" value="color" for="color11" name="color1" />
              <label class="color11"></label>
              <span>Pastel Red</span>
            </li>
            <li (click)="ClothcolorClick('PastelYellow')">
              <input type="radio" value="color" for="color12" name="color1" />
              <label class="color12"></label>
              <span>Pastel Yellow</span>
            </li>
            <li (click)="ClothcolorClick('Pink')">
              <input type="radio" value="color" for="color13" name="color1" />
              <label class="color13"></label>
              <span>Pink</span>
            </li>
            <li (click)="ClothcolorClick('Red')">
              <input type="radio" value="color" for="color14" name="color1" />
              <label class="color14"></label>
              <span>Red</span>
            </li>
            <li (click)="ClothcolorClick('White')">
              <input type="radio" value="color" for="color15" name="color1" />
              <label class="color15"></label>
              <span>White</span>
            </li>
          </ul>
          <ul class="color-list-wrapper cloths b-list hair-list">
            <li (click)="ClothClick('BlazerShirt')">
              <input type="radio" value="cloths" for="cloths1" name="cloths" />
              <label class="cloths1">
                <img
                  src="../../../assets/images/cloths/cloths-1.png"
                  alt="cloths Image"
                />
              </label>
              <div class="all-hair">
                <span>Clothing</span>
                <span>Blazer+Shirt</span>
              </div>
            </li>
            <li (click)="ClothClick('BlazerSweater')">
              <input type="radio" value="cloths" for="cloths2" name="cloths" />
              <label class="cloths2">
                <img
                  src="../../../assets/images/cloths/cloths-2.png"
                  alt="cloths Image"
                />
              </label>
              <div class="all-hair">
                <span>ClothingBlazer</span>
                <span>+Sweater</span>
              </div>
            </li>
            <li (click)="ClothClick('CollarSweater')">
              <input type="radio" value="cloths" for="cloths3" name="cloths" />
              <label class="cloths3">
                <img
                  src="../../../assets/images/cloths/cloths-3.png"
                  alt="cloths Image"
                />
              </label>
              <div class="all-hair">
                <span>ClothingColler</span>
                <span>+Sweater</span>
              </div>
            </li>
            <li (click)="ClothClick('GraphicShirt')">
              <input type="radio" value="cloths" for="cloths4" name="cloths" />
              <label class="cloths4">
                <img
                  src="../../../assets/images/cloths/cloths-4.png"
                  alt="cloths Image"
                />
              </label>
              <div class="all-hair">
                <span>ClothingGraphic</span>
                <span>+Shirt</span>
              </div>
            </li>
            <li (click)="ClothClick('Hoodie')">
              <input type="radio" value="cloths" for="cloths5" name="cloths" />
              <label class="cloths5">
                <img
                  src="../../../assets/images/cloths/cloths-5.png"
                  alt="cloths Image"
                />
              </label>
              <div class="all-hair">
                <span>Clothing</span>
                <span>Hoodie</span>
              </div>
            </li>
            <li (click)="ClothClick('Overall')">
              <input type="radio" value="cloths" for="cloths6" name="cloths" />
              <label class="cloths6">
                <img
                  src="../../../assets/images/cloths/cloths-6.png"
                  alt="cloths Image"
                />
              </label>
              <div class="all-hair">
                <span>Clothing</span>
                <span>Overall</span>
              </div>
            </li>
            <li (click)="ClothClick('ShirtCrewNeck')">
              <input type="radio" value="cloths" for="cloths7" name="cloths" />
              <label class="cloths7">
                <img
                  src="../../../assets/images/cloths/cloths-7.png"
                  alt="cloths Image"
                />
              </label>
              <div class="all-hair">
                <span>ClothingShirt</span>
                <span>CrewNeck</span>
              </div>
            </li>
            <li (click)="ClothClick('ShirtScoopNeck')">
              <input type="radio" value="cloths" for="cloths8" name="cloths" />
              <label class="cloths8">
                <img
                  src="../../../assets/images/cloths/cloths-8.png"
                  alt="cloths Image"
                />
              </label>
              <div class="all-hair">
                <span>ClothingShirt</span>
                <span>ScoopNeck</span>
              </div>
            </li>
            <li (click)="ClothClick('ShirtVNeck')">
              <input type="radio" value="cloths" for="cloths9" name="cloths" />
              <label class="cloths9">
                <img
                  src="../../../assets/images/cloths/cloths-9.png"
                  alt="cloths Image"
                />
              </label>
              <div class="all-hair">
                <span>ClothingShirt</span>
                <span>VNeck</span>
              </div>
            </li>
          </ul>
          <ul
            class="color-list-wrapper b-list tshirt-logo ss-tag"
            *ngIf="Usercloth == 'GraphicShirt'"
          >
            <li (click)="Clothlogo('Bat')">
              <input
                type="radio"
                value="tshirt-logo"
                for="tshirt-logo1"
                name="tshirt-logo"
              />
              <label class="tshirt-logo1">
                <img
                  src="../../../assets/images/tshirt-logo/tshirt-logo-1.png"
                  alt="tshirt-logo Image"
                />
              </label>
              <span>GraphicBat</span>
            </li>
            <li (click)="Clothlogo('Bear')">
              <input
                type="radio"
                value="tshirt-logo"
                for="tshirt-logo2"
                name="tshirt-logo"
              />
              <label class="tshirt-logo2">
                <img
                  src="../../../assets/images/tshirt-logo/tshirt-logo-2.png"
                  alt="tshirt-logo Image"
                />
              </label>
              <span>GraphicBear</span>
            </li>
            <li (click)="Clothlogo('Cumbia')">
              <input
                type="radio"
                value="tshirt-logo"
                for="tshirt-logo3"
                name="tshirt-logo"
              />
              <label class="tshirt-logo3">
                <img
                  src="../../../assets/images/tshirt-logo/tshirt-logo-3.png"
                  alt="tshirt-logo Image"
                />
              </label>
              <span>GraphicCumbia</span>
            </li>
            <li (click)="Clothlogo('Deer')">
              <input
                type="radio"
                value="tshirt-logo"
                for="tshirt-logo4"
                name="tshirt-logo"
              />
              <label class="tshirt-logo4">
                <img
                  src="../../../assets/images/tshirt-logo/tshirt-logo-4.png"
                  alt="tshirt-logo Image"
                />
              </label>
              <span>GraphicBeer</span>
            </li>
            <li (click)="Clothlogo('Diamond')">
              <input
                type="radio"
                value="tshirt-logo"
                for="tshirt-logo5"
                name="tshirt-logo"
              />
              <label class="tshirt-logo5">
                <img
                  src="../../../assets/images/tshirt-logo/tshirt-logo-5.png"
                  alt="tshirt-logo Image"
                />
              </label>
              <span>GraphicDiamond</span>
            </li>
            <li (click)="Clothlogo('Hola')">
              <input
                type="radio"
                value="tshirt-logo"
                for="tshirt-logo6"
                name="tshirt-logo"
              />
              <label class="tshirt-logo6">
                <img
                  src="../../../assets/images/tshirt-logo/tshirt-logo-6.png"
                  alt="tshirt-logo Image"
                />
              </label>
              <span>GraphicHola</span>
            </li>
            <li (click)="Clothlogo('Pizza')">
              <input
                type="radio"
                value="tshirt-logo"
                for="tshirt-logo7"
                name="tshirt-logo"
              />
              <label class="tshirt-logo7">
                <img
                  src="../../../assets/images/tshirt-logo/tshirt-logo-7.png"
                  alt="tshirt-logo Image"
                />
              </label>
              <span>GraphicPizza</span>
            </li>
            <li (click)="Clothlogo('Resist')">
              <input
                type="radio"
                value="tshirt-logo"
                for="tshirt-logo8"
                name="tshirt-logo"
              />
              <label class="tshirt-logo8">
                <img
                  src="../../../assets/images/tshirt-logo/tshirt-logo-8.png"
                  alt="tshirt-logo Image"
                />
              </label>
              <span>GraphicResist</span>
            </li>
            <li (click)="Clothlogo('Selena')">
              <input
                type="radio"
                value="tshirt-logo"
                for="tshirt-logo9"
                name="tshirt-logo"
              />
              <label class="tshirt-logo9">
                <img
                  src="../../../assets/images/tshirt-logo/tshirt-logo-9.png"
                  alt="tshirt-logo Image"
                />
              </label>
              <span>GraphicSelena</span>
            </li>
            <li (click)="Clothlogo('SkullOutline')">
              <input
                type="radio"
                value="tshirt-logo"
                for="tshirt-logo10"
                name="tshirt-logo"
              />
              <label class="tshirt-logo10">
                <img
                  src="../../../assets/images/tshirt-logo/tshirt-logo-11.png"
                  alt="tshirt-logo Image"
                />
              </label>
              <span>GraphicSkull1</span>
            </li>
          </ul>
        </div>
        <div class="tab8">
          <ul class="color-list-wrapper accessories b-list hair-list">
            <li (click)="Topclick('Eyepatch')">
              <input
                type="radio"
                value="accessories"
                for="accessories1"
                name="accessories"
              />
              <label class="accessories1">
                <img
                  src="../../../assets/images/accessories/accessories-1.png"
                  alt="accessories Image"
                />
              </label>
              <div class="all-hair">
                <span>Eyepatch</span>
              </div>
            </li>
            <li (click)="Topclick('Hat')">
              <input
                type="radio"
                value="accessories"
                for="accessories2"
                name="accessories"
              />
              <label class="accessories2">
                <img
                  src="../../../assets/images/accessories/accessories-2.png"
                  alt="accessories Image"
                />
              </label>
              <div class="all-hair">
                <span>Hat</span>
              </div>
            </li>
            <li (click)="Topclick('Hijab')">
              <input
                type="radio"
                value="accessories"
                for="accessories3"
                name="accessories"
              />
              <label class="accessories3">
                <img
                  src="../../../assets/images/accessories/accessories-3.png"
                  alt="accessories Image"
                />
              </label>
              <div class="all-hair">
                <span>Hijabi</span>
              </div>
            </li>
            <li (click)="Topclick('Turban')">
              <input
                type="radio"
                value="accessories"
                for="accessories4"
                name="accessories"
              />
              <label class="accessories4">
                <img
                  src="../../../assets/images/accessories/accessories-4.png"
                  alt="accessories Image"
                />
              </label>
              <div class="all-hair">
                <span>Turban</span>
              </div>
            </li>
            <li (click)="Topclick('WinterHat1')">
              <input
                type="radio"
                value="accessories"
                for="accessories5"
                name="accessories"
              />
              <label class="accessories5">
                <img
                  src="../../../assets/images/accessories/accessories-5.png"
                  alt="accessories Image"
                />
              </label>
              <div class="all-hair">
                <span>WinterHat1</span>
              </div>
            </li>
            <li (click)="Topclick('WinterHat2')">
              <input
                type="radio"
                value="accessories"
                for="accessories6"
                name="accessories"
              />
              <label class="accessories6">
                <img
                  src="../../../assets/images/accessories/accessories-6.png"
                  alt="accessories Image"
                />
              </label>
              <div class="all-hair">
                <span>WinterHat2</span>
              </div>
            </li>
            <li (click)="Topclick('WinterHat3')">
              <input
                type="radio"
                value="accessories"
                for="accessories7"
                name="accessories"
              />
              <label class="accessories7">
                <img
                  src="../../../assets/images/accessories/accessories-7.png"
                  alt="accessories Image"
                />
              </label>
              <div class="all-hair">
                <span>WinterHat3</span>
              </div>
            </li>
            <li (click)="Topclick('WinterHat4')">
              <input
                type="radio"
                value="accessories"
                for="accessories8"
                name="accessories"
              />
              <label class="accessories8">
                <img
                  src="../../../assets/images/accessories/accessories-8.png"
                  alt="accessories Image"
                />
              </label>
              <div class="all-hair">
                <span>WinterHat4</span>
              </div>
            </li>
            <li (click)="Topclicks('Kurt')">
              <input
                type="radio"
                value="accessories"
                for="accessories9"
                name="accessories"
              />
              <label class="accessories9">
                <img
                  src="../../../assets/images/accessories/accessories-9.png"
                  alt="accessories Image"
                />
              </label>
              <div class="all-hair">
                <span>Kurt</span>
              </div>
            </li>
            <li (click)="Topclicks('Prescription01')">
              <input
                type="radio"
                value="accessories"
                for="accessories10"
                name="accessories"
              />
              <label class="accessories10">
                <img
                  src="../../../assets/images/accessories/accessories-10.png"
                  alt="accessories Image"
                />
              </label>
              <div class="all-hair">
                <span>Prescription1</span>
              </div>
            </li>
            <li (click)="Topclicks('Prescription02')">
              <input
                type="radio"
                value="accessories"
                for="accessories11"
                name="accessories"
              />
              <label class="accessories11">
                <img
                  src="../../../assets/images/accessories/accessories-11.png"
                  alt="accessories Image"
                />
              </label>
              <div class="all-hair">
                <span>Prescription2</span>
              </div>
            </li>
            <li (click)="Topclicks('Round')">
              <input
                type="radio"
                value="accessories"
                for="accessories12"
                name="accessories"
              />
              <label class="accessories12">
                <img
                  src="../../../assets/images/accessories/accessories-12.png"
                  alt="accessories Image"
                />
              </label>
              <div class="all-hair">
                <span>GlassesRound</span>
              </div>
            </li>
            <li (click)="Topclicks('Sunglasses')">
              <input
                type="radio"
                value="accessories"
                for="accessories13"
                name="accessories"
              />
              <label class="accessories13">
                <img
                  src="../../../assets/images/accessories/accessories-13.png"
                  alt="accessories Image"
                />
              </label>
              <div class="all-hair">
                <span>SunGlasses</span>
              </div>
            </li>
            <li (click)="Topclicks('Wayfarers')">
              <input
                type="radio"
                value="accessories"
                for="accessories14"
                name="accessories"
              />
              <label class="accessories14">
                <img
                  src="../../../assets/images/accessories/accessories-14.png"
                  alt="accessories Image"
                />
              </label>
              <div class="all-hair">
                <span>Wayfarers</span>
              </div>
            </li>
          </ul>
          <ul class="color-list-wrapper Accessoriescolor ss-tag">
            <li (click)="Userhatcolor('Black')">
              <input type="radio" value="color" for="color1" name="color1" />
              <label class="color1"></label>
              <span>Black</span>
            </li>
            <li (click)="Userhatcolor('Blue01')">
              <input type="radio" value="color" for="color2" name="color1" />
              <label class="color2"></label>
              <span>Blue1</span>
            </li>
            <li (click)="Userhatcolor('Blue02')">
              <input type="radio" value="color" for="color3" name="color1" />
              <label class="color3"></label>
              <span>Blue2</span>
            </li>
            <li (click)="Userhatcolor('Blue03')">
              <input type="radio" value="color" for="color4" name="color1" />
              <label class="color4"></label>
              <span>Blue3</span>
            </li>
            <li (click)="Userhatcolor('Gray01')">
              <input type="radio" value="color" for="color5" name="color1" />
              <label class="color5"></label>
              <span>Grey1</span>
            </li>
            <li (click)="Userhatcolor('Gray02')">
              <input type="radio" value="color" for="color6" name="color1" />
              <label class="color6"></label>
              <span>Grey2</span>
            </li>
            <li (click)="Userhatcolor('Heather')">
              <input type="radio" value="color" for="color7" name="color1" />
              <label class="color7"></label>
              <span>Heather</span>
            </li>
            <li (click)="Userhatcolor('PastelBlue')">
              <input type="radio" value="color" for="color8" name="color1" />
              <label class="color8"></label>
              <span>Pastel Blue</span>
            </li>
            <li (click)="Userhatcolor('PastelGreen')">
              <input type="radio" value="color" for="color9" name="color1" />
              <label class="color9"></label>
              <span>Pastel Green</span>
            </li>
            <li (click)="Userhatcolor('PastelOrange')">
              <input type="radio" value="color" for="color10" name="color1" />
              <label class="color10"></label>
              <span>Pastel Orange</span>
            </li>
            <li (click)="Userhatcolor('PastelRed')">
              <input type="radio" value="color" for="color11" name="color1" />
              <label class="color11"></label>
              <span>Pastel Red</span>
            </li>
            <li (click)="Userhatcolor('PastelYellow')">
              <input type="radio" value="color" for="color12" name="color1" />
              <label class="color12"></label>
              <span>Pastel Yellow</span>
            </li>
            <li (click)="Userhatcolor('Pink')">
              <input type="radio" value="color" for="color13" name="color1" />
              <label class="color13"></label>
              <span>Pink</span>
            </li>
            <li (click)="Userhatcolor('Red')">
              <input type="radio" value="color" for="color14" name="color1" />
              <label class="color14"></label>
              <span>Red</span>
            </li>
            <li (click)="Userhatcolor('White')">
              <input type="radio" value="color" for="color15" name="color1" />
              <label class="color15"></label>
              <span>White</span>
            </li>
          </ul>
        </div>
      </section>
    </div>
  </div>

  <div class="form-button-next">
    <button type="button" class="frm-next-btn" style="opacity: 0">
      Continue
    </button>
    <button type="button" class="frm-next-btn" (click)="uploadimage()">
      <span class="material-icons"> arrow_forward </span>
    </button>
  </div>
</form>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
