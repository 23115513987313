import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServicesService } from '../../../services.service';
import { AuthserviceService } from '../../../authservice.service';
import { TakesurveyService } from '../../../../app/services/takesurvey/takesurvey.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-domainresult',
  templateUrl: './domainresult.component.html',
  styleUrls: ['./domainresult.component.scss'],
})
export class DomainresultComponent implements OnInit {
  DomainList = [];

  constructor(
    public TakesurveyService: TakesurveyService,
    public ActiveRoute: ActivatedRoute,
    public service: ServicesService,
    public auth: AuthserviceService,
    public spinner: NgxSpinnerService,
    public router: Router,
    private location: Location,
  ) {}

  ngOnInit(): void {
    this.Domainlist();
  }

  back() {
    this.location.back();
  }

  radioChange(item, count) {
    item.AboveProficiency = false;
    item.BelowProficiency = false;
    item.checked = true;
    if (count == 1) {
      item.AboveProficiency = true;
    } else {
      item.BelowProficiency = true;
    }
  }

  checkValidation() {
    let Exit = this.DomainList.filter((x) => x.checked);
    if (Exit.length == this.DomainList.length) {
      return false;
    } else {
      return true;
    }
  }

  async Domainlist() {
    this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        var cohort_id = localStorage.getItem('cohort_id');
        await this.TakesurveyService.GetDomainlist(cohort_id, token).then(
          async (data) => {
            if (data[0].status == 200) {
              var list = JSON.parse(<string>data[0].json);
              this.DomainList = list.data[0];
              this.DomainList.forEach((element) => {
                (element['AboveProficiency'] = false),
                  (element['BelowProficiency'] = false),
                  (element['checked'] = false);
              });
              this.CheckDomainValue();
              await this.spinner.hide();
            }
          },
        );
      }
    });
  }

  async UpdateDomainValue() {
    try {
      await this.spinner.show();
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            await this.spinner.show();
            var token = session.getIdToken().getJwtToken();
            var cohort_id = localStorage.getItem('cohort_id');
            var user_id = localStorage.getItem('CisspUserId');
            let data = {
              user_id: user_id,
              cohort_id: cohort_id,
              per_domain: JSON.stringify(this.DomainList),
            };
            await this.TakesurveyService.UpdateSurveyActivity(data, token).then(
              async (data) => {
                if (data[0].status == 200) {
                  var list = JSON.parse(<string>data[0].json);
                  await this.router.navigate(['/surveythankyou']);
                }
                await this.spinner.hide();
              },
            );
          }
        });
    } catch (e) {
      await this.spinner.hide();
      this.service.ShowError(e.meassage);
    }
  }

  async CheckDomainValue() {
    try {
      await this.spinner.show();
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            await this.spinner.show();
            var token = session.getIdToken().getJwtToken();
            var cohort_id = localStorage.getItem('cohort_id');
            var user_id = localStorage.getItem('CisspUserId');
            await this.TakesurveyService.GetSurveyActivity(
              user_id,
              cohort_id,
              token,
            ).then(async (data) => {
              if (data[0].status == 200) {
                var list = JSON.parse(<string>data[0].json);
              }
              await this.spinner.hide();
            });
          }
        });
    } catch (e) {
      await this.spinner.hide();
      this.service.ShowError(e.meassage);
    }
  }
}
