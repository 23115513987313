<div class="forgetpass">
  <div class="login-form">
    <div class="logo">
      <img src="../../assets/cissplogo.png" alt="CISSP" />
    </div>
    <h3>
      <span>Password Recovery Email Sent</span> We've sent you an email with
      directions to reset your password.
    </h3>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
