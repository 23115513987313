import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServicesService } from '../../services.service';
import { AuthserviceService } from '../../authservice.service';
import { LoginflowService } from '../../data/loginflow.service';
import { VgApiService } from '@videogular/ngx-videogular/core';
import { Subscription } from 'rxjs';
import { SharedDataService } from 'src/app/services/SharedData/shared-data.service';
import { CourseserviceService } from 'src/app/services/course/courseservice.service';
import Player from '@vimeo/player';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent implements OnInit {
  get isVimeoVideo(): boolean {
    return ('' + this.welcomevideo).startsWith('https://vimeo.com');
  }
  vimeoPlayer?: any;
  videoEnded = false;
  data: any;
  Users = [];
  cohort_id: any;
  certi_id: number;
  CohortName: any;
  welcomevideo: any;
  StartDate: any;
  IsLinkExpored = false;
  is_selfpaced: any = '0';
  db_cohort_id: any = '';
  welcome_flow: any = '';
  timer: any;
  constructor(
    public LoginflowService: LoginflowService,
    public ActiveRoute: ActivatedRoute,
    public router: Router,
    public spinner: NgxSpinnerService,
    public service: ServicesService,
    public auth: AuthserviceService,
    public CourseserviceService: CourseserviceService,
    public sharedDataService: SharedDataService,
  ) {
    this.ActiveRoute.params.subscribe(async (params) => {
      try {
        this.data = JSON.parse(atob(decodeURIComponent(params['data'])));
        this.cohort_id = this.data.cohort;
        localStorage.setItem('cohort_id', this.data.cohort);
        await this.service
          .get_welcome_links(this.cohort_id)
          .then(async (data) => {
            var list = JSON.parse(<string>data[0].json);
            var cohortlist = list.data[0];
            window.localStorage.setItem(
              'CisspCourseId',
              cohortlist[0].certi_id,
            );
            this.certi_id = cohortlist[0].certi_id;
            this.CohortName = cohortlist[0].cohort_name;
            // Order of videos in onboarding:
            // hybrid_welcome_video on the welcome screen
            // self_welcome_video on the selfPacedVideo screen
            // live_welcome_video on the learningVideo screen
            if (cohortlist[0].course_type == null) {
              this.welcomevideo = cohortlist[0].hybrid_welcome_video;
              this.is_selfpaced = '0';
            } else {
              this.welcomevideo = cohortlist[0].hybrid_welcome_video;
              this.is_selfpaced = '1';
            }
          });

        if (this.isVimeoVideo) {
          this.setupVimeoPlayer(this.welcomevideo);
        }

        this.StartDate = this.data.date;
        await this.versions_getUserById(this.data.user_id);
        await this.LoginflowService.versions_getUserById(
          this.data.user_id,
        ).then(async (data) => {
          if (data[0].status == 200) {
            const usersData = JSON.parse(<string>data[0].json);
            const password = usersData?.data?.[0]?.password;
            const aliasname = usersData?.data?.[0]?.aliasname;
            const loggedUserId = localStorage.getItem('CisspUserId') ?? null;
            if (password !== 'REWerew889##' && aliasname) {
              if (this.data.user_id === parseInt(loggedUserId)) {
                this.listCourseByCohortId(this.data.user_id, this.cohort_id);
              } else {
                const name = usersData?.data?.[0]?.name;
                const email = usersData?.data?.[0]?.email;
                const courseData = {
                  cohort_id: this.cohort_id,
                  certi_id: this.certi_id,
                };
                const userData = name ?? email ?? '';
                this.LoginflowService.setLoginUserData(
                  userData,
                  email,
                  courseData,
                );
                await this.logout();
              }
            } else if (loggedUserId) {
              await this.logout(false);
            }
          }
        });
      } catch (error) {
        this.router.navigate(['/login']);
      }
    });
  }
  ngOnInit() {}

  async createUser() {
    await this.auth.CreateUser(this.Users[0].email).then(async (data) => {
      if (data[0].StatusCode == 1) {
        await this.spinner.hide();
      } else {
        await this.spinner.hide();
      }
    });
  }
  async versions_getUserById(user_id) {
    await this.LoginflowService.loginLogsBy(user_id).then(async (data) => {
      var list = JSON.parse(<string>data[0].json);

      await this.spinner.show();
      await this.LoginflowService.versions_getUserById(user_id).then(
        async (dataRes) => {
          if (dataRes[0].status == 200) {
            var list = JSON.parse(<string>dataRes[0].json);
            if (list.data.length <= 0) {
              this.router.navigate(['/login']);
            } else {
              this.Users = list.data;
            }
          } else {
            this.router.navigate(['/login']);
          }
        },
      );
      if (list.data.length > 0) {
        await this.onBoardingflow();
      } else {
        await this.onBoardingflow();
        this.IsLinkExpored = false;
      }
    });
    await this.spinner.hide();
  }
  async onBoardingflow() {
    var onboardigdata = {
      user_id: this.data.user_id,
      cohort_id: this.cohort_id,
      is_selfpaced: this.is_selfpaced,
    };
    await this.LoginflowService.add_onboarding_flow(onboardigdata).then(
      async (onboardingFlowRes) => {
        if (onboardingFlowRes[0].status == 200) {
          var list = JSON.parse(<string>onboardingFlowRes[0].json);
          if (!list.data.is_main) {
            if (!list.data.is_expire) {
              var updateOnboardingUser = {
                email: this.Users[0].email,
                onboard_cohort: this.cohort_id,
                is_selfpaced: this.is_selfpaced,
                welcome_flow: '7',
                course_id: this.cohort_id.toString(),
              };
              await this.LoginflowService.updateUserByEmail(
                updateOnboardingUser,
              ).then(async (userRes) => {
                if (userRes[0].status == 200) {
                  await this.update_onboarding_flow();
                  await this.LoginflowService.updateUserWelcomeFlow(
                    this.Users[0].email,
                    '7',
                  ).then(async (data) => {
                    var dataemail = {
                      email: this.Users[0].email,
                      password: this.data.password,
                      cohort_id: this.cohort_id,
                    };
                    this.IsLinkExpored = false;
                    var param = btoa(JSON.stringify(dataemail));
                    param = encodeURIComponent(param);
                    this.router.navigate(['login'], {
                      queryParams: { return: `/selfpacedvideo/${param}` },
                    });
                  });
                }
              });
              this.IsLinkExpored = false;
            } else {
              this.IsLinkExpored = true;
            }
          } else {
            if (!list.data.is_expire) {
              this.IsLinkExpored = false;
              var updateOnboardingUserdata = {
                email: this.Users[0].email,
                onboard_cohort: this.cohort_id,
                is_selfpaced: this.is_selfpaced,
              };
              await this.LoginflowService.updateUserByEmail(
                updateOnboardingUserdata,
              ).then((userRes) => {});
            } else {
              this.IsLinkExpored = true;
            }
          }
        } else {
          this.router.navigate(['/login']);
        }
      },
    );
  }

  async next() {
    await this.LoginflowService.UpdatedefaultCohortEmail(
      this.Users[0].email,
      this.cohort_id,
    ).then(async (data) => {});

    var userdata = {
      email: this.Users[0].email,
      password: localStorage.getItem('domaindata')
        ? JSON.parse(atob(localStorage.getItem('domaindata')))
        : this.Users[0].password,
      cohort_id: this.cohort_id,
    };
    var email = btoa(JSON.stringify(userdata));
    email = encodeURIComponent(email);
    let wel_flow =
      this.Users[0].welcome_flow != undefined ||
      this.Users[0].welcome_flow != null
        ? parseInt(this.Users[0].welcome_flow)
        : 0;
    if (wel_flow > 2) {
      if (wel_flow == 2) {
        this.router.navigate([`/alias/${email}`]);
        return;
      } else if (wel_flow == 3) {
        this.router.navigate([`/timezone/${email}`]);
        return;
      } else if (wel_flow == 4) {
        this.router.navigate([`/protectpass/${email}`]);
        return;
      } else if (wel_flow == 5) {
        this.router.navigate([`/onetimepsas/${email}`]);
        return;
      } else if (wel_flow == 6) {
        this.router.navigate([`/userimage/${email}`]);
        return;
      } else if (wel_flow == 7) {
        this.router.navigate([`/selfpacedvideo/${email}`]);
        return;
      } else if (wel_flow == 8) {
        this.router.navigate([`/customschedule/${email}`]);
        return;
      } else if (wel_flow == 9) {
        this.router.navigate([`/competition/${email}`]);
        return;
      } else if (wel_flow == 10) {
        this.router.navigate([`/learning/${email}`]);
        return;
      } else if (wel_flow == 11) {
        this.router.navigate([`/thankyou/${email}`]);
        return;
      }
    }
    await this.LoginflowService.updateUserWelcomeFlow(
      userdata.email,
      '2',
      this.cohort_id,
    ).then(async (data) => {
      this.router.navigate([`/alias/${email}`]);
    });
  }

  async update_onboarding_flow() {
    var onboardigdata = {
      user_id: this.data.user_id,
      cohort_id: this.cohort_id,
      is_expire: '1',
      welcome_flow: '5',
      link_expire_on: new Date(),
    };
    await this.LoginflowService.update_onboarding_flow(onboardigdata).then(
      (onboardingFlowRes) => {
        if (onboardingFlowRes[0].status == 200) {
          var list = JSON.parse(<string>onboardingFlowRes[0].json);
        } else {
          this.router.navigate(['/login']);
        }
      },
    );
  }

  async logout(navigateLogin: boolean = true) {
    const user = localStorage.getItem('userdetails') ?? '';
    const data = user ? JSON.parse(user) : null;
    if (data) {
      await this.service.setOfflineUser(data.email).then(async (res) => {
        await this.auth.logOut();
        await this.spinner.hide();
        this.sharedDataService.clearSharedData();
        window.localStorage.clear();
        window.sessionStorage.clear();
      });
    }
    if (navigateLogin) {
      await this.router.navigate(['/login']);
    }
  }

  async getResult(cid, cohort_id) {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        let user_id = localStorage.getItem('CisspUserId');
        this.spinner.show();
        await this.CourseserviceService.getDashboardPercentage(
          user_id,
          cid,
          cohort_id,
          token,
        ).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            let domainsData = list.data;

            let sum = 0;
            let i = 0;
            let w = [9, 11, 12, 12, 12, 12, 12, 12];
            domainsData.forEach((element) => {
              element.weight = w[i];
              i++;
              sum += element.totalPro;
            });
            localStorage.setItem('domainsData', JSON.stringify(domainsData));
            this.spinner.hide();
          } else {
            this.spinner.hide();
          }
        });
      }
    });
  }

  async listCourseByCohortId(userid, cohortId) {
    let courselist = [];
    try {
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();
            await this.CourseserviceService.knowassquecource(
              userid,
              token,
            ).then(async (data) => {
              if (data[0].status == 200) {
                var list = JSON.parse(<string>data[0].json);
                courselist = list.data;
                const filterCourse = courselist.filter(
                  (item) => item.cohort_id === cohortId,
                );
                if (filterCourse.length > 0)
                  await this.CertificationClickNew(filterCourse[0]);
                else this.router.navigate(['enrolledcourse']);
                await this.spinner.hide();
              } else if (data[0].status == 502) {
                this.listCourseByCohortId(userid, cohortId);
                await this.spinner.hide();
              } else {
                await this.spinner.hide();
              }
            });
          }
        });
    } catch (e) {
      await this.spinner.hide();
      this.service.ShowError(e.meassage);
    }
  }

  async CertificationClickNew(item) {
    this.service.courseid = item.certi_id;
    await this.getResult(item.certi_id, item.cohort_id);
    localStorage.setItem('CisspCourseId', item.certi_id);
    localStorage.setItem('cohort_id', item.cohort_id);
    localStorage.setItem('overall', item.percentage);
    localStorage.setItem('method', item.method_id);
    localStorage.setItem(
      'course_type',
      item.course_type ? item.course_type : '',
    );
    window.localStorage.setItem('accessitem', JSON.stringify(item.access));
    await this.router.navigate(['sidenav/dashboard']);
  }
  async addActivityTracker(user_id, email, cohort_id, type, message) {
    await this.service.addActivityTracker(
      user_id,
      email,
      cohort_id,
      type,
      message,
    );
  }
  async setupVimeoPlayer(vimeoUrl) {
    let playerHeight = 450;

    if (window.innerHeight > 1500) {
      playerHeight = 1400;
    } else if (window.innerHeight > 1100) {
      playerHeight = 850;
    } else if (window.innerHeight > 850) {
      playerHeight = 650;
    }

    this.vimeoPlayer = new Player('vimeoplayer', {
      url: this.getEmbedVimeoVideoUrl(vimeoUrl),
      height: playerHeight,
    });
    this.vimeoPlayer.on('play', () => {
      this.videoEnded = false;
    });
    this.vimeoPlayer.on('ended', () => {
      this.videoEnded = true;
    });

    await this.vimeoPlayer.ready();
  }

  getEmbedVimeoVideoUrl(vimeoUrl) {
    const regex = /vimeo\.com\/(\d+)\/([a-z0-9]+)/i;
    const matches = vimeoUrl.match(regex);

    if (matches && matches.length === 3) {
      const videoId = matches[1];
      const hash = matches[2];

      return `https://player.vimeo.com/video/${videoId}?h=${hash}`;
    } else {
      return null;
    }
  }
}
