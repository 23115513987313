<img src="../../../../assets/images/sidebar-logo.png" alt="" />
<div class="servey-center last-stage">
  <svg
    width="102"
    height="102"
    viewBox="0 0 102 102"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="51" cy="51" r="50" stroke="#F473B9" />
    <path
      d="M66.901 57.8554V57.8554C68.5103 57.8554 69.8164 59.1615 69.8164 60.7709C69.8164 62.3821 68.5103 63.6863 66.901 63.6863H64.9573C66.5667 63.6863 67.8728 64.9924 67.8728 66.6018C67.8728 68.213 66.5667 68.5454 64.9573 68.5454H52.3237C46.7824 68.5454 45.521 67.5736 38.7183 66.6018V50.0809C43.4802 50.0809 51.3519 41.3345 51.3519 33.56C51.3519 30.4871 55.6046 29.3423 57.1828 34.9574C58.1546 38.4191 55.2392 45.2218 55.2392 45.2218H70.7882C72.3976 45.2218 73.7037 46.5279 73.7037 48.1372C73.7037 49.7485 72.3976 52.0245 70.7882 52.0245H68.8446C70.4539 52.0245 71.7601 53.3306 71.7601 54.94C71.7601 56.5512 70.4539 57.8554 68.8446 57.8554H66.901"
      stroke="#F473B9"
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M36.9339 70.489H30.7843C29.7988 70.489 29 69.6902 29 68.7048V48.9497C29 47.9642 29.7988 47.1654 30.7843 47.1654H36.9339C37.9193 47.1654 38.7182 47.9642 38.7182 48.9497V68.7048C38.7182 69.6902 37.9193 70.489 36.9339 70.489Z"
      stroke="#F473B9"
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
  <h2>Thank you very much</h2>
  <h4>for taking the time to complete our survey!</h4>
</div>
