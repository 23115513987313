<img src="../../../../assets/images/sidebar-logo.png" alt="" />

<div class="servey-center step-3 sm-off-domain">
  <h4>What were your results per domain?</h4>
  <ul class="pre-exam">
    <li class="th"></li>
    <li class="th">Above proficiency</li>
    <li class="th">Below proficiency</li>
  </ul>
  <div class="option-wrapper" *ngFor="let item of DomainList; let i = index">
    <div class="ow ow_flex">
      <div class="spannumber">{{ i + 1 }}.</div>
      <span> {{ item.domin_name }}</span>
    </div>
    <div class="ow">
      <input
        type="radio"
        (change)="radioChange(item, 1)"
        name="RiskButton{{ i }}"
        [checked]="item.AboveProficiency"
      />
      <label></label>
    </div>
    <div class="ow">
      <input
        type="radio"
        (change)="radioChange(item, 2)"
        name="RiskButton{{ i }}"
        [checked]="item.BelowProficiency"
      />
      <label></label>
    </div>
  </div>
</div>
<!-- Mobile -->
<div class="servey-center step-3 md-off-domain">
  <h4>What were your results per domain?</h4>
  <ul class="pre-exam">
    <li class="th"></li>
    <li class="th">Above proficiency</li>
    <li class="th">Below proficiency</li>
  </ul>
  <div class="option-wrapper" *ngFor="let item of DomainList; let i = index">
    <div class="ow_flex">
      <div class="spannumber">{{ i + 1 }}.</div>
      <span> {{ item.domin_name }}</span>
    </div>
    <div class="above-below">
      <div class="ow">
        <input
          type="radio"
          (change)="radioChange(item, 1)"
          name="RiskButton{{ i }}"
          [checked]="item.AboveProficiency"
        />
        <label></label>
        <span for=""
          >Above<br />
          proficiency</span
        >
      </div>
      <div class="ow">
        <input
          type="radio"
          (change)="radioChange(item, 2)"
          name="RiskButton{{ i }}"
          [checked]="item.BelowProficiency"
        />
        <label></label>
        <span for="">Below <br />proficiency</span>
      </div>
    </div>
  </div>
</div>

<div class="form-button-next">
  <button type="button" class="frm-prev-btn">
    <span class="material-icons" (click)="back()">arrow_back</span>
  </button>
  <button
    type="button"
    class="frm-next-btn"
    (click)="UpdateDomainValue()"
    [disabled]="checkValidation()"
  >
    <span class="material-icons"> arrow_forward </span>
  </button>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
