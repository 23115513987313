import { Component, OnInit } from '@angular/core';
import { ServicesService } from '../services.service';

@Component({
  selector: 'app-noconnection',
  templateUrl: './noconnection.component.html',
  styleUrls: ['./noconnection.component.scss'],
})
export class NoconnectionComponent implements OnInit {
  constructor(public service: ServicesService) {}

  ngOnInit(): void {}
  retuey() {
    window.location.reload();
  }
}
