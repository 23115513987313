<div *ngIf="service.status == 'ONLINE'">
  <div class="form-button-next">
    <div class="logo-div">
      <img src="../../../assets/images/sidebar-logo.png" alt="Logo" />
    </div>
    <div class="main-body">
      <label>Create Nickname/Aliasname</label>
      <input
        [ngClass]="{ wronge: errorshow }"
        type="text"
        placeholder="Nickname/Aliasname"
        (keyup)="checkAlias()"
        [(ngModel)]="name"
      />
      <a *ngIf="errorshow">Someone already has that username. Try another?</a>
      <a class="success" *ngIf="perfectShow">Username looks perfect!</a>
    </div>
    <div class="next-btn">
      <button
        type="button"
        class="frm-prev-btn"
        (click)="alisnamechange()"
        [disabled]="errorshow || !name"
      >
        <span class="material-icons"> arrow_forward </span>
      </button>
    </div>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
<div class="no-internet" *ngIf="service.status == 'OFFLINE'">
  <img [src]="service.srcData" alt="" />
  <h2>Uh-Oh!</h2>
  <h3>Slow or no internet Connection.</h3>
  <h6>Please check your internet settings and try again</h6>
  <button (click)="retuey()">Retry</button>
</div>
