import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServicesService } from '../services.service';
import { WebsocketService } from '../services/websocket.service';
import { LivequeserviceService } from '../services/livequestion/livequeservice.service';

@Component({
  selector: 'app-studentlive',
  templateUrl: './studentlive.component.html',
  styleUrls: ['./studentlive.component.scss'],
})
export class StudentliveComponent implements OnInit {
  UserName: any;
  UserImage: any;
  Answer: any;
  RightAnswer: any;
  CurrentUser: any;
  websocket: WebSocket;
  QuestionSr_no: any;
  QuestionId: any;
  TestId: any;
  UserId: any;
  ShowAns: boolean = false;
  TestStart: boolean = false;
  TestFinish: boolean = true;

  constructor(
    public socket: WebsocketService,
    public service: ServicesService,
    private ActiveRoute: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    public LivequeserviceService: LivequeserviceService,
  ) {
    this.ActiveRoute.params.subscribe(async (params) => {
      try {
        this.CurrentUser = JSON.parse(atob(decodeURIComponent(params['id'])));
        await this.SocketConnect();
        await this.getUserById(this.CurrentUser.user_id);
      } catch (error) {}
    });
  }
  ngOnInit() {
    var d = { user_id: 2017, test_id: 1, roll_id: 3, q_id: 1, sr_no: 1 };
    setTimeout(() => {
      this.socket.OnMessage().subscribe((result) => {
        let data = JSON.parse(result);
        if (data.message == 'Next Question.') {
          this.TestStart = true;
          this.QuestionSr_no = data.data.sr_no;
          this.QuestionId = data.data.q_id;
          this.TestId = data.data.test_id;
          this.UserId = data.data.user_id;
          this.Answer = null;
          this.RightAnswer = null;
          this.ShowAns = false;
        } else if (data.message == 'Show Answer') {
          if (data.data.sr_no == 'true') {
            this.TestFinish = true;
          } else {
            this.RightAnswer = data.data.sr_no;
            this.ShowAns = true;
          }
        }
      });
    }, 1000);
  }
  async getUserById(user_id) {
    await this.LivequeserviceService.getUserByIdLiveQ(user_id).then(
      async (data) => {
        if (data[0].status == 200) {
          var list = JSON.parse(<string>data[0].json);
          this.UserName = list.data[0].name;
          this.UserImage = list.data[0].avatar_url;
        }
      },
    );
  }
  async SocketConnect() {
    let MessageArr = {
      action: 'message',
      msg: {
        action: 'connected',
        user_id: this.CurrentUser.user_id,
        test_id: this.CurrentUser.test_id,
        roll_id: this.CurrentUser.roll_id,
        q_id: this.CurrentUser.q_id,
        sr_no: this.CurrentUser.sr_no,
      },
    };
    this.socket.Connect();
    this.socket
      .OnOpen()
      .then((result) => {
        this.socket
          .DoSend(MessageArr)
          .then((result) => {})
          .catch((err) => {});
      })
      .catch((err) => {});
  }
  postAnswer(answer, option) {
    if (this.Answer == null) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You select ' + option,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirm',
      }).then(async (result) => {
        if (result.value) {
          this.Answer = answer;
          await this.LivequeserviceService.liveTestPostAnswer(
            this.QuestionId,
            this.Answer,
            this.CurrentUser.user_id,
            this.TestId,
          ).then(async (data) => {
            if (data[0].status == 200) {
              var list = JSON.parse(<string>data[0].json);
            }
          });
        }
      });
    }
  }
}
