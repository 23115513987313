<div class="userr clearfix">
  <div class="user-pro">
    <img
      [src]="UserImage"
      alt="user"
      id="UserImage"
      onError="this.src='assets/user.png' "
    />
    <span>{{ UserName }} </span>
    <span class="material-icons"> expand_more </span>
  </div>
</div>
<div class="live-ques" *ngIf="TestStart">
  <div class="live-ques-top">
    <h2>#{{ QuestionSr_no }}</h2>
  </div>
  <div class="live-ques-bottom">
    <div class="question-option">
      <button
        (click)="postAnswer('1', 'A')"
        [ngClass]="[
          Answer == 1 ? 'active' : '',
          RightAnswer == 1 ? 'right' : ''
        ]"
        [disabled]="Answer != null || ShowAns"
      >
        A
      </button>
      <button
        (click)="postAnswer('2', 'B')"
        [ngClass]="[
          Answer == 2 ? 'active' : '',
          RightAnswer == 2 ? 'right' : ''
        ]"
        [disabled]="Answer != null || ShowAns"
      >
        B
      </button>
      <button
        (click)="postAnswer('3', 'C')"
        [ngClass]="[
          Answer == 3 ? 'active' : '',
          RightAnswer == 3 ? 'right' : ''
        ]"
        [disabled]="Answer != null || ShowAns"
      >
        C
      </button>
      <button
        (click)="postAnswer('4', 'D')"
        [ngClass]="[
          Answer == 4 ? 'active' : '',
          RightAnswer == 4 ? 'right' : ''
        ]"
        [disabled]="Answer != null || ShowAns"
      >
        D
      </button>
    </div>
    <div class="que-result" *ngIf="ShowAns">
      <div class="crt" *ngIf="Answer == RightAnswer">
        <span class="material-icons">done</span>
        <span class="correct">Correct Answer</span>
      </div>
      <div class="wrg" *ngIf="Answer != RightAnswer">
        <span class="material-icons">close</span>
        <span class="wrong">Wrong Answer</span>
      </div>
    </div>
  </div>
</div>
<div class="starttest" *ngIf="!TestStart">
  <p>Test will start soon..</p>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
