import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VgApiService } from '@videogular/ngx-videogular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthserviceService } from 'src/app/authservice.service';
import { VideoserviceService } from 'src/app/services/video/videoservice.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-eventrecordings',
  templateUrl: './eventrecordings.component.html',
  styleUrls: ['./eventrecordings.component.scss'],
})
export class EventrecordingsComponent implements OnInit {
  api: VgApiService;
  currentIndex = 0;
  MeetingId: any;
  defaultPath = '';
  currentItem = {
    id: '',
    certi_id: '',
    event_id: '',
    title: '',
    meeting_id: '',
    status: '',
    createdAt: '',
    updatedAt: '',
    duration: '',
    questions: '',
    recording_url: '',
    recording_url_720p: '',
    recording_url_480p: '',
    recording_url_360p: '',
    recording_url_240p: '',
    recording_url_144p: '',
    is_playing: '',
  };
  mobilePopup: boolean = false;
  mobileTitle: any;
  Recordings = [];
  playlist = [];
  VideoLink: any;
  type_id: any;
  showControls: boolean = false;
  showSettingsPopup: boolean = false;
  showPlaybackPopup: boolean = false;
  showVideoQuality: boolean = false;
  activeSettings: boolean = false;
  playbackSpeedOptions: string[] = [
    '0.25',
    '0.5',
    '0.75',
    'Normal',
    '1.25',
    '1.5',
    '1.75',
    '2.0',
  ];
  selectedPlaybackSpeed: string = 'Normal';
  videoQualityOptions: string[] = [];
  selectedVideoQuality: string = '1080p HD';
  selectedVideo: any;
  timer: any;

  constructor(
    public spinner: NgxSpinnerService,
    private location: Location,
    public auth: AuthserviceService,
    public router: Router,
    public ActiveRoute: ActivatedRoute,
    public videoService: VideoserviceService,
    public EleRef: ElementRef,
  ) {
    this.ActiveRoute.params.subscribe(async (params) => {
      try {
        this.type_id = JSON.parse(atob(params['eventid']));
      } catch (error) {}
    });
  }
  @ViewChild('videoElement') videoElement!: ElementRef;
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event): void {
    if (!this.videoElement?.nativeElement.contains(event.target)) {
      if (
        (!this.showPlaybackPopup && this.showVideoQuality) ||
        (this.showPlaybackPopup && !this.showVideoQuality)
      ) {
        this.toggleSettings();
      } else if (this.showSettingsPopup) {
        this.toggleSettings();
      }
    }
  }

  ngOnInit(): void {
    this.getEventRecording();
  }

  resetTimer() {
    this.showControls = true;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.hideControls();
    }, 3000);
  }

  hideControls() {
    this.showControls = false;
  }

  eventClick(item) {
    this.selectedVideo = item;
    this.mobileTitle = '';
    if (window.innerWidth <= 768) {
      this.mobilePopup = true;
      this.mobileTitle = item.title;
    }
    this.Recordings.forEach((element) => {
      element['is_playing'] = false;
    });
    item['is_playing'] = true;
    this.currentItem = item;
    this.VideoLink = item.recording_url;
    this.selectedPlaybackSpeed = 'Normal';
    this.selectedVideoQuality = '1080p HD';
    this.videoQualityOptions = [];
    if (this.currentItem.recording_url) {
      this.videoQualityOptions.push('1080p HD');
    }
    if (this.currentItem.recording_url_720p) {
      this.videoQualityOptions.push('720p');
    }
    if (this.currentItem.recording_url_480p) {
      this.videoQualityOptions.push('480p');
    }
    if (this.currentItem.recording_url_360p) {
      this.videoQualityOptions.push('360p');
    }
    if (this.currentItem.recording_url_240p) {
      this.videoQualityOptions.push('240p');
    }
    if (this.currentItem.recording_url_144p) {
      this.videoQualityOptions.push('144p');
    }

    var player = this.EleRef.nativeElement.querySelector('video');
    player.addEventListener('timeupdate', async () => {
      var audi = document.getElementsByTagName('video');
      if (audi[0]?.duration.toString() != 'NaN') {
        if (audi.length > 0) {
          item.questions.forEach((chapt) => {
            var hms = chapt.FromTime;
            var a = hms.split(':');
            var seconds = parseInt(a[0]) * 60 + parseInt(a[1]);
            if (seconds < audi[0].currentTime) {
              item.questions.forEach((c) => {});
              chapt.done = true;
              chapt.active = false;
            }
          });
        }
      }
    });
  }
  questionClick(que, item) {
    item.questions.forEach((element) => {
      if (element['active'] == true) {
        element['done'] = true;
      }
      element['active'] = false;
    });
    que['active'] = true;
    var hms = que.FromTime;
    var a = hms.split(':');
    var seconds = parseInt(a[0]) * 60 + parseInt(a[1]);
    const video = document.getElementsByTagName('video');
    video[0].currentTime = seconds;
  }
  async getEventRecording() {
    this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        this.spinner.show();
        var cohort_id = localStorage.getItem('cohort_id');
        var course_type = localStorage.getItem('course_type');
        var method = localStorage.getItem('method');
        if (method == '0') {
          course_type = 'live';
        }
        let request = {
          cohort_id: cohort_id,
          method_type: course_type,
        };
        await this.videoService
          .getEventRecording(request, token)
          .then(async (data) => {
            if (data[0].status == 200) {
              var list = JSON.parse(<string>data[0].json);
              this.Recordings = list.data.filter(
                (event) =>
                  event.recording_url !== null && event.recording_url !== '',
              );
              if (this.Recordings.length > 0) {
                this.MeetingId = this.Recordings[0].meeting_id;
                this.Recordings.forEach((element, ind) => {
                  if (element.questions) {
                    element.questions = JSON.parse(element.questions);
                  } else {
                    element.questions = [];
                  }
                  if (element.event_id == this.type_id) {
                    element['is_playing'] = true;
                    this.eventClick(element);
                  } else element['is_playing'] = false;

                  const video = document.createElement('video');
                  video.src = element.recording_url;

                  video.addEventListener('loadedmetadata', () => {
                    const durationInSeconds = video.duration;
                    let minute = (durationInSeconds / 60).toFixed(2);
                    let second = (parseInt(minute.split('.')[1]) * 60)
                      .toString()
                      .substr(0, 2);
                    let time =
                      Math.round(parseInt(minute)) + 'm:' + second + 's';
                    element.duration = time;
                  });

                  video.preload = 'metadata';
                  video.load();
                });
                this.Recordings = this.Recordings.reverse();
              }
            }
          });
        setTimeout(() => {
          this.spinner.hide();
        }, 5000);
      }
    });
  }
  async back() {
    this.location.back();
  }
  toggleSettings() {
    if (
      !this.showSettingsPopup &&
      (this.showPlaybackPopup || this.showVideoQuality)
    ) {
      this.showSettingsPopup = false;
      this.showPlaybackPopup = false;
      this.showVideoQuality = false;
    } else {
      this.showSettingsPopup = !this.showSettingsPopup;
    }
    this.activeSettings = !this.activeSettings;
  }
  showoption(selectedOption: any) {
    if (selectedOption == 'PlaybackOptions') {
      setTimeout(() => {
        this.showPlaybackPopup = true;
      }, 10);
      this.showSettingsPopup = false;
    } else if (selectedOption == 'QualityOptions') {
      setTimeout(() => {
        this.showVideoQuality = true;
      }, 10);
      this.showSettingsPopup = false;
    }
  }
  backShowoption(selectedOption: any) {
    if (selectedOption == 'PlaybackOptions') {
      this.showPlaybackPopup = false;
      setTimeout(() => {
        this.showSettingsPopup = true;
      }, 10);
    } else if (selectedOption == 'QualityOptions') {
      this.showVideoQuality = false;
      setTimeout(() => {
        this.showSettingsPopup = true;
      }, 10);
    }
  }
  setPlaybackSpeed(speed: string) {
    this.api.playbackRate = speed == 'Normal' ? '1.0' : speed;
    this.selectedPlaybackSpeed = speed;
  }
  async setVideoQuality(quality: string) {
    this.spinner.show();
    this.selectedVideoQuality = quality;
    var audi = document.getElementsByTagName('video');
    var currentTime = audi[0].currentTime;
    await this.changetranscode(this.selectedVideo);
    await this.api.play();
    setTimeout(() => {
      var vid = document.getElementsByTagName('video');
      vid[0].currentTime = currentTime;
      this.api.playbackRate =
        this.selectedPlaybackSpeed == 'Normal'
          ? '1.0'
          : this.selectedPlaybackSpeed;
    }, 1000);
    this.spinner.hide();
  }
  onPlayerReady(api: VgApiService) {
    this.api = api;
    let me = this;
    document.onkeydown = function (event) {
      const video = document.getElementsByTagName('video');
      switch (event.keyCode) {
        case 37: //ArrowLeft-  Backward 10 sec
          video[0].currentTime = video[0].currentTime - 10;
          break;
        case 38: //ArrowUp- volume increase
          if (video[0].volume != 1) video[0].volume += 0.1;
          break;
        case 39: //ArrowRight- Forward 10 sec
          video[0].currentTime = video[0].currentTime + 10;
          break;
        case 40: //ArrowDown- Volume decrease
          if (video[0].volume > 0.1) video[0].volume -= 0.1;
          break;
        case 32: //SpaceBar- Play Pause
          event.preventDefault();
          video[0].paused ? video[0].play() : video[0].pause();
          break;
        case 70: //F- Fullscreen
          me.api.fsAPI.toggleFullscreen();
          break;
        case 74: //J- Backward 10 sec
          video[0].currentTime = video[0].currentTime - 10;
          break;
        case 75: //K- Play Paused
          video[0].paused ? video[0].play() : video[0].pause();
          break;
        case 76: //L- Forward 10 sec
          video[0].currentTime = video[0].currentTime + 10;
          break;
        case 77: //M- Mute Unmute
          video[0].volume = video[0].volume == 1 ? 0 : 1;
          break;
        case 188: //>- Slow down video playback rate
          if (video[0].playbackRate > 0.25) video[0].playbackRate -= 0.25;
          me.playbackRate(video[0].playbackRate);
          break;
        case 190: //>- Speedup video playback rate
          video[0].playbackRate += 0.25;
          me.playbackRate(video[0].playbackRate);
          break;
      }
    };
    this.api
      .getDefaultMedia()
      .subscriptions.loadedMetadata.subscribe(this.playVideo.bind(this));
    this.api
      .getDefaultMedia()
      .subscriptions.ended.subscribe(this.nextVideo.bind(this));
  }
  playbackRate(item) {
    this.selectedPlaybackSpeed = item == 1 ? 'Normal' : item;
  }
  playVideo() {
    this.api.play();
  }
  async changetranscode(currentItem) {
    if (
      this.selectedVideoQuality == '720p' &&
      this.currentItem.recording_url_720p
    ) {
      this.VideoLink = this.currentItem.recording_url_720p;
    } else if (
      this.selectedVideoQuality == '480p' &&
      this.currentItem.recording_url_480p
    ) {
      this.VideoLink = this.currentItem.recording_url_480p;
    } else if (
      this.selectedVideoQuality == '360p' &&
      this.currentItem.recording_url_360p
    ) {
      this.VideoLink = this.currentItem.recording_url_360p;
    } else if (
      this.selectedVideoQuality == '240p' &&
      this.currentItem.recording_url_240p
    ) {
      this.VideoLink = this.currentItem.recording_url_240p;
    } else if (
      this.selectedVideoQuality == '144p' &&
      this.currentItem.recording_url_144p
    ) {
      this.VideoLink = this.currentItem.recording_url_144p;
    } else {
      this.VideoLink = this.currentItem.recording_url;
    }
  }
  nextVideo() {
    this.currentIndex++;
    if (this.currentIndex === this.playlist.length) {
      this.currentIndex = 0;
    }
    this.currentItem = this.playlist[this.currentIndex];
    this.VideoLink = this.currentItem.recording_url;
  }
  forwordvideo() {
    const video = document.getElementsByTagName('video');
    video[0].currentTime = video[0].currentTime + 10;
  }
  backwordvideo() {
    const video = document.getElementsByTagName('video');
    video[0].currentTime = video[0].currentTime - 10;
  }
}
