// import { enableProdMode, isDevMode } from '@angular/core';
// import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

// import { AppModule } from './app/app.module';
// import { environment } from './environments/environment';
// import { inject } from '@vercel/analytics';

// if (environment.production) {
//   enableProdMode();
// }

// const bootstrapApplication = async () => {
//   // TODO: Add code to disable dev mode when published to production
//   inject();
//   // if (isDevMode()) {
//   // await import('@vercel/analytics').then((analyticsModule) => {
//   //   analyticsModule.inject({ mode: 'development' });
//   // });
//   // } else {
//   //   await import('@vercel/analytics').then((analyticsModule) => {
//   //     analyticsModule.inject({ mode: 'production' });
//   //   });
//   // }
//   const platform = platformBrowserDynamic();
//   await platform.bootstrapModule(AppModule);
// };

// bootstrapApplication().catch((err) => console.error(err));

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
