import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServicesService } from '../services.service';
import { AuthserviceService } from '../authservice.service';
import { ActivatedRoute } from '@angular/router';
import { LoginflowService } from '../data/loginflow.service';
import { ConfirmedValidator } from '../validation';

@Component({
  selector: 'app-resetpassauth',
  templateUrl: './resetpassauth.component.html',
  styleUrls: ['./resetpassauth.component.scss'],
})
export class ResetpassauthComponent implements OnInit {
  newpass: any;
  Confirmpass: any;
  Authcode: any;
  email: any;
  submitted = false;
  PassLength: boolean = false;
  strangth = 0;
  IsSmall: boolean = false;
  IsUpper: boolean = false;
  Number: boolean = false;
  IsSpecial: boolean = false;
  Checkpassword: boolean = false;
  PassWidth = 0;
  PassMsg = 'Poor';
  oldpassword: any;
  data: any;
  changepasstype = 'password';
  showCriteria: boolean = false;
  public AuthForm: FormGroup;
  constructor(
    public ActiveRoute: ActivatedRoute,
    public service: ServicesService,
    public auth: AuthserviceService,
    public spinner: NgxSpinnerService,
    public fb: FormBuilder,
    public router: Router,
    public LoginflowService: LoginflowService,
  ) {
    this.ActiveRoute.params.subscribe(async (params) => {
      try {
        this.email = JSON.parse(atob(decodeURIComponent(params['email'])));
      } catch (error) {
        this.router.navigate(['/login']);
      }
    });
  }
  get f() {
    return this.AuthForm.controls;
  }

  ngOnInit(): void {
    this.AuthForm = this.fb.group(
      {
        Confirmpass: [
          '',
          [
            Validators.required,
            Validators.pattern(
              '(?=.*[A-Za-z])(?=.*[0-9])(?=.*[$@$!#^~%*?&,.<>"\'\\;:{\\}\\[\\]\\|\\+\\-\\=\\_\\)\\(\\)\\`\\/\\\\\\]])[A-Za-z0-9d$@].{7,}',
            ),
          ],
        ],
        newpass: [
          '',
          [
            Validators.required,
            Validators.pattern(
              '(?=.*[A-Za-z])(?=.*[0-9])(?=.*[$@$!#^~%*?&,.<>"\'\\;:{\\}\\[\\]\\|\\+\\-\\=\\_\\)\\(\\)\\`\\/\\\\\\]])[A-Za-z0-9d$@].{7,}',
            ),
          ],
        ],
        Authcode: ['', Validators.required],
      },
      {
        validator: ConfirmedValidator('newpass', 'Confirmpass'),
      },
    );
  }
  showpass(item) {
    this.changepasstype = item;
  }
  PasswordCheck() {
    if (this.newpass.length > 0) {
      this.showCriteria = true;
      this.PassWidth = 0;
      this.IsSmall = false;
      this.IsUpper = false;
      this.Number = false;
      this.IsSpecial = false;
      this.PassLength = false;
      this.PassMsg = 'Poor';
      var cnt = 0;
      if (/[a-z]/.test(this.newpass)) {
        this.IsSmall = true;
      }
      if (this.newpass.length >= 8) {
        this.PassLength = true;
      }
      if (
        this.newpass &&
        this.Confirmpass &&
        this.newpass == this.Confirmpass
      ) {
        this.Checkpassword = true;
      } else {
        this.Checkpassword = false;
      }
      if (/[A-Z]/.test(this.newpass)) {
        this.IsUpper = true;
      }

      if (/[0-9]/.test(this.newpass)) {
        this.Number = true;
      }
      if (/[^a-zA-Z0-9]/.test(this.newpass)) {
        this.IsSpecial = true;
      }
      if (this.PassLength) {
        this.PassWidth += 20;
      }
      if (this.IsSmall) {
        this.PassWidth += 20;
      }
      if (this.IsUpper) {
        this.PassWidth += 20;
      }
      if (this.Number) {
        this.PassWidth += 20;
      }
      if (this.IsSpecial) {
        this.PassWidth += 20;
      }

      if (this.PassWidth == 20 || this.PassWidth == 40) {
        this.PassMsg = 'Poor';
      }
      if (this.PassWidth == 60 || this.PassWidth == 80) {
        this.PassMsg = 'Good';
      }
      if (this.PassWidth == 100) {
        this.PassMsg = 'Strong';
      }
    } else {
      this.showCriteria = false;
    }
  }
  async ConfimCode() {
    if (this.AuthForm.valid) {
      this.spinner.show();
      await this.LoginflowService.versions_getUserByEmail(this.email).then(
        async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            if (list.data.length > 0) {
              this.email = list.data[0].email;
              await this.auth
                .forgotPasswordAuthcode(this.email, this.Authcode, this.newpass)
                .then(async (data) => {
                  if (data[0].StatusCode == 1) {
                    await this.LoginflowService.saveAuthyDetails(
                      list.data[0].id,
                      this.email,
                      this.newpass,
                      0,
                      0,
                    ).then((response) => {});
                    await this.addActivityTracker(
                      0,
                      this.email,
                      0,
                      'Change Password',
                      'Change Password - Password change successfully',
                    );
                    this.spinner.hide();
                    this.service.ShowSuccess(data[0].message);
                    this.router.navigate(['/login']);
                  } else {
                    await this.addActivityTracker(
                      0,
                      this.email,
                      0,
                      'Change Password',
                      'Change Password failed- ' + data[0].message,
                    );
                    this.spinner.hide();
                    this.service.ShowError(data[0].message);
                  }
                });
            } else {
              this.service.ShowError('User not found');
              await this.spinner.hide();
            }
          } else {
            this.service.ShowError(data[0].message);
            await this.spinner.hide();
          }
        },
      );
    } else {
      this.submitted = true;
    }
  }
  async resend() {
    await this.spinner.show();
    await this.LoginflowService.versions_getUserByEmail(this.email).then(
      async (data) => {
        if (data[0].status == 200) {
          var list = JSON.parse(<string>data[0].json);
          if (list.data.length > 0) {
            this.email = list.data[0].email;
            await this.auth
              .resendConfirmationCode(this.email)
              .then(async (data) => {
                if (data[0].StatusCode == 1) {
                  this.service.ShowSuccess(data[0].message);
                  await this.spinner.hide();
                } else {
                  if (
                    data[0].message ==
                    'Username/client id combination not found.'
                  ) {
                    this.service.ShowError('No user found');
                    await this.spinner.hide();
                  } else if (
                    data[0].message ==
                    'User password cannot be reset in the current state.'
                  ) {
                    this.service.ShowError(
                      'You have not completed the onboarding flow. Please check email for temp password.',
                    );
                    await this.spinner.hide();
                  } else {
                    this.service.ShowError(data[0].message);
                    await this.spinner.hide();
                  }
                }
              });
          } else {
            this.service.ShowError('User not found');
            await this.spinner.hide();
          }
        } else {
          this.service.ShowError(data[0].message);
          await this.spinner.hide();
        }
      },
    );
    await this.spinner.hide();
  }
  async addActivityTracker(user_id, email, cohort_id, type, message) {
    await this.service.addActivityTracker(
      user_id,
      email,
      cohort_id,
      type,
      message,
    );
  }
}
