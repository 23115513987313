import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServicesService } from '../../../services.service';
import { AuthserviceService } from '../../../authservice.service';
import { TakesurveyService } from '../../../../app/services/takesurvey/takesurvey.service';

@Component({
  selector: 'app-prepareexam',
  templateUrl: './prepareexam.component.html',
  styleUrls: ['./prepareexam.component.scss'],
})
export class PrepareexamComponent implements OnInit {
  ListArray = [
    {
      ItemName: 'Flashcard App',
      StronglyDisagree: false,
      Disagree: false,
      Neutral: false,
      Agree: false,
      StronglyAgree: false,
      checked: false,
    },
    {
      ItemName: 'Practice Question App',
      StronglyDisagree: false,
      Disagree: false,
      Neutral: false,
      Agree: false,
      StronglyAgree: false,
      checked: false,
    },
    {
      ItemName: 'MindMaps',
      StronglyDisagree: false,
      Disagree: false,
      Neutral: false,
      Agree: false,
      StronglyAgree: false,
      checked: false,
    },
    {
      ItemName: 'Videos',
      StronglyDisagree: false,
      Disagree: false,
      Neutral: false,
      Agree: false,
      StronglyAgree: false,
      checked: false,
    },
    {
      ItemName: 'Written summaries',
      StronglyDisagree: false,
      Disagree: false,
      Neutral: false,
      Agree: false,
      StronglyAgree: false,
      checked: false,
    },
    {
      ItemName: 'Practice Exam',
      StronglyDisagree: false,
      Disagree: false,
      Neutral: false,
      Agree: false,
      StronglyAgree: false,
      checked: false,
    },
    {
      ItemName: 'Knowledge Assessment',
      StronglyDisagree: false,
      Disagree: false,
      Neutral: false,
      Agree: false,
      StronglyAgree: false,
      checked: false,
    },
    {
      ItemName: 'CAT Exam Simulator',
      StronglyDisagree: false,
      Disagree: false,
      Neutral: false,
      Agree: false,
      StronglyAgree: false,
      checked: false,
    },
    {
      ItemName: 'The Overall Class',
      StronglyDisagree: false,
      Disagree: false,
      Neutral: false,
      Agree: false,
      StronglyAgree: false,
      checked: false,
    },
  ];
  constructor(
    public TakesurveyService: TakesurveyService,
    public ActiveRoute: ActivatedRoute,
    public service: ServicesService,
    public auth: AuthserviceService,
    public spinner: NgxSpinnerService,
    public router: Router,
  ) {}

  ngOnInit(): void {
    this.CheckPrepareExamValue();
  }
  radioChange(item, count) {
    item.StronglyDisagree = false;
    item.Disagree = false;
    item.Neutral = false;
    item.Agree = false;
    item.StronglyAgree = false;
    item.checked = true;
    if (count == 1) {
      item.StronglyDisagree = true;
    } else if (count == 2) {
      item.Disagree = true;
    } else if (count == 3) {
      item.Neutral = true;
    } else if (count == 4) {
      item.Agree = true;
    } else {
      item.StronglyAgree = true;
    }
  }
  checkValidation() {
    let Exit = this.ListArray.filter((x) => x.checked);
    if (Exit.length == this.ListArray.length) {
      return false;
    } else {
      return true;
    }
  }
  async UpdatePrepareExamValue() {
    try {
      await this.spinner.show();
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            await this.spinner.show();
            var token = session.getIdToken().getJwtToken();
            var cohort_id = localStorage.getItem('cohort_id');
            var user_id = localStorage.getItem('CisspUserId');
            let data = {
              user_id: user_id,
              cohort_id: cohort_id,
              prepare_exam: JSON.stringify(this.ListArray),
            };
            await this.TakesurveyService.UpdateSurveyActivity(data, token).then(
              async (data) => {
                if (data[0].status == 200) {
                  var list = JSON.parse(<string>data[0].json);
                  await this.router.navigate(['/effectivepart']);
                }
                await this.spinner.hide();
              },
            );
          }
        });
    } catch (e) {
      await this.spinner.hide();
      this.service.ShowError(e.meassage);
    }
  }

  async CheckPrepareExamValue() {
    try {
      await this.spinner.show();
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            await this.spinner.show();
            var token = session.getIdToken().getJwtToken();
            var cohort_id = localStorage.getItem('cohort_id');
            var user_id = localStorage.getItem('CisspUserId');
            await this.TakesurveyService.GetSurveyActivity(
              user_id,
              cohort_id,
              token,
            ).then(async (data) => {
              if (data[0].status == 200) {
                var list = JSON.parse(<string>data[0].json);
                if (list.data.length > 0) {
                  if (list.data[0].prepare_exam != null) {
                    this.ListArray = JSON.parse(list.data[0].prepare_exam);
                  }
                }
              }
              await this.spinner.hide();
            });
          }
        });
    } catch (e) {
      await this.spinner.hide();
      this.service.ShowError(e.meassage);
    }
  }
}
