import { Component, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class ChatDiscordModalService {
  constructor(public dialog: MatDialog) {}

  async showModal() {
    const dialogRef = this.dialog.open(ChatDiscordModalDialog, {
      panelClass: 'chat-discord-container',
    });

    return dialogRef.afterClosed().toPromise();
  }

  closeModal() {}
}

@Component({
  selector: 'app-chat-discord-modal.dialog',
  templateUrl: 'chat-discord-modal.dialog.html',
  styleUrls: ['./chat-discord-modal.dialog.scss'],
})
export class ChatDiscordModalDialog {
  constructor(public dialogRef: MatDialogRef<ChatDiscordModalDialog>) {}

  openDiscordLink() {
    const discordLink = 'https://discord.gg/Uu8hCy7UhR';

    window.open(discordLink, '_blank');
  }

  closeButton() {
    this.dialogRef.close();
  }
}
