<div class="auth">
  <div class="login-form">
    <div class="logo">
      <img src="../../assets/cissplogo.png" alt="CISSP" />
    </div>
    <h3>
      <span>Enter your authentication code</span> Use your authenticator app to
      view your security code
    </h3>
    <div class="form" [formGroup]="AuthForm">
      <div class="input-form mb-20">
        <input
          type="password"
          name="auth"
          formControlName="authCode"
          [(ngModel)]="authCode"
          class="input"
          placeholder="Authentication code"
        />
        <span class="material-icons"> more_horiz </span>
      </div>
    </div>
    <button type="submit" class="submit" (click)="confirmCode()">Login</button>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
