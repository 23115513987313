<div class="survey-wrapper">
  <div class="video-wrapper">
    <div class="video-top">
      <img src="../../../assets/images/sidebar-logo.png" alt="Logo" />
      <h4>Welcome to <span>CISSP Destination Certification</span></h4>
    </div>
    <div class="video-div">
      <vg-player>
        <vg-controls>
          <vg-play-pause></vg-play-pause>
          <vg-time-display
            vgProperty="current"
            vgFormat="mm:ss"
          ></vg-time-display>
          <vg-scrub-bar readonly="disabled" disabled="disabled">
            <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
            <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
          </vg-scrub-bar>
          <vg-time-display vgProperty="left" vgFormat="mm:ss"></vg-time-display>
          <vg-playback-button></vg-playback-button>
          <vg-mute></vg-mute>
          <vg-volume></vg-volume>
          <vg-fullscreen></vg-fullscreen>
        </vg-controls>
        <video
          autoplay
          [vgMedia]="media"
          #media
          id="singleVideo"
          preload="auto"
        >
          <source
            src="https://d6mhjheehudv1.cloudfront.net/CISSPWelcomeready.mp4"
            type="video/mp4"
          />
        </video>
      </vg-player>
    </div>
  </div>
</div>
<div class="form-button-next">
  <button type="button" class="frm-prev-btn" style="opacity: 0">
    <span class="material-icons">arrow_back</span>
  </button>
  <button type="button" class="frm-next-btn" (click)="next()">Continue</button>
</div>
