import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServicesService } from '../services.service';
import { AuthserviceService } from '../authservice.service';
import { LoginflowService } from '../data/loginflow.service';

@Component({
  selector: 'app-showvideo',
  templateUrl: './showvideo.component.html',
  styleUrls: ['./showvideo.component.scss'],
})
export class ShowvideoComponent implements OnInit {
  data: any;
  constructor(
    public LoginflowService: LoginflowService,
    public ActiveRoute: ActivatedRoute,
    public router: Router,
    public spinner: NgxSpinnerService,
    public service: ServicesService,
    public auth: AuthserviceService,
  ) {
    this.ActiveRoute.params.subscribe(async (params) => {
      try {
        this.data = JSON.parse(atob(decodeURIComponent(params['data'])));
      } catch (error) {
        this.router.navigate(['/login']);
      }
    });
  }

  ngOnInit() {}
  async next() {
    var userdata = {
      email: this.data.email,
      password: this.data.password,
    };

    await this.LoginflowService.updateUserWelcomeFlow(
      this.data.email,
      '2',
    ).then((data) => {
      var email = btoa(JSON.stringify(userdata));
      email = encodeURIComponent(email);
      this.router.navigate([`/alias/${email}`]);
    });
  }
}
