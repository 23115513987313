<router-outlet></router-outlet>
<div class="reset-result" *ngIf="service.ErrorPopup">
  <div class="result-body">
    <div class="containt">
      <span class="material-icons-outlined">
        <img src="../assets/images/Error-icon.png" />
      </span>
      <h4>Uhh-Ohh!</h4>
      <p>
        {{ service.ErrorMessage }}
      </p>
      <div class="reset-btns">
        <button (click)="service.ErrorPopup = false">Ok</button>
      </div>
    </div>
  </div>
</div>
