import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Http, Headers, RequestOptions } from '@angular/http';
import { CryptoService } from '../crypto/crypto.service';

@Injectable({
  providedIn: 'root',
})
export class LivequeserviceService {
  baseurl = environment.baseurl;
  constructor(
    public http: Http,
    private crypto: CryptoService,
  ) {}
  async getUserByIdLiveQ(user_id) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          id: user_id,
        }),
      ),
    };
    return new Promise((resolve) => {
      var url = this.baseurl + environment.VERSIONS_GETUSER_BYID;
      this.http.post(url, data, {}).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve(err);
        },
      );
    });
  }
  async liveTestPostAnswer(lq_id, ans, user_id, lt_id) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          lq_id: lq_id,
          ans: ans,
          user_id: user_id,
          lt_id: lt_id,
        }),
      ),
    };

    return new Promise((resolve) => {
      var url = this.baseurl + environment.LIVEQ_ADDANS;
      this.http.post(url, data, {}).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve(err);
        },
      );
    });
  }
  GetAllLiveQuestionTestWise(id, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          id: id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + environment.LIVE_LIVEBYID;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  GetLiveQuestionTestWise(id) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          id: id,
        }),
      ),
    };
    return new Promise((resolve) => {
      var url = this.baseurl + environment.LIVEQ_LIVE_BYID;
      this.http.post(url, data, {}).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
}
