import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NoconnectionRoutingModule } from './noconnection-routing.module';
import { NoconnectionComponent } from './noconnection.component';

@NgModule({
  declarations: [NoconnectionComponent],
  imports: [CommonModule, NoconnectionRoutingModule],
  exports: [NoconnectionComponent],
})
export class NoconnectionModule {}
