import { Component, OnInit } from '@angular/core';
import { ConfirmedValidator } from '../../validation';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthserviceService } from '../../authservice.service';
import { environment } from 'src/environments/environment';
import { ServicesService } from '../../services.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { LoginflowService } from '../../data/loginflow.service';
import { Location } from '@angular/common';
@Component({
  selector: 'app-protectpasstemp',
  templateUrl: './protectpasstemp.component.html',
  styleUrls: ['./protectpasstemp.component.scss'],
})
export class ProtectpasstempComponent implements OnInit {
  public LoginForm: FormGroup;
  password = '';
  Confirmpass: any;
  PassLength: boolean = false;
  strangth = 0;
  IsSmall: boolean = false;
  IsUpper: boolean = false;
  Number: boolean = false;
  IsSpecial: boolean = false;
  PassWidth = 0;
  PassMsg = 'Poor';
  oldpassword: any;
  data: any;
  changepasstype = 'password';
  showCriteria: boolean = false;
  gotoNext: boolean = false;
  constructor(
    public ActiveRoute: ActivatedRoute,
    public fb: FormBuilder,
    public auth: AuthserviceService,
    public service: ServicesService,
    public spinner: NgxSpinnerService,
    public router: Router,
    public LoginflowService: LoginflowService,
    private location: Location,
  ) {
    this.ActiveRoute.params.subscribe(async (params) => {
      try {
        this.data = JSON.parse(atob(decodeURIComponent(params['data'])));
      } catch (error) {
        this.router.navigate(['/login']);
      }
    });
  }
  async ngOnInit() {
    this.spinner.hide();
    this.LoginForm = this.fb.group(
      {
        password: ['', Validators.required],
        Confirmpass: ['', Validators.required],
        oldpassword: ['', Validators.required],
      },
      {
        validator: ConfirmedValidator('password', 'Confirmpass'),
      },
    );
    await this.LoginflowService.versions_getUserByEmail(this.data.email).then(
      (data) => {
        if (data[0].status == 200) {
          var list = JSON.parse(<string>data[0].json);
          if (list.data[0].welcome_flow == 5) {
            this.gotoNext = true;
          } else {
            this.gotoNext = false;
          }
        }
      },
    );
  }
  get f() {
    return this.LoginForm.controls;
  }
  PasswordCheck() {
    if (this.password.length > 0) {
      this.showCriteria = true;
      this.PassWidth = 0;
      this.IsSmall = false;
      this.IsUpper = false;
      this.Number = false;
      this.IsSpecial = false;
      this.PassLength = false;
      this.PassMsg = 'Poor';
      var cnt = 0;
      if (/[a-z]/.test(this.password)) {
        this.IsSmall = true;
      }
      if (this.password.length >= 8) {
        this.PassLength = true;
      }

      if (/[A-Z]/.test(this.password)) {
        this.IsUpper = true;
      }

      if (/[0-9]/.test(this.password)) {
        this.Number = true;
      }
      if (/[!@#$%^&*()_+]/.test(this.password)) {
        this.IsSpecial = true;
      }

      if (this.PassLength) {
        this.PassWidth += 20;
      }
      if (this.IsSmall) {
        this.PassWidth += 20;
      }
      if (this.IsUpper) {
        this.PassWidth += 20;
      }
      if (this.Number) {
        this.PassWidth += 20;
      }
      if (this.IsSpecial) {
        this.PassWidth += 20;
      }

      if (this.PassWidth == 20 || this.PassWidth == 40) {
        this.PassMsg = 'Poor';
      }
      if (this.PassWidth == 60 || this.PassWidth == 80) {
        this.PassMsg = 'Good';
      }
      if (this.PassWidth == 100) {
        this.PassMsg = 'Strong';
      }
    } else {
      this.showCriteria = false;
    }
  }
  showpasschek(item) {
    this.changepasstype = item;
  }
  async resetpassword() {
    try {
      if (this.gotoNext) {
        var userdata = {
          email: this.data.email,
          password: this.password,
        };
        var datas = btoa(JSON.stringify(userdata));
        datas = encodeURIComponent(datas);
        this.router.navigate([`/onetimepsas/${datas}`]);
      } else {
        if (this.LoginForm.valid) {
          if (this.PassWidth == 100) {
            this.spinner.show();
            var email;
            var data = window.localStorage.getItem('userdetails');
            if (data && data != undefined && data != null && data != '') {
              var list = JSON.parse(<string>data);
              email = list.email;
            } else {
              email = this.data.email;
            }
            await this.LoginflowService.versions_getUserByEmail(email).then(
              async (data) => {
                if (data[0].status == 200) {
                  var list = JSON.parse(<string>data[0].json);
                  if (list && list.data[0] && list.data[0].is_temp == '1') {
                    await this.auth
                      .TempsignIn(email, this.oldpassword, this.password)
                      .then(async (data) => {
                        if (data[0].StatusCode == 1) {
                          await this.LoginflowService.UpdateTempemail(
                            email,
                            this.password,
                          ).then(async (dataset: any) => {
                            if (dataset[0].status == 200) {
                              this.spinner.hide();
                              var userdata = {
                                email: this.data.email,
                                password: this.password,
                              };
                              await this.LoginflowService.updateUserWelcomeFlow(
                                this.data.email,
                                '5',
                              ).then((data) => {
                                var datas = btoa(JSON.stringify(userdata));
                                datas = encodeURIComponent(datas);
                                this.router.navigate([`/onetimepsas/${datas}`]);
                              });
                            }
                          });
                        } else {
                          this.spinner.hide();
                          if (
                            data[0].message == 'Incorrect username or password.'
                          ) {
                            this.service.ShowError(
                              'Enter valid Activation Code',
                            );
                          } else {
                            this.service.ShowError(data[0].message);
                          }
                        }
                      });
                  } else {
                    this.spinner.hide();
                    this.location.forward();
                  }
                }
              },
            );
          }
        }
      }
    } catch {
      this.spinner.hide();
    }
  }
}
