<div *ngIf="!IsLinkExpored">
  <div class="survey-wrapper">
    <div class="video-wrapper">
      <div class="video-top">
        <img src="../../../assets/images/sidebar-logo.png" alt="Logo" />
        <h4>
          Welcome to <span> {{ CohortName }}</span>
        </h4>
      </div>
      <div class="video-div">
        <div [hidden]="!isVimeoVideo" id="vimeoplayer"></div>
        <button
          type="button"
          class="frm-next-btn"
          (click)="next()"
          *ngIf="videoEnded"
        >
          Click here to Proceed
        </button>
      </div>
    </div>
  </div>
  <div class="form-button-next">
    <button type="button" class="frm-prev-btn" style="opacity: 0">
      <span class="material-icons">arrow_back</span>
    </button>
    <button type="button" class="frm-next-btn-down" (click)="next()">
      <span class="material-icons"> arrow_forward </span>
    </button>
  </div>
</div>
<div *ngIf="IsLinkExpored" class="expiredlink">
  <div class="message">
    <h1>Uhh-oh, your account activation link has expired</h1>
    <p>
      If you have already set up your account,<a href="https://my.destcert.com/"
        >click here to login</a
      >
    </p>
    <p>
      If you need help with your account, please contact us at
      <a href="mailto:support@destcert.com">support&#64;destcert.com</a>
    </p>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
