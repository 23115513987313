import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Http, Headers, RequestOptions } from '@angular/http';
import { CryptoService } from '../services/crypto/crypto.service';
@Injectable({
  providedIn: 'root',
})
export class StudyguideService {
  constructor(
    public http: Http,
    private crypto: CryptoService,
  ) {}
  async userstudyguide(u_id, cid, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          u_id: u_id,
          cource_id: cid,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.baseurl + 'knowassque/userstudyguide';
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  getReviewGuidePercentage(user_id, certi_id, cohort_id, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          user_id: user_id,
          certi_id: certi_id,
          cohort_id: cohort_id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);

      let options = new RequestOptions({ headers: headers });
      var url = environment.baseurl + environment.REVIEW_GUIDE_PERCENTAGE;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async userstudyguidelist(
    u_id,
    cid,
    cohort_id,
    method_id,
    method_type,
    material_type,
    token,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          u_id: u_id,
          cource_id: cid,
          cohort_id: cohort_id,
          method_id: method_id,
          method_type: method_type,
          material_type: material_type,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.baseurl + environment.GETREVIEWGUIDE;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  UserstudyDomain(cource_id, u_id, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          u_id: u_id,
          cource_id: cource_id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.baseurl + 'userlogs/studydomain';
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async addStudyFeedback(user_id, material_id, helpful, feedback, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          user_id: user_id,
          material_id: material_id,
          helpful: helpful,
          feedback: feedback,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.baseurl + 'points/studyMatFeedback';
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async flashcardView(user_id, cohort_id, topic_id, card_type, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          user_id: user_id,
          cohort_id: cohort_id,
          topic_id: topic_id,
          card_type: card_type,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.baseurl + 'testlogs/flashcard_view';
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async discussreviewguide(discussreview, token) {
    var data = {
      data: this.crypto.encrypt(JSON.stringify(discussreview)),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.baseurl + environment.REVIEW_GUIDE;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
}
