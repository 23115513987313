import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthserviceService } from '../authservice.service';
import { LoginflowService } from '../data/loginflow.service';
import { ServicesService } from '../services.service';

@Component({
  selector: 'app-recoveryemailsuccess',
  templateUrl: './recoveryemailsuccess.component.html',
  styleUrls: ['./recoveryemailsuccess.component.scss'],
})
export class RecoveryemailsuccessComponent implements OnInit {
  public AuthForm: FormGroup;
  email: any;
  constructor(
    public service: ServicesService,
    public auth: AuthserviceService,
    public spinner: NgxSpinnerService,
    public fb: FormBuilder,
    public router: Router,
    public ActiveRoute: ActivatedRoute,
    public LoginflowService: LoginflowService,
  ) {}

  ngOnInit() {
    this.AuthForm = this.fb.group({
      email: ['', Validators.required],
    });
  }

  async forgotPassword() {
    let pattern = new RegExp(
      '^[_A-Za-z0-9-]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9-]+)*((\\.[A-Za-z]{2,4})$)',
    );
    if (pattern.test(this.email)) {
      this.spinner.show();
      await this.LoginflowService.versions_getUserByEmail(this.email).then(
        async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            if (list.data.length > 0) {
              this.email = list.data[0].email;
              await this.auth.forgotPassword(this.email).then(async (data) => {
                this.spinner.hide();
                if (data[0].StatusCode == 1) {
                  let user = this.email;
                  let msg = data[0].message;
                  let cohort = 'Reset Password';
                  let email_type = 'Reset Password';
                  await this.addFailedEmail(user, cohort, msg, email_type, 1);
                  this.service.ShowError(data[0].message);
                  this.service.ShowSuccess(data[0].message);
                  var datas = btoa(JSON.stringify(this.email));
                  datas = encodeURIComponent(datas);
                  this.router.navigate([`/resetpassauth/${datas}`]);
                } else {
                  if (
                    data[0].message ==
                    'Username/client id combination not found.'
                  ) {
                    let user = this.email;
                    let msg = 'No user found';
                    let cohort = 'Reset Password';
                    let email_type = 'Reset Password';
                    await this.addFailedEmail(user, cohort, msg, email_type, 0);
                    this.service.ShowError('No user found');
                  } else if (
                    data[0].message ==
                    'User password cannot be reset in the current state.'
                  ) {
                    let user = this.email;
                    let msg =
                      'You have not completed the onboarding flow. Please check email for temp password.';
                    let cohort = 'Reset Password';
                    let email_type = 'Reset Password';
                    await this.addFailedEmail(user, cohort, msg, email_type, 0);
                    this.service.ShowError(
                      'You have not completed the onboarding flow. Please check email for temp password.',
                    );
                  } else {
                    let user = this.email;
                    let msg = data[0].message;
                    let cohort = 'Reset Password failed';
                    let email_type = 'Reset Password';
                    await this.addFailedEmail(user, cohort, msg, email_type, 0);
                    this.service.ShowError(data[0].message);
                  }
                }
              });
            } else {
              this.service.ShowError('User not found');
              await this.spinner.hide();
            }
          } else {
            this.service.ShowError(data[0].message);
            await this.spinner.hide();
          }
        },
      );
    } else {
      this.service.ShowError('email provided is invalid');
    }
  }

  async addFailedEmail(user, cohort, msg, email_type, mail_success) {
    await this.service
      .addFailedEmail(user, cohort, msg, email_type, mail_success, '')
      .then(async (data) => {});
  }
}
