<div class="logo-div">
  <img src="../../../../assets/images/sidebar-logo.png" alt="" />
</div>
<div class="servey-center step-3">
  <h4>Did the following tools help you prepare for the exam?</h4>
  <ul class="pre-exam">
    <li class="th"></li>
    <li class="th">Strongly Disagree</li>
    <li class="th">Disagree</li>
    <li class="th">Neutral</li>
    <li class="th">Agree</li>
    <li class="th">Strongly Agree</li>
  </ul>
  <div class="option-wrapper" *ngFor="let item of ListArray; let i = index">
    <div class="ow-bg">
      <span>{{ item.ItemName }}</span>
    </div>
    <div class="feedback">
      <div class="ow">
        <input
          type="radio"
          (change)="radioChange(item, 1)"
          name="FlashcardButton{{ i }}"
          [checked]="item.StronglyDisagree"
        />
        <label
          ><span [ngClass]="{ active: item.StronglyDisagree }"
            >Strongly Disagree</span
          ></label
        >
      </div>
      <div class="ow">
        <input
          type="radio"
          (change)="radioChange(item, 2)"
          name="FlashcardButton{{ i }}"
          [checked]="item.Disagree"
        />
        <label
          ><span [ngClass]="{ active: item.Disagree }" class="none"
            >Disagree</span
          ></label
        >
      </div>
      <div class="ow">
        <input
          type="radio"
          (change)="radioChange(item, 3)"
          name="FlashcardButton{{ i }}"
          [checked]="item.Neutral"
        />
        <label
          ><span [ngClass]="{ active: item.Neutral }" class="none"
            >Neutral</span
          ></label
        >
      </div>
      <div class="ow">
        <input
          type="radio"
          (change)="radioChange(item, 4)"
          name="FlashcardButton{{ i }}"
          [checked]="item.Agree"
        />
        <label
          ><span [ngClass]="{ active: item.Agree }" class="none"
            >Agree</span
          ></label
        >
      </div>
      <div class="ow">
        <input
          type="radio"
          (change)="radioChange(item, 5)"
          name="FlashcardButton{{ i }}"
          [checked]="item.StronglyAgree"
        />
        <label
          ><span [ngClass]="{ active: item.StronglyAgree }"
            >Strongly Agree</span
          ></label
        >
      </div>
    </div>
  </div>
</div>

<div class="form-button-next">
  <button type="button" class="frm-prev-btn">
    <span class="material-icons" [routerLink]="['/aboutclass']"
      >arrow_back</span
    >
  </button>
  <button
    type="button"
    class="frm-next-btn"
    (click)="UpdatePrepareExamValue()"
    [disabled]="checkValidation()"
  >
    <span class="material-icons"> arrow_forward </span>
  </button>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
