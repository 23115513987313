<div class="logo-div">
  <img src="../../assets/images/sidebar-logo.png" alt="Logo" />
</div>
<div class="verification-step">
  <h4>
    Further protect your account with something you have, by setting up a
    <span>One-Time Password</span> authenticator app
  </h4>
  <form>
    <div class="verify-method">
      <div class="verify-left very">
        <span>1</span>
        <p>If you don’t already have an authenticator app, we recommend</p>
        <ul>
          <li disabled="true">
            <a href="https://authy.com/download/" target="_blank">Authy</a>
          </li>
          <li disabled="true">
            <a
              href="https://support.google.com/accounts/answer/1066447?co=GENIE.Platform%3DAndroid&hl=en&oco=0"
              target="_blank"
              >Google Authenticator</a
            >
          </li>
          <li disabled="true">
            <a
              href="https://www.microsoft.com/en-us/account/authenticator"
              target="_blank"
              >Microsoft Authenticator</a
            >
          </li>
        </ul>
      </div>
      <div class="verify-center very">
        <span>2</span>
        <p>
          Please scan the following QR code with your authenticator app or enter
          your authenticator app.
          <span class="text-color">{{ devicecode }} </span>
        </p>
        <ngx-qrcode
          [elementType]="elementType"
          [value]="value"
          cssClass="qrcodeas"
          errorCorrectionLevel="L"
          *ngIf="!authyPasswordEnbled"
        >
        </ngx-qrcode>
        <div class="form-button-next-generate" *ngIf="authyPasswordEnbled">
          <button
            type="button "
            class="frm-next-btn-generate"
            (click)="GeneratedQR()"
          >
            Setup Code
          </button>
        </div>
      </div>
      <div class="verify-right very">
        <span>3</span>
        <p>
          Please enter the 6-digit one-time password that has been generated by
          your app
        </p>
        <div class="input-flex">
          <input
            type="password "
            name="veripass "
            placeholder="Enter code"
            [(ngModel)]="code"
            class="verify-password"
          />
          <svg
            width="27"
            height="7"
            viewBox="0 0 27 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="3.5"
              cy="3.5"
              r="2.85"
              stroke="#7066AB"
              stroke-width="1.3"
            />
            <circle
              cx="13.5"
              cy="3.5"
              r="2.85"
              stroke="#7066AB"
              stroke-width="1.3"
            />
            <circle
              cx="23.5"
              cy="3.5"
              r="2.85"
              stroke="#7066AB"
              stroke-width="1.3"
            />
          </svg>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="form-button-next">
  <button type="button " class="frm-next-btn" (click)="thankyou()">
    <span class="material-icons"> arrow_forward </span>
  </button>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
