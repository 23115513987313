import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ServicesService } from '../../../services.service';
import { AuthserviceService } from '../../../authservice.service';
import { TakesurveyService } from '../../../../app/services/takesurvey/takesurvey.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-additionalfeedback',
  templateUrl: './additionalfeedback.component.html',
  styleUrls: ['./additionalfeedback.component.scss'],
})
export class AdditionalfeedbackComponent implements OnInit {
  InputValue = '';

  constructor(
    public TakesurveyService: TakesurveyService,
    public ActiveRoute: ActivatedRoute,
    public service: ServicesService,
    public auth: AuthserviceService,
    public router: Router,
    public spinner: NgxSpinnerService,
  ) {}

  ngOnInit(): void {
    this.CheckFeedbackValue();
  }

  async UpdateFeedbackValue() {
    if (this.InputValue) {
      try {
        await this.spinner.show();
        await this.auth
          .getAuthenticatedUser()
          .getSession(async (err, session) => {
            if (err) {
              await this.spinner.hide();
              this.router.navigate(['/login']);
            } else {
              await this.spinner.show();
              var token = session.getIdToken().getJwtToken();
              var cohort_id = localStorage.getItem('cohort_id');
              var user_id = localStorage.getItem('CisspUserId');
              let data = {
                user_id: user_id,
                cohort_id: cohort_id,
                additional_feedback: this.InputValue,
              };
              await this.TakesurveyService.UpdateSurveyActivity(
                data,
                token,
              ).then(async (data) => {
                if (data[0].status == 200) {
                  var list = JSON.parse(<string>data[0].json);
                  await this.router.navigate(['/feedbackjohn']);
                }
                await this.spinner.hide();
              });
            }
          });
      } catch (e) {
        await this.spinner.hide();
        this.service.ShowError(e.meassage);
      }
    }
  }

  async CheckFeedbackValue() {
    try {
      await this.spinner.show();
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            await this.spinner.show();
            var token = session.getIdToken().getJwtToken();
            var cohort_id = localStorage.getItem('cohort_id');
            var user_id = localStorage.getItem('CisspUserId');
            await this.TakesurveyService.GetSurveyActivity(
              user_id,
              cohort_id,
              token,
            ).then(async (data) => {
              if (data[0].status == 200) {
                var list = JSON.parse(<string>data[0].json);
                this.InputValue = list.data[0].additional_feedback;
              }
              await this.spinner.hide();
            });
          }
        });
    } catch (e) {
      await this.spinner.hide();
      this.service.ShowError(e.meassage);
    }
  }
}
