<div class="img-center">
  <img src="../../../../assets/images/sidebar-logo.png" alt="" />
</div>
<div class="servey-center step-2 step-5">
  <h4>What are other training classes you are interested in?</h4>
  <ul class="review-item-wrapper">
    <li class="review-item" *ngFor="let item of Certificatelist">
      <input
        type="checkbox"
        name="TrainingClassButton"
        (change)="changechk(item)"
        [checked]="item.checked"
      />
      <label
        ><span>{{ item.certificate_name }}</span></label
      >
    </li>
  </ul>
</div>
<div class="form-button-next">
  <button type="button" class="frm-prev-btn" [routerLink]="['/feedbackjohn']">
    <span class="material-icons">arrow_back</span>
  </button>
  <button
    type="button"
    class="frm-next-btn"
    (click)="UpdateClassInterestValue()"
    [disabled]="checkValidation()"
  >
    <span class="material-icons"> arrow_forward </span>
  </button>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
