<div *ngIf="service.status == 'ONLINE'">
  <div class="form-button-next">
    <div class="logo-div">
      <img src="../../../assets/images/sidebar-logo.png" alt="Logo" />
    </div>
    <div class="main-body">
      <label>Select Your Time Zone</label>
      <ng-moment-timezone-picker
        customPlaceholderText="Time Zone"
        [(ngModel)]="time_zone"
        clearOnBackspace="true"
      >
      </ng-moment-timezone-picker>
    </div>
    <div class="next-btn">
      <button
        type="button"
        class="frm-prev-btn"
        (click)="alisnamechange()"
        [disabled]="!time_zone"
      >
        <span class="material-icons"> arrow_forward </span>
      </button>
    </div>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
<div class="no-internet" *ngIf="service.status == 'OFFLINE'">
  <img [src]="service.srcData" alt="" />
  <h2>Uh-Oh!</h2>
  <h3>Slow or no internet Connection.</h3>
  <h6>Please check your internet settings and try again</h6>
  <button (click)="retry()">Retry</button>
</div>
