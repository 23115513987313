<div class="img-center">
  <img src="../../../../assets/images/sidebar-logo.png" alt="" />
</div>
<div class="rob">
  <div class="servey-center step-4">
    <h4>Do you have any feedback for Rob as an instructor?</h4>
    <textarea placeholder="Your Answer" [(ngModel)]="RobInputValue"></textarea>
  </div>
  <div class="form-button-next">
    <button type="button" class="frm-prev-btn" [routerLink]="['/feedbackjohn']">
      <span class="material-icons">arrow_back</span>
    </button>
    <button
      type="button"
      class="frm-next-btn2"
      (click)="UpdateRobFeedBackValue()"
      [disabled]="RobInputValue == ''"
    >
      <span class="material-icons"> arrow_forward </span>
    </button>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
