import { Component, OnInit } from '@angular/core';
import { ServicesService } from '../../services.service';

import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthserviceService } from '../../authservice.service';
import { LoginflowService } from '../../data/loginflow.service';

@Component({
  selector: 'app-studentimage',
  templateUrl: './studentimage.component.html',
  styleUrls: ['./studentimage.component.scss'],
})
export class StudentimageComponent implements OnInit {
  UserSkinColor = 'Default';
  UserHairSkinColor = 'Default';
  UserEyes = 'Default';
  Usereyebrows = 'Default';
  UserMouth = 'Default';
  facialhair = 'Blank';
  Usercloth = 'BlazerShirt';
  clothColor = 'Default';
  clothlogo = 'Bat';
  topselect = 'LongHairStraight';
  transparent = 'Circle';

  data: any;
  name: any;
  hatcolor = '';
  userid: any;
  imageurl: any;
  imageshow = true;
  urlAvatar: any;
  glass = 'Default';
  tranferbutton: boolean = true;

  UserSkinColorArray = [
    'Tanned',
    'Yellow',
    'Pale',
    'Light',
    'Brown',
    'DarkBrown',
    'Black',
  ];
  UserHairSkinColorArray = [
    'Auburn',
    'Black',
    'Blonde',
    'Brown',
    'BrownDark',
    'PastelPink',
    'Platinum',
    'Red',
    'SilverGray',
  ];
  topselectArray = [
    'NoHair',
    'LongHairBigHair',
    'LongHairBob',
    'LongHairBun',
    'LongHairCurly',
    'LongHairDreads',
    'LongHairFrida',
    'LongHairFro',
    'LongHairFroBand',
    'LongHairNotTooLong',
    'LongHairMiaWallace',
    'LongHairShavedSides',
    'LongHairCurvy',
    'LongHairStraight',
    'LongHairStraight2',
    'LongHairNotTooLong',
    'ShortHairDreads01',
    'ShortHairDreads02',
    'ShortHairFrizzle',
    'ShortHairShaggyMullet',
    'ShortHairShortCurly',
    'ShortHairShortFlat',
    'ShortHairShortRound',
    'ShortHairShortWaved',
    'ShortHairSides',
    'ShortHairTheCaesar',
    'ShortHairTheCaesarSidePart',
  ];
  UserEyesArray = [
    'Close',
    'Cry',
    'Default',
    'EyeRoll',
    'Happy',
    'Hearts',
    'Side',
    'Squint',
    'Surprised',
    'WinkWacky',
    'Wink',
    'Dizzy',
    '',
  ];
  UsereyebrowsArray = [
    'FlatNatural',
    'DefaultNatural',
    'SadConcerned',
    'Default',
    'SadConcernedNatural',
    'RaisedExcitedNatural',
    'Angry',
    'AngryNatural',
    'RaisedExcited',
    'UnibrowNatural',
    'UpDown',
  ];
  UserMouthArray = [
    'Concerned',
    'Default',
    'Disbelief',
    'Eating',
    'Grimace',
    'Sad',
    'ScreamOpen',
    'Serious',
    'Smile',
    'Tongue',
    'Twinkle',
    'Vomit',
  ];
  clothColorArray = [
    'Black',
    'Blue01',
    'Blue02',
    'Blue03',
    'Gray01',
    'Gray02',
    'Heather',
    'PastelBlue',
    'PastelGreen',
    'PastelOrange',
    'PastelRed',
    'PastelYellow',
    'Pink',
    'Red',
    'White',
  ];
  UserclothArray = [
    'BlazerShirt',
    'BlazerSweater',
    'CollarSweater',
    'GraphicShirt',
    'Hoodie',
    'Overall',
    'ShirtCrewNeck',
    'ShirtScoopNeck',
    'ShirtVNeck',
  ];
  clothlogoArray = [
    'Bat',
    'Bear',
    'Cumbia',
    'Dear',
    'Diamond',
    'Hola',
    'Pizza',
    'Resist',
    'Selena',
    'SkullOutline',
  ];
  dataset: any;
  constructor(
    public LoginflowService: LoginflowService,
    public ActiveRoute: ActivatedRoute,
    public auth: AuthserviceService,
    public service: ServicesService,
    public spinner: NgxSpinnerService,
    public router: Router,
  ) {
    this.ActiveRoute.params.subscribe(async (params) => {
      try {
        this.dataset = JSON.parse(atob(decodeURIComponent(params['data'])));
      } catch (error) {
        this.router.navigate(['/login']);
      }
    });
  }

  ngOnInit() {
    this.spinner.hide();

    var data = window.localStorage.getItem('userdetails');
    if (data != undefined || data != null || data != '') {
      this.data = JSON.parse(<string>data);
      this.name = this.data.aliasname ? this.data.aliasname : this.data.name;
      this.urlAvatar = this.data.avatar_url
        ? this.data.avatar_url
        : 'https://avataaars.io/?avatarStyle=Circle&topType=LongHairStraight&accessoriesType=Default&hairColor=Default&facialHairType=Blank&clotheType=BlazerShirt&clotheColor=Default&graphicType=Bat&eyeType=Default&eyebrowType=Default&mouthType=Default&skinColor=Pale&hatColor=';
    }

    const urlParams = new URLSearchParams(this.urlAvatar);
    this.UserSkinColor = urlParams.get('skinColor');
    this.UserHairSkinColor = urlParams.get('hairColor');
    this.UserEyes = urlParams.get('eyeType');
    this.Usereyebrows = urlParams.get('eyebrowType');
    this.UserMouth = urlParams.get('mouthType');
    this.facialhair = urlParams.get('facialHairType');
    this.Usercloth = urlParams.get('clotheType');
    this.transparent = 'Circle';
    this.clothColor = urlParams.get('clotheColor');
    this.clothlogo = urlParams.get('graphicType');
    this.topselect = urlParams.get('topType');
    this.glass = urlParams.get('accessoriesType');
    this.hatcolor = urlParams.get('hatColor');
  }
  skinClick(item) {
    this.imageshow = false;
    this.UserSkinColor = item;
    this.ChnageAvatrarUrl();
  }
  hairclick(item) {
    this.imageshow = false;
    this.UserHairSkinColor = item;
    this.ChnageAvatrarUrl();
  }
  eyeClick(item) {
    this.imageshow = false;
    this.UserEyes = item;
    this.ChnageAvatrarUrl();
  }
  eyebrows(item) {
    this.imageshow = false;
    this.Usereyebrows = item;
    this.ChnageAvatrarUrl();
  }
  mounthclick(item) {
    this.imageshow = false;
    this.UserMouth = item;
    this.ChnageAvatrarUrl();
  }
  facialhairclick(item) {
    this.imageshow = false;
    this.facialhair = item;
    this.ChnageAvatrarUrl();
  }
  ClothcolorClick(item) {
    this.imageshow = false;
    this.clothColor = item;
    this.ChnageAvatrarUrl();
  }
  ClothClick(item) {
    this.imageshow = false;
    this.Usercloth = item;
    this.ChnageAvatrarUrl();
  }
  Clothlogo(item) {
    this.imageshow = false;
    this.clothlogo = item;
    this.ChnageAvatrarUrl();
  }
  Topclick(item) {
    this.imageshow = false;
    this.topselect = item;
    this.glass = '';
    this.ChnageAvatrarUrl();
  }
  Topclicks(item) {
    this.imageshow = false;
    this.glass = item;
    this.ChnageAvatrarUrl();
  }
  tranferbg(item) {
    this.imageshow = false;
    this.tranferbutton = false;
    this.UserSkinColor =
      this.UserSkinColorArray[
        Math.floor(Math.random() * this.UserSkinColorArray.length)
      ];
    this.UserHairSkinColor =
      this.UserHairSkinColorArray[
        Math.floor(Math.random() * this.UserHairSkinColorArray.length)
      ];
    this.topselect =
      this.topselectArray[
        Math.floor(Math.random() * this.topselectArray.length)
      ];
    this.UserEyes =
      this.UserEyesArray[Math.floor(Math.random() * this.UserEyesArray.length)];
    this.Usereyebrows =
      this.UsereyebrowsArray[
        Math.floor(Math.random() * this.UsereyebrowsArray.length)
      ];
    this.UserMouth =
      this.UserMouthArray[
        Math.floor(Math.random() * this.UserMouthArray.length)
      ];
    this.clothColor =
      this.clothColorArray[
        Math.floor(Math.random() * this.clothColorArray.length)
      ];
    this.Usercloth =
      this.UserclothArray[
        Math.floor(Math.random() * this.UserclothArray.length)
      ];
    this.clothlogo =
      this.clothlogoArray[
        Math.floor(Math.random() * this.clothlogoArray.length)
      ];
    this.ChnageAvatrarUrl();
  }
  tranferbgelse(item) {
    this.imageshow = false;
    this.transparent = item;
    this.tranferbutton = true;
  }
  uploadimage() {
    const url = new URL(this.urlAvatar);
    if (url.hostname != 'avataaars.io') {
      return this.service.ShowError('Unauthorized Image Url');
    }
    this.spinner.show();
    this.userid = localStorage.getItem('CisspUserId');
    var data = this.urlAvatar;
    this.LoginflowService.ProfileUpdated(data, this.userid).then(
      async (dataset: any) => {
        if (dataset[0].status == 200) {
          var list = JSON.parse(<string>dataset[0].json);
          localStorage.setItem(
            'userdetails',
            JSON.stringify(<string>list.data),
          );
          this.spinner.hide();
          var userdata = {
            email: this.dataset.email,
            password: this.dataset.password,
            cohort_id: this.dataset.cohort_id,
          };
          var datas = btoa(JSON.stringify(userdata));
          datas = encodeURIComponent(datas);
          await this.LoginflowService.updateUserWelcomeFlow(
            this.data.email,
            '7',
            this.dataset.cohort_id,
          ).then(async (data) => {
            var datas = btoa(JSON.stringify(userdata));
            datas = encodeURIComponent(datas);
            await this.router.navigate([`/selfpacedvideo/${datas}`]);
          });
        } else {
          this.spinner.hide();
        }
      },
    );
  }
  Userhatcolor(item) {
    this.imageshow = false;
    this.hatcolor = item;
    this.ChnageAvatrarUrl();
  }
  ChnageAvatrarUrl() {
    this.urlAvatar =
      'https://avataaars.io/?avatarStyle=' +
      this.transparent +
      '&topType=' +
      this.topselect +
      '&accessoriesType=' +
      this.glass +
      '&hairColor=' +
      this.UserHairSkinColor +
      '&facialHairType=' +
      this.facialhair +
      '&clotheType=' +
      this.Usercloth +
      '&clotheColor=' +
      this.clothColor +
      '&graphicType=' +
      this.clothlogo +
      '&eyeType=' +
      this.UserEyes +
      '&eyebrowType=' +
      this.Usereyebrows +
      '&mouthType=' +
      this.UserMouth +
      '&skinColor=' +
      this.UserSkinColor +
      '&hatColor=' +
      this.hatcolor;
  }
}
