import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthserviceService } from 'src/app/authservice.service';
import { ServicesService } from 'src/app/services.service';
import { ScheduleserviceService } from 'src/app/services/Schedule/scheduleservice.service';
import { LoginflowService } from '../../data/loginflow.service';
@Component({
  selector: 'app-customschedule',
  templateUrl: './customschedule.component.html',
  styleUrls: ['./customschedule.component.scss'],
})
export class CustomscheduleComponent implements OnInit {
  sun: number = 0;
  mon: number = 0;
  tues: number = 0;
  wed: number = 0;
  thurs: number = 0;
  fri: number = 0;
  sat: number = 0;
  hsun: number = 0;
  hmon: number = 0;
  htues: number = 0;
  hwed: number = 0;
  hthurs: number = 0;
  hfri: number = 0;
  hsat: number = 0;
  balance: number = 0;
  sprint: number = 0;
  insane: number = 0;
  exam_date: any;
  study_date: any = new Date();
  data: any;
  self_schedule_id: any;
  IsSelfSchedule: boolean = true;
  Users = [];
  ExpiryDate: Date;
  HeaderStartDate: Date;
  ShowInvalidDate: boolean = false;
  misMatchSchedule: boolean = false;
  SelfCheckBox: boolean = false;
  custome: number = 0;

  constructor(
    public LoginflowService: LoginflowService,
    public router: Router,
    public ActiveRoute: ActivatedRoute,
    public ScheduleserviceService: ScheduleserviceService,
    public auth: AuthserviceService,
    public service: ServicesService,
    public spinner: NgxSpinnerService,
  ) {
    this.ActiveRoute.params.subscribe(async (params) => {
      try {
        this.data = JSON.parse(atob(decodeURIComponent(params['data'])));
        await this.versions_getUserById(this.data.user_id);
        await this.getExpiryDate();
      } catch (error) {}
    });
  }

  ngOnInit(): void {}
  selectDay(day) {
    day == 'sun'
      ? (this.sun = this.sun == 1 ? 0 : 1)
      : day == 'mon'
        ? (this.mon = this.mon == 1 ? 0 : 1)
        : day == 'tues'
          ? (this.tues = this.tues == 1 ? 0 : 1)
          : day == 'wed'
            ? (this.wed = this.wed == 1 ? 0 : 1)
            : day == 'thurs'
              ? (this.thurs = this.thurs == 1 ? 0 : 1)
              : day == 'fri'
                ? (this.fri = this.fri == 1 ? 0 : 1)
                : day == 'sat'
                  ? (this.sat = this.sat == 1 ? 0 : 1)
                  : '';
    day == 'sun'
      ? (this.hsun = this.sun == 1 ? this.hsun : 0)
      : day == 'mon'
        ? (this.hmon = this.mon == 1 ? this.hmon : 0)
        : day == 'tues'
          ? (this.htues = this.tues == 1 ? this.htues : 0)
          : day == 'wed'
            ? (this.hwed = this.wed == 1 ? this.hwed : 0)
            : day == 'thurs'
              ? (this.hthurs = this.thurs == 1 ? this.hthurs : 0)
              : day == 'fri'
                ? (this.hfri = this.fri == 1 ? this.hfri : 0)
                : day == 'sat'
                  ? (this.hsat = this.sat == 1 ? this.hsat : 0)
                  : '';
  }

  presetSelected(val) {
    this.sun = 1;
    this.mon = 1;
    this.tues = 1;
    this.wed = 1;
    this.thurs = 1;
    this.fri = 1;
    this.sat = 1;
    this.hsun = val;
    this.hmon = val;
    this.htues = val;
    this.hwed = val;
    this.hthurs = val;
    this.hfri = val;
    this.hsat = val;
  }
  presetUnselected(val) {
    this.sun = 0;
    this.mon = 0;
    this.tues = 0;
    this.wed = 0;
    this.thurs = 0;
    this.fri = 0;
    this.sat = 0;
    this.hsun = val;
    this.hmon = val;
    this.htues = val;
    this.hwed = val;
    this.hthurs = val;
    this.hfri = val;
    this.hsat = val;
  }
  SelectCustome(custome) {
    custome = 'custome' ? (this.custome = this.custome == 1 ? 0 : 1) : '';
  }
  validateNumber(e, item) {
    var idx = e.target.value.indexOf('.');
    if (idx >= 0) {
    } else if (e.target.value > 24) {
      e.target.value = 24;
      return false;
    } else if (e.target.value <= 0) {
      e.target.value = 0;
      if (item == 'sun') {
        this.sun = 0;
      } else if (item == 'mon') {
        this.mon = 0;
      } else if (item == 'tues') {
        this.tues = 0;
      } else if (item == 'wed') {
        this.wed = 0;
      } else if (item == 'thurs') {
        this.thurs = 0;
      } else if (item == 'fri') {
        this.fri = 0;
      } else {
        this.sat = 0;
      }
      return false;
    }
    return true;
  }

  customScheduleClick() {
    this.insane = 0;
    this.sprint = 0;
    this.balance = 0;
  }

  selectOption(option) {
    this.SelfCheckBox = false;
    if (option == 'balance') {
      if (this.balance == 1) {
        this.balance = 1;
        this.presetUnselected(0);
      } else {
        this.balance = 1;
        this.sprint = 0;
        this.insane = 0;
      }
    } else if (option == 'sprint') {
      if (this.sprint == 1) {
        this.sprint = 1;
        this.presetUnselected(0);
      } else {
        this.balance = 0;
        this.sprint = 1;
        this.insane = 0;
      }
    } else {
      if (this.insane == 1) {
        this.insane = 1;
        this.presetUnselected(0);
      } else {
        this.balance = 0;
        this.sprint = 0;
        this.insane = 1;
      }
    }
  }

  selectDate() {
    if (this.study_date > this.exam_date) {
      this.ShowInvalidDate = true;
      this.misMatchSchedule = true;
    } else {
      this.misMatchSchedule = false;
      this.ShowInvalidDate = false;
    }
  }

  async versions_getUserById(user_id) {
    await this.spinner.show();
    await this.LoginflowService.versions_getUserByEmail(this.data.email).then(
      (data) => {
        var list = JSON.parse(<string>data[0].json);
        this.Users = list.data;
      },
    );
    await this.spinner.hide();
  }
  async generateSchedule() {
    if (this.balance == 1) {
      this.study_date = new Date(new Date().setDate(new Date().getDate() + 1));
      this.exam_date = new Date(new Date().setDate(new Date().getDate() + 57));
      this.presetSelected(2);
    } else if (this.sprint == 1) {
      this.study_date = new Date(new Date().setDate(new Date().getDate() + 1));
      this.exam_date = new Date(new Date().setDate(new Date().getDate() + 29));
      this.presetSelected(4);
    } else if (this.insane == 1) {
      this.study_date = new Date(new Date().setDate(new Date().getDate() + 1));
      this.exam_date = new Date(new Date().setDate(new Date().getDate() + 11));
      this.presetSelected(14);
    }
    var userdata = {
      email: this.data.email,
      password: this.data.password,
      cohort_id: this.data.cohort_id
        ? this.data.cohort_id
        : localStorage.getItem('cohort_id'),
    };
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      var token = session.getIdToken().getJwtToken();
      var userId = this.Users[0].id;
      var cohort_id = this.data.cohort_id
        ? this.data.cohort_id
        : localStorage.getItem('cohort_id');
      var cer_id = localStorage.getItem('CisspCourseId');
      this.spinner.show();
      await this.ScheduleserviceService.generateSelfSchedule(
        this.self_schedule_id,
        this.IsSelfSchedule,
        parseInt(userId),
        parseInt(cer_id),
        this.exam_date,
        this.sun,
        this.mon,
        this.tues,
        this.wed,
        this.thurs,
        this.fri,
        this.sat,
        this.hsun,
        this.hmon,
        this.htues,
        this.hwed,
        this.hthurs,
        this.hfri,
        this.hsat,
        this.balance,
        this.sprint,
        this.insane,
        cohort_id,
        this.study_date,
        token,
      ).then(async (data) => {
        if (data[0].status == 200) {
          await this.spinner.hide();
        }
      });

      await this.LoginflowService.updateUserWelcomeFlow(
        userdata.email,
        '9',
        this.data.cohort_id
          ? this.data.cohort_id
          : localStorage.getItem('cohort_id'),
      ).then(async (data) => {
        var datas = btoa(JSON.stringify(userdata));
        datas = encodeURIComponent(datas);
        this.router.navigate([`/competition/${datas}`]);
      });
    });
  }
  async SkipSchedule() {
    var userdata = {
      email: this.data.email,
      password: this.data.password,
      cohort_id: this.data.cohort_id
        ? this.data.cohort_id
        : localStorage.getItem('cohort_id'),
    };
    this.spinner.show();
    await this.LoginflowService.updateUserWelcomeFlow(
      userdata.email,
      '9',
      this.data.cohort_id
        ? this.data.cohort_id
        : localStorage.getItem('cohort_id'),
    ).then(async (data) => {
      this.spinner.hide();
      var datas = btoa(JSON.stringify(userdata));
      datas = encodeURIComponent(datas);
      this.router.navigate([`/competition/${datas}`]);
    });
  }
  async getExpiryDate() {
    this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var cohort_id = this.data.cohort_id
          ? this.data.cohort_id
          : localStorage.getItem('cohort_id');
        var userId = this.Users[0].id;
        var data = {
          user_id: userId,
          cohort_id: cohort_id,
        };
        await this.LoginflowService.get_expirydate_onboarding(data).then(
          (data) => {
            if (data[0].status == 200) {
              var list = JSON.parse(<string>data[0].json);
              if (list.data.length > 0) {
                this.ExpiryDate = list.data[0].expiry_date;
                this.HeaderStartDate = list.data[0].createdAt;
              }
              this.spinner.hide();
            }
          },
        );
      }
    });
    this.spinner.hide();
  }
}
