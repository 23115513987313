import { Component, OnInit } from '@angular/core';
import { ServicesService } from '../../services.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginflowService } from '../../data/loginflow.service';

@Component({
  selector: 'app-competition',
  templateUrl: './competition.component.html',
  styleUrls: ['./competition.component.scss'],
})
export class CompetitionComponent implements OnInit {
  data: any;
  constructor(
    public LoginflowService: LoginflowService,
    private router: Router,
    private service: ServicesService,
    private spinner: NgxSpinnerService,
    public ActiveRoute: ActivatedRoute,
  ) {
    this.ActiveRoute.params.subscribe(async (params) => {
      try {
        this.data = JSON.parse(atob(decodeURIComponent(params['data'])));
      } catch (error) {
        this.router.navigate(['/login']);
      }
      var datas = localStorage.getItem('userdetails');
      if (datas == null || datas == undefined) {
        this.router.navigate(['/login']);
      }
      this.spinner.hide();
    });
  }

  ngOnInit() {}
  async UpdateOptIn(opt_in) {
    this.spinner.show();
    let user_id = localStorage.getItem('CisspUserId');
    await this.update_onboarding_flow(opt_in);
    await this.LoginflowService.UpdateUserOptIn(opt_in, user_id).then(
      async (data) => {
        if (data[0].status == 200) {
          var datas = localStorage.getItem('userdetails');

          if (datas != undefined && datas != null && datas != '' && datas) {
            var set = JSON.parse(<string>datas);
            await this.LoginflowService.updateUserWelcomeFlow(
              set.email,
              '7',
              this.data.cohort_id,
            );
          }

          this.spinner.hide();
          window.localStorage.setItem('logintrueStudent', 'true');

          var userdata = {
            email: this.data.email,
            password: this.data.password,
            cohort_id: this.data.cohort_id,
          };
          var email = btoa(JSON.stringify(userdata));
          email = encodeURIComponent(email);
          await this.LoginflowService.updateUserWelcomeFlow(
            this.data.email,
            '10',
            this.data.cohort_id,
          ).then(async (data) => {
            this.router.navigate([`/learning/${email}`]);
          });
        } else {
          this.spinner.hide();
        }
      },
    );
  }
  async update_onboarding_flow(opt_in) {
    let user_id = localStorage.getItem('CisspUserId');
    var onboardigdata = {
      user_id: user_id,
      cohort_id: this.data.cohort_id,
      opt_in: opt_in,
    };

    await this.LoginflowService.update_onboarding_flow(onboardigdata).then(
      (onboardingFlowRes) => {
        if (onboardingFlowRes[0].status == 200) {
          var list = JSON.parse(<string>onboardingFlowRes[0].json);
        } else {
          this.router.navigate(['/login']);
        }
      },
    );
  }
}
