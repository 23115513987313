<div>
  <div class="servey-top">
    <div class="logo-image">
      <img src="../../../assets/images/sidebar-logo.png" alt="Image" />
    </div>
    <h2 class="servey-title">
      Destination Certification <span>{{ cohort_name }}</span> Survey
    </h2>
  </div>
  <div class="servey-center">
    <h4>Did you pass the exam?</h4>
    <ul class="review-item-wrapper">
      <li class="review-item" *ngFor="let item of InputArray">
        <input
          type="radio"
          (change)="radioChange(item)"
          name="FriendCollagueButton"
          [checked]="item.checked"
        />
        <label
          ><span>{{ item.type }}</span></label
        >
      </li>
    </ul>
  </div>
</div>
<div class="form-button-next">
  <button
    type="button"
    class="frm-next-btn"
    (click)="UpdatePassExamValue()"
    [disabled]="checkValidation()"
  >
    <span class="material-icons"> arrow_forward </span>
  </button>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
