<div *ngIf="service.status == 'ONLINE'">
  <div class="starttest" *ngIf="!TestStart">
    <button (click)="startTest()">START</button>
  </div>
  <div class="live-ques" *ngIf="TestStart">
    <div *ngFor="let item of SingleQue">
      <div class="live-ques-top">
        <div class="live-ques-logo">
          <img src="../../../assets/images/sidebar-logo.png" alt="Logo" />
        </div>
        <div class="live-ques-per">
          <h2>{{ item.QuestionNo }}# <span id="timer"></span></h2>
        </div>
        <div class="question-btns">
          <button (click)="showAnswer()">{{ AnswerButton }}</button>
          <button
            (click)="nextQuestion()"
            *ngIf="QuestionKey < Questions.length - 1"
          >
            Next
          </button>
          <button
            *ngIf="QuestionKey >= Questions.length - 1"
            (click)="finishTest()"
          >
            Finish
          </button>
        </div>
      </div>
      <div class="live-ques-bottom">
        <div class="que">
          <h2>{{ item.question }}</h2>
        </div>
        <div class="question-option">
          <ul>
            <li
              [ngClass]="{ 'question-correct': item.answer == 1 && ShowAnswer }"
            >
              <span>A</span>
              <p>{{ item.first_choice }}</p>
            </li>
            <li
              [ngClass]="{ 'question-correct': item.answer == 2 && ShowAnswer }"
            >
              <span>B</span>
              <p>{{ item.second_choice }}</p>
            </li>
            <li
              [ngClass]="{ 'question-correct': item.answer == 3 && ShowAnswer }"
            >
              <span>C</span>
              <p>{{ item.third_choice }}</p>
            </li>
            <li
              [ngClass]="{ 'question-correct': item.answer == 4 && ShowAnswer }"
            >
              <span>D</span>
              <p>{{ item.fourth_choice }}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>

<app-noconnection></app-noconnection>
