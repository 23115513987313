import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServicesService } from '../../services.service';
import { AuthserviceService } from '../../authservice.service';
import { ActivatedRoute } from '@angular/router';
import { LoginflowService } from '../../data/loginflow.service';
import { CourseserviceService } from 'src/app/services/course/courseservice.service';

@Component({
  selector: 'app-tnakyou',
  templateUrl: './tnakyou.component.html',
  styleUrls: ['./tnakyou.component.scss'],
})
export class TnakyouComponent implements OnInit {
  data: any;
  list1: any;
  courselist = [];
  cohortId: any;
  cohortName: any;
  buttondisabled = false;
  constructor(
    public ActiveRoute: ActivatedRoute,
    public service: ServicesService,
    public auth: AuthserviceService,
    public spinner: NgxSpinnerService,
    public fb: FormBuilder,
    public router: Router,
    public LoginflowService: LoginflowService,
    public CourseserviceService: CourseserviceService,
  ) {
    this.ActiveRoute.params.subscribe(async (params) => {
      try {
        this.data = JSON.parse(atob(decodeURIComponent(params['data'])));
        this.cohortId = this.data?.cohort_id;
        await this.service
          .get_welcome_links(this.cohortId)
          .then(async (data) => {
            var list = JSON.parse(<string>data[0].json);
            var cohortlist = list?.data?.[0];
            this.cohortName = cohortlist?.[0]?.cohort_name;
          });
      } catch (error) {}
    });
  }

  ngOnInit(): void {
    this.spinner.hide();
  }
  async Continue() {
    this.buttondisabled = true;
    await this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        this.spinner.hide();
        this.buttondisabled = false;
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.LoginflowService.UsersFilter(this.data.email, token).then(
          async (user) => {
            if (user[0].status == 200) {
              var list = JSON.parse(<string>user[0].json);
              await this.auth.ResetMFASetup(list.data.email, '');
              localStorage.setItem('CisspUserId', JSON.stringify(list.data.id));
              localStorage.setItem('userdetails', JSON.stringify(list.data));
              window.localStorage.setItem('logintrueStudent', 'true');
              this.LoginflowService.UpdateTempemail(
                this.data.email,
                this.data.password,
              );
              await this.update_onboarding_flow();
              await this.LoginflowService.updateUserWelcomeFlow(
                this.data.email,
                '0',
                this.data.cohort_id,
              ).then(async (data) => {
                var list = JSON.parse(<string>data[0].json);
                window.localStorage.removeItem('preventBack');
                this.LoginflowService.loginlog(list.data.id, '', '', '');
                var list = JSON.parse(<string>data[0].json);
                this.list1 = list;
                if (list.data.course_id == null) {
                  await this.spinner.hide();

                  this.router.navigate(['enrolledcourse']);
                } else {
                  if (list.data.show_resources == 1) {
                    this.service.instructionHint = false;
                  } else {
                    this.service.instructionHint = true;
                  }
                  await this.listCoursesNew(list.data.id);
                }
              });
            } else {
              this.buttondisabled = false;
            }
          },
        );
      }
    });
    await this.spinner.hide();
  }

  async listCoursesNew(userid) {
    this.courselist = [];
    try {
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();
            await this.CourseserviceService.knowassquecource(
              userid,
              token,
            ).then(async (data) => {
              if (data[0].status == 200) {
                var list = JSON.parse(<string>data[0].json);
                this.courselist = list.data;
                let courselistNew = [];
                this.courselist.forEach((element) => {
                  if (element.cohort_id == this.list1.data.course_id) {
                    courselistNew.push(element);
                  }
                });
                await this.CertificationClickNew(courselistNew[0]);
                await this.spinner.hide();
              } else if (data[0].status == 502) {
                this.listCoursesNew(userid);
                await this.spinner.hide();
              } else {
                await this.spinner.hide();
              }
            });
          }
        });
    } catch (e) {
      await this.spinner.hide();
      this.service.ShowError(e.meassage);
    }
  }
  async CertificationClickNew(item) {
    this.service.courseid = item.certi_id;
    await this.getResult(item.certi_id, item.cohort_id);
    localStorage.setItem('CisspCourseId', item.certi_id);
    localStorage.setItem('cohort_id', item.cohort_id);
    localStorage.setItem('overall', item.percentage);
    localStorage.setItem('method', item.method_id);
    localStorage.setItem(
      'course_type',
      item.course_type ? item.course_type : '',
    );
    window.localStorage.setItem('accessitem', JSON.stringify(item.access));
    await this.spinner.hide();
    await this.router.navigate(['sidenav/dashboard']);
  }

  async getResult(cid, cohort_id) {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        let user_id = localStorage.getItem('CisspUserId');
        this.spinner.show();
        await this.CourseserviceService.getDashboardPercentage(
          user_id,
          cid,
          cohort_id,
          token,
        ).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            let domainsData = list.data;

            let sum = 0;
            let i = 0;
            let w = [9, 11, 12, 12, 12, 12, 12, 12];
            domainsData.forEach((element) => {
              element.weight = w[i];
              i++;
              sum += element.totalPro;
            });
            localStorage.setItem('domainsData', JSON.stringify(domainsData));
            this.spinner.hide();
          } else {
            this.spinner.hide();
          }
        });
      }
    });
  }
  async update_onboarding_flow() {
    let user_id = localStorage.getItem('CisspUserId');
    var onboardigdata = {
      user_id: user_id,
      cohort_id: this.data.cohort_id,
      completed_on: new Date(),
    };
    await this.LoginflowService.update_onboarding_flow(onboardigdata).then(
      (onboardingFlowRes) => {
        if (onboardingFlowRes[0].status == 200) {
          var list = JSON.parse(<string>onboardingFlowRes[0].json);
        } else {
          this.router.navigate(['/login']);
        }
      },
    );
  }
}
