import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-surveythankyou',
  templateUrl: './surveythankyou.component.html',
  styleUrls: ['./surveythankyou.component.scss'],
})
export class SurveythankyouComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
