import { Injectable } from '@angular/core';
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
  CognitoUserAttribute,
} from 'amazon-cognito-identity-js';
var AmazonCognitoIdentity = require('amazon-cognito-identity-js');
import { environment } from 'src/environments/environment';
import { CognitoIdentityServiceProvider, config } from 'aws-sdk';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { ServicesService } from './services.service';

import { LoginflowService } from './data/loginflow.service';
import * as AWS from 'aws-sdk';
import { TZone } from 'moment-timezone-picker';
import * as momentZone from 'moment-timezone';
import { CryptoService } from './services/crypto/crypto.service';

const SES = new AWS.SES();
var s3 = new AWS.S3();
let poolData = {
  UserPoolId: 'us-east-2_h8VcL2Tg6',
  ClientId: '61u543sdf10u7kpq7ntfqf82rt',
};

let userPool = new CognitoUserPool(poolData);

@Injectable({
  providedIn: 'root',
})
export class AuthserviceService {
  cognitoUser: any;
  constructor(
    public LoginflowService: LoginflowService,
    public service: ServicesService,
    public spinner: NgxSpinnerService,
    public router: Router,
    private crypto: CryptoService,
  ) {
    config.update({
      secretAccessKey: this.crypto.dec(environment.ACCESS_KEY),
      accessKeyId: this.crypto.dec(environment.KEY_ID),
      region: 'us-east-2',
      correctClockSkew: true,
    });
    poolData = {
      UserPoolId: this.crypto.dec(environment.UserPoolId),
      ClientId: this.crypto.dec(environment.ClientId),
    };

    userPool = new CognitoUserPool(poolData);
  }

  async signIn(email, password) {
    var userdata = {
      email: email,
      password: password,
    };
    const authenticationData = {
      Username: email,
      Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    const userData = {
      Username: email,
      Pool: userPool,
    };
    const cognitoUser = new CognitoUser(userData);
    let me = this;
    return new Promise((resolve) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {
          me.LoginflowService.versions_getUserByEmail(email).then((data) => {
            if (data[0].status == 200) {
              var list = JSON.parse(<string>data[0].json);
              var userdatacohort = {
                email: email,
                password: password,
                cohort_id: list.data[0].onboard_cohort,
              };
              var params = btoa(JSON.stringify(userdatacohort));
              params = encodeURIComponent(params);
              var passwordsession = btoa(JSON.stringify(password));
              window.localStorage.setItem('domaindata', passwordsession);
              localStorage.setItem(
                'CisspUserId',
                JSON.stringify(list.data[0].id),
              );
              localStorage.setItem('userdetails', JSON.stringify(list.data[0]));
              if (list.data[0].welcome_flow == 2) {
                window.localStorage.setItem('preventBack', `/alias/${params}`);
                me.router.navigate([`/alias/${params}`]);
              } else if (list.data[0].welcome_flow == 3) {
                window.localStorage.setItem(
                  'preventBack',
                  `/timezone/${params}`,
                );
                me.router.navigate([`/protectpass/${params}`]);
              } else if (list.data[0].welcome_flow == 4) {
                window.localStorage.setItem(
                  'preventBack',
                  `/protectpass/${params}`,
                );
                me.router.navigate([`/protectpass/${params}`]);
              } else if (list.data[0].welcome_flow == 5) {
                window.localStorage.setItem(
                  'preventBack',
                  `/onetimepsas/${params}`,
                );
                me.router.navigate([`/onetimepsas/${params}`]);
              } else if (list.data[0].welcome_flow == 6) {
                window.localStorage.setItem(
                  'preventBack',
                  `/userimage/${params}`,
                );
                me.router.navigate([`/userimage/${params}`]);
              } else if (list.data[0].welcome_flow == 7) {
                window.localStorage.setItem(
                  'preventBack',
                  `/selfpacedvideo/${params}`,
                );
                me.router.navigate([`/selfpacedvideo/${params}`]);
              } else if (list.data[0].welcome_flow == 8) {
                window.localStorage.setItem(
                  'preventBack',
                  `/customschedule/${params}`,
                );
                me.router.navigate([`/customschedule/${params}`]);
              } else if (list.data[0].welcome_flow == 9) {
                window.localStorage.setItem(
                  'preventBack',
                  `/competition/${params}`,
                );
                me.router.navigate([`/competition/${params}`]);
              } else if (list.data[0].welcome_flow == 10) {
                window.localStorage.setItem(
                  'preventBack',
                  `/learning/${params}`,
                );
                me.router.navigate([`/learning/${params}`]);
              } else if (list.data[0].welcome_flow == 11) {
                window.localStorage.setItem(
                  'preventBack',
                  `/thankyou/${params}`,
                );
                me.router.navigate([`/thankyou/${params}`]);
              }
            }
          });
          resolve([
            { message: 'Login Successful', StatusCode: 1, Data: result },
          ]);
        },
        onFailure: function (err) {
          resolve([{ message: err.message, StatusCode: 0, Data: '' }]);
        },
        totpRequired: async (totpRequir, test) => {
          var userdata = {
            email: email,
            password: password,
          };
          await me.ResetMFASetup(email, password).then(async (dataset) => {
            cognitoUser.authenticateUser(authenticationDetails, {
              onSuccess: function (result) {
                resolve([
                  { message: 'Login Successful', StatusCode: 1, Data: result },
                ]);
              },
              onFailure: function (err) {
                resolve([{ message: err.message, StatusCode: 0, Data: '' }]);
              },
            });
          });
        },
        newPasswordRequired: (userAttributes) => {
          this.spinner.hide();
          this.LoginflowService.versions_getUserByEmail(email).then((data) => {
            if (data[0].status == 200) {
              var list = JSON.parse(<string>data[0].json);
              var userdatacohort = {
                email: email,
                password: password,
                cohort_id: list.data[0].onboard_cohort,
              };
              var param = btoa(JSON.stringify(userdatacohort));
              param = encodeURIComponent(param);
              var passwordsession = btoa(JSON.stringify(password));
              window.localStorage.setItem('domaindata', passwordsession);
              localStorage.setItem(
                'CisspUserId',
                JSON.stringify(list.data[0].id),
              );
              localStorage.setItem('userdetails', JSON.stringify(list.data[0]));
              this.spinner.hide();
              if (list.data[0].welcome_flow == 2) {
                window.localStorage.setItem('preventBack', `/alias/${param}`);
                this.router.navigate([`/alias/${param}`]);
              } else if (list.data[0].welcome_flow == 3) {
                window.localStorage.setItem(
                  'preventBack',
                  `/timezone/${param}`,
                );
                this.router.navigate([`/protectpass/${param}`]);
              } else if (list.data[0].welcome_flow == 4) {
                window.localStorage.setItem(
                  'preventBack',
                  `/protectpass/${param}`,
                );
                this.router.navigate([`/protectpass/${param}`]);
              } else if (list.data[0].welcome_flow == 5) {
                window.localStorage.setItem(
                  'preventBack',
                  `/onetimepsas/${param}`,
                );
                this.router.navigate([`/onetimepsas/${param}`]);
              } else if (list.data[0].welcome_flow == 6) {
                window.localStorage.setItem(
                  'preventBack',
                  `/userimage/${param}`,
                );
                this.router.navigate([`/userimage/${param}`]);
              } else if (list.data[0].welcome_flow == 7) {
                window.localStorage.setItem(
                  'preventBack',
                  `/selfpacedvideo/${param}`,
                );
                this.router.navigate([`/selfpacedvideo/${param}`]);
              } else if (list.data[0].welcome_flow == 8) {
                window.localStorage.setItem(
                  'preventBack',
                  `/customschedule/${param}`,
                );
                this.router.navigate([`/customschedule/${param}`]);
              } else if (list.data[0].welcome_flow == 9) {
                window.localStorage.setItem(
                  'preventBack',
                  `/competition/${param}`,
                );
                this.router.navigate([`/competition/${param}`]);
              } else if (list.data[0].welcome_flow == 10) {
                window.localStorage.setItem(
                  'preventBack',
                  `/learning/${param}`,
                );
                this.router.navigate([`/learning/${param}`]);
              } else if (list.data[0].welcome_flow == 11) {
                window.localStorage.setItem(
                  'preventBack',
                  `/thankyou/${param}`,
                );
                this.router.navigate([`/thankyou/${param}`]);
              }
            }
          });
        },
      });
    });
  }
  ResendPasswordTemp(email) {
    const attributeList = [];
    attributeList.push(
      new CognitoUserAttribute({
        Name: 'email',
        Value: email,
      }),
    );
    attributeList.push(
      new CognitoUserAttribute({
        Name: 'email_verified',
        Value: 'true',
      }),
    );
    const user = {
      Username: email,
      UserPoolId: userPool.getUserPoolId(),
      UserAttributes: attributeList,
      MessageAction: 'RESEND',
    };
    const cognito = new CognitoIdentityServiceProvider();
    return new Promise((resolve, reject) => {
      cognito.adminCreateUser(user, (err, data) => {
        if (err) {
          resolve([{ message: err.message, StatusCode: 0, Data: err }]);
        } else {
          resolve([
            {
              message: 'Student Add successfully !',
              StatusCode: 1,
              Data: data,
            },
          ]);
        }
      });
    });
  }
  getuser(email) {
    const user = {
      Username: email,
      UserPoolId: userPool.getUserPoolId(),
    };
    const cognito = new CognitoIdentityServiceProvider();
    return new Promise((resolve, reject) => {
      cognito.adminGetUser(user, (err, data) => {
        if (err) {
          resolve([{ message: err.message, StatusCode: 0, Data: err }]);
        } else {
          resolve([
            {
              message: 'Student Add successfully !',
              StatusCode: 1,
              Data: data,
            },
          ]);
        }
      });
    });
  }
  async TempsignIn(email, password, Newpassword) {
    const authenticationData = {
      Username: email,
      Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    const userData = {
      Username: email,
      Pool: userPool,
    };
    const cognitoUser = new CognitoUser(userData);
    return new Promise((resolve) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {
          resolve([
            { message: 'Login Successful', StatusCode: 1, Data: result },
          ]);
        },
        onFailure: function (err) {
          resolve([{ message: err.message, StatusCode: 0, Data: '' }]);
        },
        newPasswordRequired: (userAttributes) => {
          delete userAttributes.email_verified;
          cognitoUser.completeNewPasswordChallenge(
            Newpassword,
            userAttributes,
            {
              onSuccess: function (result) {
                resolve([
                  {
                    message: 'Change password successfully!',
                    StatusCode: 1,
                    Data: result,
                  },
                ]);
              },
              onFailure: function (err) {
                resolve([{ message: err.message, StatusCode: 0, Data: '' }]);
              },
            },
          );
        },
      });
    });
  }
  async TempsignInprotect(email, password, Newpassword) {
    const authenticationData = {
      Username: email,
      Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    const userData = {
      Username: email,
      Pool: userPool,
    };
    const cognitoUser = new CognitoUser(userData);
    return new Promise((resolve) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {
          resolve([
            { message: 'Login Successful', StatusCode: 1, Data: result },
          ]);
        },
        onFailure: function (err) {
          resolve([{ message: err.message, StatusCode: 0, Data: '' }]);
        },
        newPasswordRequired: (userAttributes) => {
          delete userAttributes.email_verified;
          cognitoUser.completeNewPasswordChallenge(
            Newpassword,
            userAttributes,
            {
              onSuccess: function (result) {
                resolve([
                  {
                    message: 'Change password successfully!',
                    StatusCode: 2,
                    Data: result,
                  },
                ]);
              },
              onFailure: function (err) {
                resolve([{ message: err.message, StatusCode: 0, Data: '' }]);
              },
            },
          );
        },
      });
    });
  }
  async TempsignInprotecttes(email, password, Newpassword) {
    const authenticationData = {
      Username: email,
      Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    const userData = {
      Username: email,
      Pool: userPool,
    };
    const cognitoUser = new CognitoUser(userData);
    return new Promise((resolve) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {
          resolve([
            { message: 'Login Successful', StatusCode: 1, Data: result },
          ]);
        },
        onFailure: function (err) {
          resolve([{ message: err.message, StatusCode: 0, Data: '' }]);
        },
        newPasswordRequired: (userAttributes) => {
          delete userAttributes.email_verified;
          cognitoUser.completeNewPasswordChallenge(
            Newpassword,
            userAttributes,
            {
              onSuccess: function (result) {
                resolve([
                  {
                    message: 'Change password successfully!',
                    StatusCode: 1,
                    Data: result,
                  },
                ]);
              },
              onFailure: function (err) {
                resolve([{ message: err.message, StatusCode: 0, Data: '' }]);
              },
            },
          );
        },
      });
    });
  }
  register(email, password) {
    const attributeList = [];
    attributeList.push(
      new CognitoUserAttribute({
        Name: 'email',
        Value: email,
      }),
    );
    return new Promise((resolve) => {
      userPool.signUp(email, password, attributeList, null, (err, result) => {
        if (err) {
          resolve([{ message: err.message, StatusCode: 0, Data: '' }]);
        } else {
          resolve([
            {
              message: 'Register Successfully',
              StatusCode: 1,
              Data: result.user,
            },
          ]);
        }
      });
    });
  }
  confirmAuthCode(code, email) {
    const user = {
      Username: email,
      Pool: userPool,
    };
    return new Promise((resolve) => {
      const cognitoUser = new CognitoUser(user);
      cognitoUser.confirmRegistration(code, true, function (err, result) {
        if (err) {
          resolve([{ message: err.message, StatusCode: 0, Data: '' }]);
        } else {
          resolve([
            {
              message: 'Authentication Successfully Please Login',
              StatusCode: 1,
              Data: result,
            },
          ]);
        }
      });
    });
  }
  async forgotPassword(email) {
    const user = {
      Username: email,
      Pool: userPool,
    };
    return new Promise((resolve) => {
      const cognitoUser = new CognitoUser(user);
      cognitoUser.forgotPassword({
        onSuccess: function (result) {
          resolve([
            {
              message: 'Please check your email for Authentication code.',
              StatusCode: 1,
              Data: result,
            },
          ]);
        },
        onFailure: function (err) {
          resolve([{ message: err.message, StatusCode: 0, Data: '' }]);
        },
      });
    });
  }
  forgotPasswordAuthcode(email, code, password) {
    const user = {
      Username: email,
      Pool: userPool,
    };
    return new Promise((resolve) => {
      const cognitoUser = new CognitoUser(user);
      cognitoUser.confirmPassword(code, password, {
        onSuccess: function () {
          resolve([
            { message: 'Password changed successfully.', StatusCode: 1 },
          ]);
        },
        onFailure: function (err) {
          resolve([{ message: err.message, StatusCode: 0 }]);
        },
      });
    });
  }
  resendConfirmationCode(email) {
    const user = {
      Username: email,
      Pool: userPool,
    };
    return new Promise((resolve) => {
      const cognitoUser = new CognitoUser(user);
      cognitoUser.forgotPassword({
        onSuccess: function (result) {
          resolve([
            {
              message: 'Resend code successfully.',
              StatusCode: 1,
              Data: result,
            },
          ]);
        },
        onFailure: function (err) {
          resolve([{ message: err.message, StatusCode: 0, Data: '' }]);
        },
      });
    });
  }
  ResetPassword(email, oldpass, newpass) {
    const user = {
      Username: email,
      Pool: userPool,
    };
    return new Promise((resolve) => {
      var authenticationDetail = new AuthenticationDetails({
        Username: email,
        Password: oldpass,
      });
      const cognitoUser = new CognitoUser(user);
      cognitoUser.authenticateUser(authenticationDetail, {
        onSuccess: function (result) {
          cognitoUser.changePassword(oldpass, newpass, (err, result) => {
            if (err) {
              resolve([{ message: err.message, StatusCode: 0 }]);
            } else {
              resolve([
                { message: 'Password changed successfully!', StatusCode: 1 },
              ]);
            }
          });
        },
        onFailure: function (err) {
          resolve([{ message: err.message, StatusCode: 0 }]);
        },
      });
    });
  }
  isLoggedIn() {
    var login = window.localStorage.getItem('logintrueStudent');
    if (!login || login == 'false') {
      return false;
    } else {
      return true;
    }
  }
  isLoggedIncheck() {
    return userPool.getCurrentUser() != null;
  }
  redirectlogin() {
    return new Promise((resolve) => {
      resolve(userPool.getCurrentUser());
    });
  }
  getAuthenticatedUser() {
    try {
      userPool.getCurrentUser().getUsername();
      return userPool.getCurrentUser();
    } catch (error) {
      localStorage.clear();
      sessionStorage.clear();
      window.localStorage.clear();
      this.router.navigate(['/login']);
    }
  }

  async logOut() {
    try {
      userPool.getCurrentUser().signOut();
    } catch (error) {
      window.localStorage.clear();
      this.router.navigate(['/login']);
    }
    window.localStorage.clear();
  }
  generateTempPassword() {
    var specials = '!@#$%^&*()_+{}:"<>?|[];\',./`~';
    var lowercase = 'abcdefghijklmnopqrstuvwxyz';
    var uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var numbers = '0123456789';
    var password = '';
    for (let id = 0; id < 2; id++) {
      password += uppercase[Math.floor(Math.random() * uppercase.length)];
      password += specials[Math.floor(Math.random() * specials.length)];
      password += numbers[Math.floor(Math.random() * numbers.length)];
      password += lowercase[Math.floor(Math.random() * lowercase.length)];
    }
    return password;
  }
  async sendmail(data) {
    const params = {
      Destination: {
        ToAddresses: [data.to],
      },
      Message: {
        Body: {
          Html: {
            Charset: 'UTF-8',
            Data: data.text,
          },
        },
        Subject: { Charset: 'UTF-8', Data: data.subject },
      },
      Source: 'Destination Certification <system@destcert.com>',
    };

    const SES1 = new AWS.SES({ region: 'us-east-1' });
    return new Promise(async (resolve, reject) => {
      await SES1.sendEmail(params, (err, data) => {
        if (err) {
          resolve([{ message: err.message, StatusCode: 0 }]);
        } else {
          resolve([{ message: 'Mail Send Successfully', StatusCode: 1 }]);
        }
      });
    });
  }
  myGet(email) {
    const getUser = {
      Username: email,
      UserPoolId: userPool.getUserPoolId(),
    };
    const cognito = new CognitoIdentityServiceProvider();

    cognito.adminGetUser(getUser, (err, data) => {});
  }
  async CreateUser(email) {
    const attributeList = [];
    attributeList.push(
      new CognitoUserAttribute({
        Name: 'email',
        Value: email,
      }),
    );
    attributeList.push(
      new CognitoUserAttribute({
        Name: 'email_verified',
        Value: 'true',
      }),
    );

    const user = {
      Username: email,
      UserPoolId: userPool.getUserPoolId(),
      UserAttributes: attributeList,
    };
    const cognito = new CognitoIdentityServiceProvider();
    const getUser = {
      Username: email,
      UserPoolId: userPool.getUserPoolId(),
    };
    return new Promise(async (resolve, reject) => {
      await this.verifyDKIM(email).then(async (result) => {
        if (result[0].StatusCode == 1) {
          cognito.adminGetUser(getUser, (err, data) => {
            if (data) {
              user['MessageAction'] = 'RESEND';
            }
            cognito.adminCreateUser(user, (err, data) => {
              if (err) {
                resolve([{ message: err.message, StatusCode: 0, Data: err }]);
              } else {
                resolve([
                  {
                    message: 'Student Add successfully !',
                    StatusCode: 1,
                    Data: data,
                  },
                ]);
              }
            });
          });
        } else {
          this.service.ShowError(result[0].message + '111');
          resolve([{ message: result[0].message, StatusCode: 0, Data: '' }]);
        }
      });
    });
  }
  verifyDKIM(email) {
    const SES1 = new AWS.SES({ region: 'us-east-1' });
    let domain = email.split('@')[1];
    let verify = {
      Domain: domain,
    };
    return new Promise((resolve, reject) => {
      SES1.verifyDomainDkim(verify, (err, data) => {
        if (err) {
          resolve([{ message: err.message, StatusCode: 0 }]);
        } else {
          resolve([{ message: 'DKIM verification successful', StatusCode: 1 }]);
        }
      });
    });
  }
  verify(email, password, code, dataset) {
    return new Promise(async (resolve) => {
      var authenticationData = {
        Username: email,
        Password: password,
      };
      var authenticationDetails =
        new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);
      var userData = {
        Username: email,
        Pool: userPool,
      };
      var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
      await cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {
          var accessToken = result.getAccessToken().getJwtToken();
          cognitoUser.verifySoftwareToken(code, 'My TOTP device', {
            onSuccess: function (success) {
              resolve([{ message: success, StatusCode: 1 }]);
            },
            onFailure: function (errcode) {
              resolve([{ message: errcode.message, StatusCode: 0 }]);
            },
          });
        },
        onFailure: function (err) {
          resolve([{ message: err.message, StatusCode: 0 }]);
        },
        totpRequired: (totpRequir, test) => {
          cognitoUser.sendMFACode(
            code,
            {
              onFailure: function (err: { message: any }) {
                resolve([{ message: err.message, StatusCode: 0, Data: '' }]);
              },
              onSuccess: function (res) {
                resolve([{ message: 'res.message', StatusCode: 1, Data: '' }]);
              },
            },
            'SOFTWARE_TOKEN_MFA',
          );
        },
      });
    });
  }
  async MFASetup(email, password) {
    const cognito = new AWS.CognitoIdentityServiceProvider();
    return new Promise(async (resolve) => {
      var aaa = await cognito.adminInitiateAuth(
        {
          AuthFlow: 'ADMIN_NO_SRP_AUTH',
          ClientId: this.crypto.dec(environment.ClientId),
          UserPoolId: this.crypto.dec(environment.UserPoolId),
          AuthParameters: {
            USERNAME: email,
            PASSWORD: password,
            DEVICE_KEY: 'TOTP',
          },
        },
        (err, data) => {},
      );

      var aaa2 = await cognito.adminSetUserMFAPreference(
        {
          UserPoolId: this.crypto.dec(environment.UserPoolId),
          Username: email,
          SoftwareTokenMfaSettings: {
            Enabled: true,
            PreferredMfa: true,
          },
        },
        (err, data) => {},
      );

      var aaa1 = await cognito.adminGetUser(
        {
          UserPoolId: this.crypto.dec(environment.UserPoolId),
          Username: email,
        },
        (err, data) => {},
      );
    });
  }

  async Getqrcode(email, password) {
    return new Promise(async (resolve) => {
      var authenticationData = {
        Username: email,
        Password: password,
      };
      var authenticationDetails =
        new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);
      var userData = {
        Username: email,
        Pool: userPool,
      };
      var cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {
          var accessToken = result.getAccessToken().getJwtToken();
          cognitoUser.associateSoftwareToken(this);
        },

        onFailure: function (err) {
          resolve([
            { message: err.message, StatusCode: 0, Data: '', code: '' },
          ]);
        },
        totpRequired: (totpRequir, test) => {
          resolve([
            { message: 'topt Required', StatusCode: 2, Data: '', code: '' },
          ]);
        },
        associateSecretCode: function (secretCode) {
          const str =
            'otpauth://totp/Destination%20Certification' +
            '' +
            '?secret=' +
            secretCode +
            '&issuer=';
          resolve([
            {
              message: '',
              StatusCode: 1,
              Data: this,
              code: str,
              devicecode: secretCode,
            },
          ]);
        },
      });
    });
  }
  async uploadFiles(data) {
    const base64Data = data.replace(/^data:(image|text)\/\w+;base64,/, '');
    const type = data.split(';')[0].split('/')[1];
    var chars =
      '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let ids = '';
    for (let id = 0; id < 20; id++) {
      ids += chars[Math.floor(Math.random() * chars.length)];
    }
    var s3Params_logo = {
      Bucket: 'welcomeemailtemplate',
      Key: ids + '.' + (type == 'plain' ? 'txt' : type),
      Body: base64Data,
      ContentEncoding: 'base64',
      ACL: 'public-read',
    };

    const img = await s3.upload(s3Params_logo).promise();
    return img.Location;
  }
  formatZone(zone: string): TZone {
    const utc: string = momentZone.tz(zone).format('Z');
    const abbr: string = momentZone.tz(zone).zoneAbbr();
    return {
      name: `${zone} (${utc})`,
      nameValue: zone,
      timeValue: utc,
      group: zone.split('/', 1)[0],
      abbr: abbr,
    };
  }

  async FlashCardUserLogin(email, password) {
    const authenticationData = {
      Username: email,
      Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    const userData = {
      Username: email,
      Pool: userPool,
    };
    const cognitoUser = new CognitoUser(userData);
    return new Promise((resolve) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {
          resolve([
            { message: 'Login Successful', StatusCode: 1, Data: result },
          ]);
        },
        onFailure: function (err) {
          resolve([{ message: err.message, StatusCode: 0, Data: '' }]);
        },
        newPasswordRequired: (userAttributes) => {},
      });
    });
  }

  async ResetMFASetup(email, password) {
    var cognito = new CognitoIdentityServiceProvider();
    await cognito
      .adminSetUserMFAPreference({
        UserPoolId: this.crypto.dec(environment.UserPoolId),
        Username: email,
        SoftwareTokenMfaSettings: {
          Enabled: false,
        },
      })
      .promise();
  }

  async GetUserRequest(email) {
    var cognito = new CognitoIdentityServiceProvider();
    return new Promise(async (resolve) => {
      cognito.adminGetUser(
        {
          UserPoolId: this.crypto.dec(environment.UserPoolId),
          Username: email,
        },
        (err, data) => {
          if (err != null)
            resolve([{ message: err.message, StatusCode: 0, Data: err }]);
          else resolve([{ message: data, StatusCode: 1, Data: data }]);
        },
      );
    });
  }
  forcechnagepass(email) {
    const user = {
      Username: email,
      UserPoolId: this.crypto.dec(environment.UserPoolId),
    };
    const cognito = new CognitoIdentityServiceProvider();
    return new Promise((resolve, reject) => {
      cognito.adminResetUserPassword(user, (err, data) => {
        if (err) {
          resolve([{ message: err.message, StatusCode: 0, Data: err }]);
        } else {
          resolve([
            {
              message: 'Force password change apply',
              StatusCode: 1,
              Data: data,
            },
          ]);
        }
      });
    });
  }
  UpdateUserstatus(email) {
    const attributeList = [];
    attributeList.push(
      new CognitoUserAttribute({
        Name: 'email_verified',
        Value: 'true',
      }),
    );

    const user = {
      Username: email,
      UserPoolId: this.crypto.dec(environment.UserPoolId),
      UserAttributes: attributeList,
    };
    const cognito = new CognitoIdentityServiceProvider();
    return new Promise((resolve, reject) => {
      cognito.adminUpdateUserAttributes(user, (err, data) => {
        if (err) {
          resolve([{ message: err.message, StatusCode: 0, Data: err }]);
        } else {
          resolve([
            {
              message: 'Successfully User confirmed',
              StatusCode: 1,
              Data: data,
            },
          ]);
        }
      });
    });
  }

  UpdatePasswordStatus(email) {
    const user = {
      Username: email,
      UserPoolId: this.crypto.dec(environment.UserPoolId),
      Password: 'Admin@123',
      Permanent: true,
    };
    const cognito = new CognitoIdentityServiceProvider();
    return new Promise((resolve, reject) => {
      cognito.adminSetUserPassword(user, (err, data) => {
        if (err) {
          resolve([{ message: err.message, StatusCode: 0, Data: err }]);
        } else {
          resolve([
            {
              message: 'Successfully User confirmed',
              StatusCode: 1,
              Data: data,
            },
          ]);
        }
      });
    });
  }
  ChangeTemplateSES() {
    return new Promise(async (resolve, reject) => {
      await SES.listTemplates({}, (err, data) => {
        if (err) {
          resolve([{ message: err, StatusCode: 0 }]);
        } else {
          resolve([{ message: data, StatusCode: 1 }]);
        }
      });
    });
  }
  deleteUser(email) {
    const user = {
      Username: email,
      UserPoolId: userPool.getUserPoolId(),
    };
    config.update({
      secretAccessKey: this.crypto.dec(environment.ACCESS_KEY),
      accessKeyId: this.crypto.dec(environment.KEY_ID),
      region: 'us-east-2',
      correctClockSkew: true,
    });
    const cognito = new CognitoIdentityServiceProvider();
    return new Promise((resolve, reject) => {
      cognito.adminDeleteUser(user, (err, data) => {
        if (err) {
          resolve([{ message: err.message, StatusCode: 0, Data: err }]);
        } else {
          resolve([
            {
              message: 'User deleted successfully !',
              StatusCode: 1,
              Data: data,
            },
          ]);
        }
      });
    });
  }
  currentTimeZone() {
    return this.formatZone(momentZone.tz.guess(true));
  }
  ConvertFormatDbDateTime(
    pStrDate:
      | momentZone.Moment
      | Date
      | string
      | number
      | (number | string)[]
      | momentZone.MomentInputObject
      | null
      | undefined,
    pStrTimeZone: string,
  ) {
    momentZone.tz.setDefault(pStrTimeZone);
    return momentZone(pStrDate).tz(pStrTimeZone).format();
  }
  ConvertAnyTimeZoneToCurrentZone(
    pStrDate:
      | momentZone.Moment
      | Date
      | string
      | number
      | (number | string)[]
      | momentZone.MomentInputObject
      | null
      | undefined,
    pStrTimeZone: string,
  ) {
    momentZone.tz.setDefault(pStrTimeZone);
    return momentZone.parseZone(pStrDate).local(true).toDate();
  }
  ConvertAnyTimeZoneToCurrentZoneDbFormat(
    pStrDate:
      | momentZone.Moment
      | Date
      | string
      | number
      | (number | string)[]
      | momentZone.MomentInputObject
      | null
      | undefined,
    pStrTimeZone: string,
  ) {
    momentZone.tz.setDefault(pStrTimeZone);
    return momentZone(momentZone.parseZone(pStrDate).local(true).toDate())
      .tz(pStrTimeZone)
      .format();
  }
  AddDateHourFormat(
    pStrDate:
      | momentZone.Moment
      | Date
      | string
      | number
      | (number | string)[]
      | momentZone.MomentInputObject
      | null
      | undefined,
    pStrTimeZone: string,
    pStrDuration: momentZone.DurationInputArg1,
    pUnit: momentZone.DurationInputArg2,
  ) {
    return momentZone(pStrDate).add(pStrDuration, pUnit);
  }
  ConvertDateDDFormat(
    pStrDate:
      | momentZone.Moment
      | Date
      | string
      | number
      | (number | string)[]
      | momentZone.MomentInputObject
      | null
      | undefined,
    pStrTimeZone: string,
    pFormat: string,
  ) {
    momentZone.tz.setDefault(pStrTimeZone);
    return momentZone(pStrDate).tz(pStrTimeZone).format(pFormat);
  }
  convertStringUtcTimeZone(
    pStrDate:
      | momentZone.Moment
      | Date
      | string
      | number
      | (number | string)[]
      | momentZone.MomentInputObject
      | null
      | undefined,
    pStrTimeZone: string,
  ) {
    momentZone.tz.setDefault(pStrTimeZone);
    return momentZone.utc(pStrDate).format();
  }
  ConvertAnyTimeZoneToParseTimeZone(
    pStrDate:
      | momentZone.Moment
      | Date
      | string
      | number
      | (number | string)[]
      | momentZone.MomentInputObject
      | null
      | undefined,
    pStrTimeZone: string,
  ) {
    momentZone.tz.setDefault(pStrTimeZone);
    return momentZone
      .parseZone(momentZone(pStrDate).tz(pStrTimeZone).format())
      .local()
      .toDate();
  }
  convertAnyTimeZoneUtcTimeZone(
    pStrDate:
      | momentZone.Moment
      | Date
      | string
      | number
      | (number | string)[]
      | momentZone.MomentInputObject
      | null
      | undefined,
    pStrTimeZone: string,
  ) {
    momentZone.tz.setDefault(pStrTimeZone);
    return momentZone
      .utc(momentZone(pStrDate).tz(pStrTimeZone).format())
      .format();
  }
  convertLocalTimeZoneUtcTimeZone(
    pStrDate:
      | momentZone.Moment
      | Date
      | string
      | number
      | (number | string)[]
      | momentZone.MomentInputObject
      | null
      | undefined,
    pStrTimeZone: string,
    pStrTimeValue: string,
  ) {
    momentZone.tz.setDefault(pStrTimeZone);
    return momentZone
      .utc(pStrDate)
      .utcOffset(pStrTimeValue, true)
      .utc()
      .format();
  }
  convertAnyTime24Hour(pConvertTime) {
    return momentZone(pConvertTime, ['h:mm A']).format('HH:mm');
  }
  convertAnyTime12Hour(pConvertTime) {
    return momentZone(pConvertTime, ['HH:mm']).format('h:mm A');
  }
  convertDate12Hour(pConvertTime) {
    return momentZone(pConvertTime).format('h:mm A');
  }
}
