<div class="survey-wrapper">
  <div class="video-wrapper">
    <div class="video-top">
      <img src="../../../assets/images/sidebar-logo.png" alt="Logo" />
      <h4>
        Introduction to the learning system<span
          >{{ StartDate }} {{ CohortName }}</span
        >
      </h4>
    </div>
    <div class="video-div">
      <div [hidden]="!isVimeoVideo" id="vimeoplayer"></div>
      <button
        type="button"
        class="frm-next-btn"
        (click)="next()"
        *ngIf="videoEnded"
      >
        Continue
      </button>
    </div>
  </div>
</div>
<div class="form-button-next">
  <button type="button" class="frm-prev-btn" style="opacity: 0">
    <span class="material-icons">arrow_back</span>
  </button>
  <button type="button" class="frm-next-btn-down" (click)="next()">
    <span class="material-icons"> arrow_forward </span>
  </button>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
