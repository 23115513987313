import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Http, Headers, RequestOptions } from '@angular/http';
import { Router } from '@angular/router';
import { CryptoService } from '../crypto/crypto.service';

@Injectable({
  providedIn: 'root',
})
export class CourseserviceService {
  baseurl = environment.baseurl;

  constructor(
    public http: Http,
    private router: Router,
    private crypto: CryptoService,
  ) {}
  getDashboardPercentage(user_id, cource_id, cohort_id, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          user_id: user_id,
          cource_id: cource_id,
          cohort_id: cohort_id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);

      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + environment.POINTS_STUDENT_DASH;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }

  getScheduleDomain(method_type, method_id, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          method_type: method_type,
          method_id: method_id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + environment.SCHEDULE_DOMAIN;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve(err);
        },
      );
    });
  }

  async knowassquecource(user_id: number, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          user_id: user_id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + environment.KNOW_ASS_QUES_COURSE;
      this.http.post(url, data, options).subscribe(
        (res) => {
          try {
            const resBody = JSON.parse(res['_body']);
            const cohortId = localStorage.getItem('cohort_id');

            const cohort = resBody.data.find(
              (item) => '' + item.cohort_id == '' + cohortId,
            );

            localStorage.setItem('shortName', cohort.short_name);
            localStorage.setItem('cohortName', cohort.cohort_name);
          } catch (err) {}

          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async Enrollusercourse(c_id, u_id, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          c_id: c_id,
          u_id: u_id,
        }),
      ),
    };
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    let options = new RequestOptions({ headers: headers });

    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + environment.ENROLL_ADD;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async updateResourceShow(user_id, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          user_id: user_id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + environment.UPDATE_SHOW_RESOURCES;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async updateOptIn(user_id, cohort_id, opt_in, schedule_date, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          u_id: user_id,
          c_id: cohort_id,
          opt_in: opt_in,
          schedule_date: schedule_date,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + environment.UPDATE_OPT;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async ChangeCohortId(email, course_id, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          email: email,
          course_id: course_id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + environment.USER_UPDATE_BY_EMAIL;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }

  async cohortsCertificateExpireDate(user_id: number, cohort_id, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          user_id: user_id,
          cohort_id: cohort_id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + environment.COHORTSCERTIFICATEEXPIREDATE;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
}
