import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServicesService } from '../services.service';
import { AuthserviceService } from '../authservice.service';
import { LoginflowService } from '../data/loginflow.service';

@Component({
  selector: 'app-authold',
  templateUrl: './authold.component.html',
  styleUrls: ['./authold.component.scss'],
})
export class AutholdComponent implements OnInit {
  public AuthForm: FormGroup;

  Authcode: any;
  data: any;
  constructor(
    public ActiveRoute: ActivatedRoute,
    public service: ServicesService,
    public auth: AuthserviceService,
    public spinner: NgxSpinnerService,
    public fb: FormBuilder,
    public router: Router,
    public LoginflowService: LoginflowService,
  ) {
    this.ActiveRoute.params.subscribe(async (params) => {
      try {
        this.data = JSON.parse(atob(decodeURIComponent(params['data'])));
      } catch (error) {
        this.router.navigate(['/login']);
      }
    });
  }
  ngOnInit() {
    this.AuthForm = this.fb.group({
      Authcode: ['', Validators.required],
    });
  }
  dashboard() {
    this.router.navigate(['/sidenav']);
  }
  async ConfimCode() {
    try {
      await this.spinner.show();
      await this.auth.confirmAuthCode(this.Authcode, this.data.email).then(
        async (data) => {
          if (data[0].StatusCode == 1) {
            await this.auth
              .getAuthenticatedUser()
              .getSession(async (err, session) => {
                if (err) {
                  this.spinner.hide();
                  this.router.navigate(['/login']);
                } else {
                  var token = session.getIdToken().getJwtToken();
                  await this.LoginflowService.UsersFilter(
                    this.data.email,
                    token,
                  ).then(async (user) => {
                    if (user[0].status == 200) {
                      var list = JSON.parse(<string>user[0].json);

                      localStorage.setItem(
                        'CisspUserId',
                        JSON.stringify(list.data.id),
                      );
                      localStorage.setItem(
                        'userdetails',
                        JSON.stringify(list.data),
                      );
                      await this.spinner.hide();
                      var userdata = {
                        email: this.data.email,
                        password: this.data.password,
                      };
                      var email = btoa(JSON.stringify(userdata));
                      email = encodeURIComponent(email);
                      this.router.navigate([`/welcome/${email}`]);
                    } else {
                      await this.spinner.hide();
                    }
                  });
                }
              });
          } else {
            await this.service.ShowError(data[0].message);
            await this.spinner.hide();
          }
        },
        (err) => {
          this.spinner.hide();
          this.service.ShowError(err);
        },
      );
    } catch (e) {
      this.service.ShowError(e.message);
      this.spinner.hide();
    }
  }
}
