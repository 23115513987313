import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ServicesService } from '../../../services.service';
import { AuthserviceService } from '../../../authservice.service';
import { TakesurveyService } from '../../../../app/services/takesurvey/takesurvey.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-feedbackjohn',
  templateUrl: './feedbackjohn.component.html',
  styleUrls: ['./feedbackjohn.component.scss'],
})
export class FeedbackjohnComponent implements OnInit {
  JohnInputValue = '';

  constructor(
    public TakesurveyService: TakesurveyService,
    public ActiveRoute: ActivatedRoute,
    public service: ServicesService,
    public auth: AuthserviceService,
    public router: Router,
    public spinner: NgxSpinnerService,
  ) {}

  ngOnInit(): void {
    this.CheckFeedbackValue();
  }

  async UpdateJohnFeedBackValue() {
    if (this.JohnInputValue) {
      try {
        await this.spinner.show();
        await this.auth
          .getAuthenticatedUser()
          .getSession(async (err, session) => {
            if (err) {
              await this.spinner.hide();
              this.router.navigate(['/login']);
            } else {
              await this.spinner.show();
              var token = session.getIdToken().getJwtToken();
              var cohort_id = localStorage.getItem('cohort_id');
              var user_id = localStorage.getItem('CisspUserId');
              let data = {
                user_id: user_id,
                cohort_id: cohort_id,
                feedback_john: this.JohnInputValue,
              };
              await this.TakesurveyService.UpdateSurveyActivity(
                data,
                token,
              ).then(async (data) => {
                if (data[0].status == 200) {
                  var list = JSON.parse(<string>data[0].json);
                  await this.router.navigate(['/feedbackrob']);
                }
                await this.spinner.hide();
              });
            }
          });
      } catch (e) {
        await this.spinner.hide();
        this.service.ShowError(e.meassage);
      }
    }
  }

  async CheckFeedbackValue() {
    try {
      await this.spinner.show();
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            await this.spinner.show();
            var token = session.getIdToken().getJwtToken();
            var cohort_id = localStorage.getItem('cohort_id');
            var user_id = localStorage.getItem('CisspUserId');
            await this.TakesurveyService.GetSurveyActivity(
              user_id,
              cohort_id,
              token,
            ).then(async (data) => {
              if (data[0].status == 200) {
                var list = JSON.parse(<string>data[0].json);
                this.JohnInputValue = list.data[0].feedback_john;
              }
              await this.spinner.hide();
            });
          }
        });
    } catch (e) {
      await this.spinner.hide();
      this.service.ShowError(e.meassage);
    }
  }
}
