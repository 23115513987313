<div class="div-header">
  <div class="logo-div">
    <img src="../../../assets/images/sidebar-logo.png" alt="Logo" />
  </div>
</div>

<div class="div-body">
  <div class="like-image md-off-image">
    <img src="../../../assets/images/like-images.png" alt="like" />
  </div>
  <div class="like-little">
    <h2>Like a little competition?</h2>
    <p>
      If you enjoy a little friendly competition to help motivate you in your
      studies you can choose to be listed on the leaderboard for your class. Our
      system tracks your scores on knowledge assessments and practice tests, how
      many flashcards you know, and how many videos you've watched and generates
      a score.
    </p>
    <p>
      The more you study and the better you do on assessments then the higher
      you will climb up the leaderboard. It's also totally cool to choose to
      opt-out of the leaderboard. You will still see your score but you will not
      be listed on the leaderboard
    </p>
    <p>
      You can change your selection to opt-in or out of the leaderboard at any
      time in the settings menu.
    </p>
  </div>
  <div class="like-image sm-off-image">
    <img src="../../../assets/images/like-images.png" alt="like" />
  </div>
</div>

<div class="div-footer">
  <div class="no-thanks">
    <button (click)="UpdateOptIn('1')">No thanks</button>
  </div>
  <div class="in-btn">
    <button (click)="UpdateOptIn('0')">I'm in</button>
    <span>List me on the leaderboard</span>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
