import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServicesService } from '../../services.service';
import { AuthserviceService } from '../../authservice.service';
import { LoginflowService } from '../../data/loginflow.service';
import { VgApiService } from '@videogular/ngx-videogular/core';
import Player from '@vimeo/player';
@Component({
  selector: 'app-learningvideo',
  templateUrl: './learningvideo.component.html',
  styleUrls: ['./learningvideo.component.scss'],
})
export class LearningvideoComponent implements OnInit {
  get isVimeoVideo(): boolean {
    return ('' + this.videoLink).startsWith('https://vimeo.com');
  }
  videoLink?: any;
  vimeoPlayer?: any;
  videoEnded = false;
  data: any;
  Users = [];
  cohortId: any;
  CohortName: any;
  StartDate: any;
  constructor(
    public LoginflowService: LoginflowService,
    public ActiveRoute: ActivatedRoute,
    public router: Router,
    public spinner: NgxSpinnerService,
    public service: ServicesService,
    public auth: AuthserviceService,
  ) {
    this.ActiveRoute.params.subscribe(async (params) => {
      try {
        this.data = JSON.parse(atob(decodeURIComponent(params['data'])));
        this.CohortName = this.data.cohort;
        this.StartDate = this.data.date;
        this.cohortId = this.data?.cohort_id;
        await this.service
          .get_welcome_links(this.cohortId)
          .then(async (data) => {
            var list = JSON.parse(<string>data[0].json);
            var cohortlist = list?.data?.[0];
            this.CohortName = cohortlist?.[0]?.cohort_name;
            // Order of videos in onboarding:
            // hybrid_welcome_video on the welcome screen
            // self_welcome_video on the selfPacedVideo screen
            // live_welcome_video on the learningVideo screen
            this.videoLink = cohortlist[0].live_welcome_video;
          });

        if (this.isVimeoVideo) {
          this.setupVimeoPlayer(this.videoLink);
        }
      } catch (error) {
        this.router.navigate(['/login']);
      }
      this.spinner.hide();
    });
  }
  ngOnInit() {}

  async next() {
    var userdata = {
      email: this.data.email,
      password: this.data.password,
      cohort_id: this.data.cohort_id,
    };
    var email = btoa(JSON.stringify(userdata));
    email = encodeURIComponent(email);
    await this.LoginflowService.updateUserWelcomeFlow(
      this.data.email,
      '11',
      this.data.cohort_id,
    ).then(async (data) => {
      this.router.navigate([`/thankyou/${email}`]);
    });
  }

  async setupVimeoPlayer(vimeoUrl) {
    let playerHeight = 450;

    if (window.innerHeight > 1500) {
      playerHeight = 1400;
    } else if (window.innerHeight > 1100) {
      playerHeight = 850;
    } else if (window.innerHeight > 850) {
      playerHeight = 650;
    }

    this.vimeoPlayer = new Player('vimeoplayer', {
      url: this.getEmbedVimeoVideoUrl(vimeoUrl),
      height: playerHeight,
    });
    this.vimeoPlayer.on('play', () => {
      this.videoEnded = false;
    });
    this.vimeoPlayer.on('ended', () => {
      this.videoEnded = true;
    });

    await this.vimeoPlayer.ready();
  }

  getEmbedVimeoVideoUrl(vimeoUrl) {
    const regex = /vimeo\.com\/(\d+)\/([a-z0-9]+)/i;
    const matches = vimeoUrl.match(regex);

    if (matches && matches.length === 3) {
      const videoId = matches[1];
      const hash = matches[2];

      return `https://player.vimeo.com/video/${videoId}?h=${hash}`;
    } else {
      return null;
    }
  }
}
