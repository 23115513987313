import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServicesService } from '../../../services.service';
import { AuthserviceService } from '../../../authservice.service';
import { TakesurveyService } from '../../../../app/services/takesurvey/takesurvey.service';

@Component({
  selector: 'app-trainingclass',
  templateUrl: './trainingclass.component.html',
  styleUrls: ['./trainingclass.component.scss'],
})
export class TrainingclassComponent implements OnInit {
  Certificatelist = [];
  certiIds = [];

  constructor(
    public TakesurveyService: TakesurveyService,
    public ActiveRoute: ActivatedRoute,
    public service: ServicesService,
    public auth: AuthserviceService,
    public spinner: NgxSpinnerService,
    public router: Router,
  ) {}

  ngOnInit(): void {
    this.GetAllDropdown();
  }
  checkValidation() {
    if (this.Certificatelist.length > 0) {
      let Exit = this.Certificatelist.filter((x) => x.checked);
      if (Exit.length > 0) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  changechk(item) {
    if (item.checked) {
      item.checked = false;
    } else {
      item.checked = true;
    }
  }

  async UpdateClassInterestValue() {
    let Ids = '';
    this.Certificatelist.forEach((element) => {
      if (element.checked) {
        Ids += element.id + ',';
      }
    });
    if (Ids.endsWith(',') == true) {
      Ids = Ids.substring(0, Ids.length - 1);
    }
    try {
      await this.spinner.show();
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            await this.spinner.show();
            var token = session.getIdToken().getJwtToken();
            var cohort_id = localStorage.getItem('cohort_id');
            var user_id = localStorage.getItem('CisspUserId');
            let data = {
              user_id: user_id,
              cohort_id: cohort_id,
              class_interest: Ids,
            };
            await this.TakesurveyService.UpdateSurveyActivity(data, token).then(
              async (data) => {
                if (data[0].status == 200) {
                  var list = JSON.parse(<string>data[0].json);
                  await this.router.navigate(['/friendcolleague']);
                }
                await this.spinner.hide();
              },
            );
          }
        });
    } catch (e) {
      await this.spinner.hide();
      this.service.ShowError(e.meassage);
    }
  }

  async GetAllDropdown() {
    this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        await this.TakesurveyService.GetAllDropdown(token).then(
          async (data) => {
            if (data[0].status == 200) {
              var list = JSON.parse(<string>data[0].json);
              this.Certificatelist = list.data.Certificate;
              this.Certificatelist.forEach((element) => {
                element['checked'] = false;
              });
              this.CheckTrainingClassValue();
              await this.spinner.hide();
            }
          },
        );
      }
    });
  }

  async CheckTrainingClassValue() {
    try {
      await this.spinner.show();
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            await this.spinner.show();
            var token = session.getIdToken().getJwtToken();
            var cohort_id = localStorage.getItem('cohort_id');
            var user_id = localStorage.getItem('CisspUserId');
            await this.TakesurveyService.GetSurveyActivity(
              user_id,
              cohort_id,
              token,
            ).then(async (data) => {
              if (data[0].status == 200) {
                var list = JSON.parse(<string>data[0].json);
                if (list.data[0].class_interest != null) {
                  this.certiIds = list.data[0].class_interest.split(',');
                  this.Certificatelist.forEach((ele) => {
                    this.certiIds.forEach((element) => {
                      if (element == ele.id) {
                        ele.checked = true;
                      }
                    });
                  });
                }
              }
              await this.spinner.hide();
            });
          }
        });
    } catch (e) {
      await this.spinner.hide();
      this.service.ShowError(e.meassage);
    }
  }
}
