<div class="img-center">
  <img src="../../../../assets/images/sidebar-logo.png" alt="" />
</div>
<div class="servey-center step-4">
  <h4>Do you have any feedback for John as an instructor?</h4>
  <textarea placeholder="Your Answer" [(ngModel)]="JohnInputValue"></textarea>
</div>
<div class="form-button-next">
  <button type="button" class="frm-prev-btn">
    <span class="material-icons" [routerLink]="['/additionalfeedback']"
      >arrow_back</span
    >
  </button>
  <button
    type="button"
    class="frm-next-btn"
    (click)="UpdateJohnFeedBackValue()"
    [disabled]="JohnInputValue == ''"
  >
    <span class="material-icons"> arrow_forward </span>
  </button>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
