<div class="main_record">
  <div class="event_record">
    <div class="back_logo">
      <div class="mat_text" (click)="back()">
        <mat-icon>arrow_back</mat-icon>
        <h2>Back</h2>
      </div>
      <img src="../../../assets/images/sidebar-logo.png" alt="" />
    </div>
    <div class="video_startup">
      <div
        class="recording_box"
        [ngClass]="{ recording_box_bg: !item.is_playing }"
        *ngFor="let item of Recordings"
      >
        <div class="recording-title" (click)="eventClick(item)">
          <button class="coloract" *ngIf="item.is_playing">
            <mat-icon>pause</mat-icon>
          </button>
          <button *ngIf="!item.is_playing">
            <img src="../../../assets/svg/video.svg" alt="video" />
          </button>
          <div class="texted">
            <h2 class="coloract1">{{ item.title }}</h2>
            <p>{{ item.createdAt | date: 'dd-MM-yyyy, hh:mm:ss' }}</p>
          </div>
          <div class="time_zone">
            {{ item.duration }}
          </div>
        </div>
        <div
          class="question-tab"
          [ngClass]="{ 'que-active': que.active && !que.done }"
          *ngFor="let que of item.questions"
          (click)="questionClick(que, item)"
        >
          <span
            class="question-check-icon"
            [ngClass]="{ roundActive: que.active || que.done }"
          ></span>
          <p class="question-text-title">{{ que.Text }}</p>
        </div>
        <div *ngIf="item.questions.length <= 0" class="no-question">
          <span>No questions answered available</span>
        </div>
      </div>
    </div>
  </div>
  <div class="event_video">
    <vg-player
      (onPlayerReady)="onPlayerReady($event)"
      (mousemove)="resetTimer()"
      [ngClass]="{ vgControls: !showControls }"
    >
      <vg-controls>
        <div class="forword-backword-button">
          <span class="material-icons-outlined" (click)="backwordvideo()">
            replay_10
          </span>
          <vg-play-pause></vg-play-pause>
          <span class="material-icons-outlined" (click)="forwordvideo()">
            forward_10
          </span>
        </div>
        <vg-time-display
          vgProperty="current"
          vgFormat="hh:mm:ss"
        ></vg-time-display>
        <vg-scrub-bar readonly="disabled" disabled="disabled">
          <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
          <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
        </vg-scrub-bar>
        <vg-time-display
          vgProperty="left"
          vgFormat="hh:mm:ss"
        ></vg-time-display>
        <div class="settings" #videoElement>
          <div
            class="settings-button"
            [ngClass]="{ active_settings: activeSettings }"
            (click)="toggleSettings()"
          >
            <span class="material-icons-outlined">settings</span>
          </div>
          <div class="settings-popup" *ngIf="showSettingsPopup">
            <div class="options" (click)="showoption('PlaybackOptions')">
              <div class="option-name">
                <img
                  src="../../../assets/playback-speed.png"
                  alt="playback-speed"
                />
                <span>Playback speed</span>
              </div>
              <div class="arrow">
                <span>{{ selectedPlaybackSpeed }}</span>
                <span class="material-icons">keyboard_arrow_right</span>
              </div>
            </div>
            <div class="options" (click)="showoption('QualityOptions')">
              <div class="option-name">
                <span class="material-icons-outlined"> tune </span>
                <span>Video quality</span>
              </div>
              <div class="arrow">
                <span>{{ selectedVideoQuality }}</span>
                <span class="material-icons">keyboard_arrow_right</span>
              </div>
            </div>
          </div>
          <div class="PlaybackSettings" *ngIf="showPlaybackPopup">
            <div class="playback-speed-options">
              <label
                class="back-button"
                (click)="backShowoption('PlaybackOptions')"
              >
                <span class="material-icons">keyboard_arrow_left</span>
                <span>Playback speed</span>
              </label>
              <div class="playbackSpeedList">
                <div
                  *ngFor="let speed of playbackSpeedOptions"
                  [ngClass]="{ activeSpeed: speed == selectedPlaybackSpeed }"
                  (click)="setPlaybackSpeed(speed)"
                >
                  {{ speed }}
                  <span
                    *ngIf="speed == selectedPlaybackSpeed"
                    class="material-icons-outlined"
                    >check</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="PlaybackSettings" *ngIf="showVideoQuality">
            <div class="playback-speed-options">
              <label
                class="back-button"
                (click)="backShowoption('QualityOptions')"
              >
                <span class="material-icons">keyboard_arrow_left</span>
                <span>Video quality</span>
              </label>
              <div class="playbackSpeedList">
                <div
                  *ngFor="let quality of videoQualityOptions"
                  [ngClass]="{ activeSpeed: quality == selectedVideoQuality }"
                  (click)="setVideoQuality(quality)"
                >
                  {{ quality }}
                  <span
                    *ngIf="quality == selectedVideoQuality"
                    class="material-icons-outlined"
                    >check</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <vg-mute></vg-mute>
        <vg-fullscreen></vg-fullscreen>
      </vg-controls>
      <video
        #media
        [vgMedia]="media"
        [src]="VideoLink"
        id="singleVideo"
        preload="auto"
      ></video>
    </vg-player>
    <div class="transcript_area">
      <div class="transcript">
        <div class="trans_block">
          <div class="translogo">
            <img src="../../../assets/images/Event-Recordings.png" />
            <h1>Questions Answered</h1>
          </div>
        </div>
      </div>
      <div class="transcript_data">
        <ul class="questions">
          <li *ngFor="let item of currentItem.questions">
            <span class="time">{{ item.FromTime }} </span>
            <span class="text">| {{ item.Text }}</span>
          </li>
        </ul>
        <span *ngIf="currentItem.questions.length <= 0" class="no-data">
          No questions answered available
        </span>
      </div>
    </div>
  </div>
</div>
<div class="mindmap-modal" *ngIf="mobilePopup">
  <div class="midal-contain">
    <div class="modal-header">
      <div class="main-head2">
        <h3>{{ mobileTitle }}</h3>
      </div>
      <span class="material-icons" (click)="mobilePopup = false"> close </span>
    </div>
    <div class="modal-body">
      <div class="event_video">
        <vg-player
          (onPlayerReady)="onPlayerReady($event)"
          (mousemove)="resetTimer()"
          [ngClass]="{ vgControls: !showControls }"
        >
          <vg-controls>
            <div class="forword-backword-button">
              <span class="material-icons-outlined" (click)="backwordvideo()">
                replay_10
              </span>
              <vg-play-pause></vg-play-pause>
              <span class="material-icons-outlined" (click)="forwordvideo()">
                forward_10
              </span>
            </div>
            <vg-time-display
              vgProperty="current"
              vgFormat="mm:ss"
            ></vg-time-display>
            <vg-scrub-bar readonly="disabled" disabled="disabled">
              <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
              <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
            </vg-scrub-bar>
            <vg-time-display
              vgProperty="left"
              vgFormat="mm:ss"
              style="justify-content: flex-end !important; margin-right: 18px"
            ></vg-time-display>

            <div class="settings">
              <div
                class="settings-button"
                [ngClass]="{ active_settings: activeSettings }"
                (click)="toggleSettings()"
              >
                <span class="material-icons-outlined">settings</span>
              </div>
              <div class="settings-popup" *ngIf="showSettingsPopup">
                <div class="options" (click)="showoption('PlaybackOptions')">
                  <div class="option-name">
                    <img
                      src="../../../assets/playback-speed.png"
                      alt="playback-speed"
                    />
                    <span>Playback speed</span>
                  </div>
                  <div class="arrow">
                    <span>{{ selectedPlaybackSpeed }}</span>
                    <span class="material-icons">keyboard_arrow_right</span>
                  </div>
                </div>
                <div class="options" (click)="showoption('QualityOptions')">
                  <div class="option-name">
                    <span class="material-icons-outlined"> tune </span>
                    <span>Video quality</span>
                  </div>
                  <div class="arrow">
                    <span>{{ selectedVideoQuality }}</span>
                    <span class="material-icons">keyboard_arrow_right</span>
                  </div>
                </div>
              </div>
              <div class="PlaybackSettings" *ngIf="showPlaybackPopup">
                <div class="playback-speed-options">
                  <label
                    class="back-button"
                    (click)="backShowoption('PlaybackOptions')"
                  >
                    <span class="material-icons">keyboard_arrow_left</span>
                    <span>Playback speed</span>
                  </label>
                  <div class="playbackSpeedList">
                    <div
                      *ngFor="let speed of playbackSpeedOptions"
                      [ngClass]="{
                        activeSpeed: speed == selectedPlaybackSpeed
                      }"
                      (click)="setPlaybackSpeed(speed)"
                    >
                      {{ speed }}
                      <span
                        *ngIf="speed == selectedPlaybackSpeed"
                        class="material-icons-outlined"
                        >check</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="PlaybackSettings" *ngIf="showVideoQuality">
                <div class="playback-speed-options">
                  <label
                    class="back-button"
                    (click)="backShowoption('QualityOptions')"
                  >
                    <span class="material-icons">keyboard_arrow_left</span>
                    <span>Video quality</span>
                  </label>
                  <div class="playbackSpeedList">
                    <div
                      *ngFor="let quality of videoQualityOptions"
                      [ngClass]="{
                        activeSpeed: quality == selectedVideoQuality
                      }"
                      (click)="setVideoQuality(quality)"
                    >
                      {{ quality }}
                      <span
                        *ngIf="quality == selectedVideoQuality"
                        class="material-icons-outlined"
                        >check</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <vg-mute></vg-mute>
            <vg-fullscreen></vg-fullscreen>
          </vg-controls>
          <video
            #media
            [vgMedia]="media"
            [src]="currentItem.recording_url"
            id="singleVideo"
            preload="auto"
          ></video>
        </vg-player>
        <div class="transcript_area">
          <div class="transcript">
            <div class="trans_block">
              <div class="translogo">
                <img src="../../../assets/images/Event-Recordings.png" />
                <h1>Questions Answered</h1>
              </div>
            </div>
          </div>
          <div class="transcript_data"></div>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
