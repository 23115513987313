<div class="signup">
  <div class="login-form">
    <div class="logo">
      <img src="../../assets/cissplogo.png" alt="CISSP" />
    </div>
    <h3><span>Create Account</span></h3>
    <div class="form" [formGroup]="SignupForm">
      <div class="input-form mb-20">
        <input
          type="text"
          name="name"
          formControlName="Username"
          [(ngModel)]="Username"
          class="input"
          placeholder="User Name"
        />
        <svg
          width="18"
          height="19"
          viewBox="0 0 18 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9 18.028C4.02919 18.028 0 13.9919 0 9.01398C0 4.03601 4.02919 0 9 0C13.9703 0 18 4.03601 18 9.01398C18 13.9919 13.9703 18.028 9 18.028ZM9 16.9012C10.7353 16.9012 12.3255 16.3215 13.6271 15.3683C13.3982 14.979 12.9842 14.3604 12.3519 13.7272C11.8839 13.2584 11.3158 12.7891 10.8113 12.3982C10.2668 12.7435 9.657 12.9582 9 12.9582C8.343 12.9582 7.73325 12.7435 7.18875 12.3982C6.68419 12.7891 6.1155 13.2584 5.64806 13.7272C5.01525 14.3604 4.60181 14.979 4.37231 15.3683C5.67394 16.3215 7.26412 16.9012 9 16.9012ZM11.8125 8.17004C11.8125 6.14697 10.5531 4.50755 9 4.50755C7.44637 4.50755 6.1875 6.14697 6.1875 8.17004C6.1875 10.1926 7.44694 11.8314 9 11.8314C10.5531 11.8314 11.8125 10.1926 11.8125 8.17004ZM9 1.12675C4.65075 1.12675 1.125 4.65854 1.125 9.01398C1.125 11.241 2.05481 13.2438 3.537 14.6787C3.74344 14.3086 4.1535 13.6624 4.82288 12.9925C5.28075 12.5334 5.83706 12.0703 6.33769 11.6787C5.55863 10.8032 5.0625 9.56157 5.0625 8.17004C5.0625 5.52444 6.82538 3.3808 9 3.3808C11.1746 3.3808 12.9375 5.52444 12.9375 8.17004C12.9375 9.56157 12.4414 10.8032 11.6617 11.6787C12.1624 12.0703 12.7187 12.5334 13.1766 12.9925C13.8459 13.6624 14.256 14.3086 14.4624 14.6787C15.9452 13.2438 16.875 11.241 16.875 9.01398C16.875 4.65854 13.3487 1.12675 9 1.12675Z"
            fill="#7066AB"
          />
        </svg>
      </div>

      <div class="input-form mb-20">
        <input
          type="email"
          formControlName="email"
          [(ngModel)]="email"
          name="email"
          class="input"
          placeholder="Email"
          type="email"
        />
        <svg
          class="maill"
          width="17"
          height="12"
          viewBox="0 0 17 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M14.789 0H2.21098C0.990338 0 0 1.01062 0 2.25627V9.74373C0 10.9894 0.990338 12 2.21098 12H14.789C16.0097 12 17 10.9894 17 9.74373V2.25627C17 1.01062 16.0097 0 14.789 0ZM2.21098 1.30362H14.789C15.3041 1.30362 15.7225 1.73059 15.7225 2.25627V9.74373C15.7225 10.2694 15.3041 10.6964 14.789 10.6964H2.21098C1.69586 10.6964 1.27746 10.2694 1.27746 9.74373V2.25627C1.27746 1.73059 1.69586 1.30362 2.21098 1.30362ZM14.3683 3.36019C14.1825 3.05416 13.7888 2.95974 13.4889 3.14931L9.9686 5.37403C9.85874 5.40653 9.75708 5.46906 9.67654 5.5586L8.50098 6.3015L7.31492 5.54929C7.23926 5.46843 7.14602 5.41066 7.04536 5.37833L3.53161 3.14988L3.43921 3.1013C3.15534 2.98068 2.81956 3.08413 2.65193 3.35927C2.46567 3.66499 2.55754 4.0669 2.85712 4.25697L5.63005 6.01629L2.85381 7.80182L2.77061 7.8654C2.54026 8.07327 2.48472 8.42669 2.654 8.70078C2.84209 9.00533 3.23649 9.09662 3.53492 8.90468L6.83615 6.78151L8.16275 7.62319L8.25415 7.67125C8.4424 7.75139 8.65944 7.73558 8.83635 7.62375L10.1673 6.78246L13.486 8.90552L13.5783 8.95444C13.8617 9.07614 14.1978 8.97396 14.3665 8.69945C14.5538 8.39444 14.4634 7.99218 14.1645 7.80098L11.377 6.01776L14.1616 4.25754L14.2453 4.19462C14.4772 3.98858 14.5355 3.63562 14.3683 3.36019Z"
            fill="#7066AB"
          />
        </svg>
      </div>
      <div class="input-form mb-20">
        <input
          type="password"
          formControlName="password"
          [(ngModel)]="password"
          name="password"
          class="input"
          placeholder="Password"
        />
        <svg
          class="lock"
          width="14"
          height="21"
          viewBox="0 0 14 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7 20.0311C3.13409 20.0311 0 16.9477 0 13.1452C0 11.6761 0.476 10.321 1.27273 9.2037V5.19335C1.27273 2.32534 3.83664 0 7 0C10.1634 0 12.7273 2.32534 12.7273 5.19335V9.20307C13.524 10.321 14 11.6761 14 13.1452C14 16.9477 10.8659 20.0311 7 20.0311ZM11.4545 5.17144C11.4545 3.00697 9.46018 1.25186 7 1.25186C4.53982 1.25186 2.54545 3.00697 2.54545 5.17144V7.87484C3.759 6.88149 5.29836 6.25994 7 6.25994C8.70227 6.25994 10.241 6.88149 11.4545 7.87422V5.17144ZM7 7.5118C3.83727 7.5118 1.27273 10.0337 1.27273 13.1452C1.27273 16.2567 3.83664 18.7786 7 18.7786C10.1634 18.7786 12.7273 16.2567 12.7273 13.1452C12.7273 10.0337 10.1627 7.5118 7 7.5118ZM7 15.0224C6.64873 15.0224 6.36364 14.7426 6.36364 14.397V11.8939C6.36364 11.5478 6.64809 11.268 7 11.268C7.35191 11.268 7.63636 11.5478 7.63636 11.8939V14.397C7.63636 14.7426 7.35127 15.0224 7 15.0224Z"
            fill="#7066AB"
          />
        </svg>
      </div>
      <div class="input-form mb-20">
        <input
          type="password"
          name="password"
          formControlName="confirmpass"
          [(ngModel)]="confirmpass"
          class="input"
          placeholder="Re-enter Password"
        />
        <svg
          class="visibility"
          width="20"
          height="12"
          viewBox="0 0 20 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M19 6.08696C19 6.08696 14.9711 11.1739 10 11.1739C5.02887 11.1739 1 6.08696 1 6.08696C1 6.08696 5.02887 1 10 1C14.9711 1 19 6.08696 19 6.08696Z"
            stroke="#7066AB"
            stroke-width="1.3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13 6.09003C13 7.74753 11.6567 9.09003 10 9.09003C8.34325 9.09003 7 7.74753 7 6.09003C7 4.43253 8.34325 3.09003 10 3.09003C11.6567 3.09003 13 4.43253 13 6.09003Z"
            stroke="#7066AB"
            stroke-width="1.3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
    <p class="loginsign">
      Already have an account? <a routerLink="/login">Login here</a>
    </p>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
<app-noconnection></app-noconnection>
