import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServicesService } from '../../services.service';
import { AuthserviceService } from '../../authservice.service';
import { LoginflowService } from '../../data/loginflow.service';
import Player from '@vimeo/player';

@Component({
  selector: 'app-selfpacedvideo',
  templateUrl: './selfpacedvideo.component.html',
  styleUrls: ['./selfpacedvideo.component.scss'],
})
export class SelfpacedvideoComponent implements OnInit {
  get isVimeoVideo(): boolean {
    return ('' + this.videoLink).startsWith('https://vimeo.com');
  }
  videoLink?: any;
  vimeoPlayer?: any;
  videoEnded = false;
  data: any;
  Users = [];
  cohort_id: any;
  CohortName: any;
  StartDate: any;
  course_type: any;
  isselfpaced: boolean = false;

  constructor(
    public LoginflowService: LoginflowService,
    public ActiveRoute: ActivatedRoute,
    public router: Router,
    public spinner: NgxSpinnerService,
    public service: ServicesService,
    public auth: AuthserviceService,
  ) {
    this.ActiveRoute.params.subscribe(async (params) => {
      try {
        this.data = JSON.parse(atob(decodeURIComponent(params['data'])));
        if (this.data.cohort_id) {
          this.data['password'] = JSON.parse(
            atob(localStorage.getItem('domaindata')),
          );
          localStorage.setItem('cohort_id', this.data.cohort_id);
        }
        this.cohort_id = localStorage.getItem('cohort_id');
        await this.service
          .get_welcome_links(this.cohort_id, this.data.email)
          .then(async (data) => {
            var list = JSON.parse(<string>data[0].json);
            var cohortlist = list.data[0];
            window.localStorage.setItem(
              'CisspCourseId',
              cohortlist[0].certi_id,
            );
            this.CohortName = cohortlist[0].cohort_name;
            // Order of videos in onboarding:
            // hybrid_welcome_video on the welcome screen
            // self_welcome_video on the selfPacedVideo screen
            // live_welcome_video on the learningVideo screen
            if (
              cohortlist[0].course_type == null ||
              cohortlist[0].course_type == ''
            ) {
              this.course_type = 'Live Class';
              this.videoLink = cohortlist[0].self_welcome_video;
            } else if (cohortlist[0].course_type == 'hybrid') {
              this.course_type = 'Hybrid Class';
              this.videoLink = cohortlist[0].self_welcome_video;
            } else {
              this.course_type = 'Self-Paced';
              this.videoLink = cohortlist[0].self_welcome_video;
              this.isselfpaced = true;
            }
          });

        if (this.isVimeoVideo) {
          this.setupVimeoPlayer(this.videoLink);
        }

        this.StartDate = this.data.date;
        await this.versions_getUserById(this.data.user_id);
      } catch (error) {
        this.router.navigate(['/login']);
      }
    });
  }
  ngOnInit() {}

  async versions_getUserById(user_id) {
    await this.spinner.show();
    await this.LoginflowService.versions_getUserByEmail(this.data.email).then(
      (data) => {
        var list = JSON.parse(<string>data[0].json);
        this.Users = list.data;
      },
    );
    await this.spinner.hide();
  }

  async next() {
    var userdata = {
      email: this.data.email,
      password: this.data.password,
      cohort_id: this.data.cohort_id,
    };
    var email = btoa(JSON.stringify(userdata));
    email = encodeURIComponent(email);
    if (this.Users[0].is_selfpaced == 1 && this.course_type == 'Self-Paced') {
      await this.LoginflowService.updateUserWelcomeFlow(
        this.data.email,
        '8',
        this.data.cohort_id,
      ).then(async (data) => {
        this.router.navigate([`/customschedule/${email}`]);
      });
    } else {
      await this.LoginflowService.updateUserWelcomeFlow(
        this.data.email,
        '9',
        this.data.cohort_id,
      ).then(async (data) => {
        this.router.navigate([`/competition/${email}`]);
      });
    }
  }

  async setupVimeoPlayer(vimeoUrl) {
    let playerHeight = 450;

    if (window.innerHeight > 1500) {
      playerHeight = 1400;
    } else if (window.innerHeight > 1100) {
      playerHeight = 850;
    } else if (window.innerHeight > 850) {
      playerHeight = 650;
    }

    this.vimeoPlayer = new Player('vimeoplayer', {
      url: this.getEmbedVimeoVideoUrl(vimeoUrl),
      height: playerHeight,
    });
    this.vimeoPlayer.on('play', () => {
      this.videoEnded = false;
    });
    this.vimeoPlayer.on('ended', () => {
      this.videoEnded = true;
    });

    await this.vimeoPlayer.ready();
  }

  getEmbedVimeoVideoUrl(vimeoUrl) {
    const regex = /vimeo\.com\/(\d+)\/([a-z0-9]+)/i;
    const matches = vimeoUrl.match(regex);

    if (matches && matches.length === 3) {
      const videoId = matches[1];
      const hash = matches[2];

      return `https://player.vimeo.com/video/${videoId}?h=${hash}`;
    } else {
      return null;
    }
  }
}
