import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthserviceService } from './authservice.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardServiceService implements CanActivate {
  constructor(
    public auth: AuthserviceService,
    private router: Router,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean {
    var setlogin = this.auth.isLoggedIn();
    if (!setlogin) {
      if (state.url == '/sidenav/dashboard') {
        this.router.navigate(['login']);
      } else {
        this.router.navigate(['login'], {
          queryParams: { returnUrl: state.url },
        });
      }
      return false;
    } else {
      return true;
    }
  }
}
