import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
@Injectable({
  providedIn: 'root',
})
export class StripePaymentService {
  stripeKey =
    'sk_test_51JoLjKAI7lJSHRGDiZMXhMTrr7cAswHBCB7WLh7mGRM5Uu70uJ5egoRcnBEdWFx3vsAb4ROqgaBE3Sb120XnRgpG00sDb5zjVr'; //us
  constructor(public https: Http) {}
  async generateToken(CardHolderName) {
    let headers = new Headers();
    headers.append('Authorization', 'Bearer ' + this.stripeKey);
    headers.append('Content-Type', 'application/x-www-form-urlencoded');

    let body =
      'card[number]=' +
      '4242424242424242' +
      '&card[exp_month]=' +
      '6' +
      '&card[exp_year]=' +
      '2024' +
      '&card[cvc]=' +
      '123' +
      '&card[name]=' +
      CardHolderName;

    let options = new RequestOptions({ headers: headers });

    var curl = 'https://api.stripe.com/v1/tokens';
    return new Promise((resolve) => {
      this.https.post(curl, body, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }

  oneTimePayment(card_token, amount, email, customer) {
    let headers = new Headers();
    headers.append('Authorization', 'Bearer ' + this.stripeKey);
    headers.append('Content-Type', 'application/x-www-form-urlencoded');

    let body =
      'amount=' +
      amount +
      '&currency=' +
      'usd' +
      '&description=' +
      email +
      '&source=' +
      customer.default_source +
      '&customer=' +
      customer.id;
    let options = new RequestOptions({ headers: headers });
    var curl = 'https://api.stripe.com/v1/charges';
    return new Promise((resolve) => {
      this.https.post(curl, body, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  GetProductRetrive(pStrProdcutId) {
    let headers = new Headers();
    headers.append('Authorization', 'Bearer ' + this.stripeKey);
    headers.append('Content-Type', 'application/x-www-form-urlencoded');

    let body = '';
    let options = new RequestOptions({ headers: headers });

    var curl = 'https://api.stripe.com/v1/products/' + pStrProdcutId;
    return new Promise((resolve) => {
      this.https.post(curl, body, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  GetPlanRetrive(pStrPlanId) {
    let headers = new Headers();
    headers.append('Authorization', 'Bearer ' + this.stripeKey);
    headers.append('Content-Type', 'application/x-www-form-urlencoded');

    let body = '';
    let options = new RequestOptions({ headers: headers });

    var curl = 'https://api.stripe.com/v1/plans/' + pStrPlanId;
    return new Promise((resolve) => {
      this.https.post(curl, body, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  public CreateCustomer(pStrtrAccountDetails) {
    let headers = new Headers();
    headers.append('Authorization', 'Bearer ' + this.stripeKey);
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    let body = '';
    if (pStrtrAccountDetails.payment_method != null) {
      body =
        '&description=' +
        pStrtrAccountDetails.description +
        '&name=' +
        pStrtrAccountDetails.name +
        '&email=' +
        pStrtrAccountDetails.email +
        '&payment_method=' +
        pStrtrAccountDetails.payment_method +
        '&invoice_settings[default_payment_method]=' +
        pStrtrAccountDetails.payment_method +
        '&address[line1]=' +
        pStrtrAccountDetails.address.line1 +
        '&address[line2]=' +
        pStrtrAccountDetails.address.line2 +
        '&address[postal_code]=' +
        pStrtrAccountDetails.address.postal_code +
        '&address[city]=' +
        pStrtrAccountDetails.address.city +
        '&address[state]=' +
        pStrtrAccountDetails.address.state +
        '&address[country]=' +
        pStrtrAccountDetails.address.country;
    } else {
      body =
        '&description=' +
        pStrtrAccountDetails.description +
        '&name=' +
        pStrtrAccountDetails.name +
        '&email=' +
        pStrtrAccountDetails.email +
        '&source=' +
        pStrtrAccountDetails.source +
        '&address[line1]=' +
        pStrtrAccountDetails.address.line1 +
        '&address[postal_code]=' +
        pStrtrAccountDetails.address.postal_code +
        '&address[city]=' +
        pStrtrAccountDetails.address.city +
        '&address[state]=' +
        pStrtrAccountDetails.address.state +
        '&address[country]=' +
        pStrtrAccountDetails.address.country;
    }

    let options = new RequestOptions({ headers: headers });
    var curl = 'https://api.stripe.com/v1/customers';
    return new Promise((resolve) => {
      this.https.post(curl, body, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  public CreateSubscriptionPlan(Subscri) {
    let headers = new Headers();
    headers.append('Authorization', 'Bearer ' + this.stripeKey);
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    let body =
      '&customer=' + Subscri.customer + '&items[0][price]=' + Subscri.item;
    let options = new RequestOptions({ headers: headers });
    var curl = 'https://api.stripe.com/v1/subscriptions';
    return new Promise((resolve) => {
      this.https.post(curl, body, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }

  public AttachPaymentMethodToCustomer(PaymentMethodId, CustomerId) {
    let headers = new Headers();
    headers.append('Authorization', 'Bearer ' + this.stripeKey);
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    let body = 'customer=' + CustomerId;
    let options = new RequestOptions({ headers: headers });
    var curl =
      'https://api.stripe.com/v1/payment_methods/' +
      PaymentMethodId +
      '/attach ';
    return new Promise((resolve, reject) => {
      this.https.post(curl, body, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }

  public BalanceTransactions() {
    let headers = new Headers();
    headers.append('Authorization', 'Bearer ' + this.stripeKey);
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    let body = 'limit=3';
    let options = new RequestOptions({ headers: headers });
    var curl = 'https://api.stripe.com/v1/payment/history';
    return new Promise((resolve, reject) => {
      this.https.get(curl, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  public RetirveCustmer(pStrStripeCustmerid) {
    let headers = new Headers();
    headers.append('Authorization', 'Bearer ' + this.stripeKey);
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    let body = '';
    let options = new RequestOptions({ headers: headers });
    var curl = 'https://api.stripe.com/v1/customers/' + pStrStripeCustmerid;
    return new Promise((resolve, reject) => {
      this.https.get(curl, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  public RetirveCardDeatils(pStrCustmerId, pStrCardDetails) {
    let headers = new Headers();
    headers.append('Authorization', 'Bearer ' + this.stripeKey);
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    let body = '';
    let options = new RequestOptions({ headers: headers });
    var curl =
      'https://api.stripe.com/v1/customers/' +
      pStrCustmerId +
      '/sources/' +
      pStrCardDetails;
    return new Promise((resolve, reject) => {
      this.https.get(curl, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  public AttchPaymentMethod(pStrCustmerId, pStrCardDetails) {
    let headers = new Headers();
    headers.append('Authorization', 'Bearer ' + this.stripeKey);
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    let body = '';
    let options = new RequestOptions({ headers: headers });
    var curl =
      'https://api.stripe.com/v1/customers/' +
      pStrCustmerId +
      '/sources/' +
      pStrCardDetails;
    return new Promise((resolve, reject) => {
      this.https.get(curl, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }

  public UpdateCustomer(pStrtrAccountDetails) {
    let headers = new Headers();
    headers.append('Authorization', 'Bearer ' + this.stripeKey);
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    let body = '';

    body = '&source=' + pStrtrAccountDetails.source;

    let options = new RequestOptions({ headers: headers });
    var curl =
      'https://api.stripe.com/v1/customers/' + pStrtrAccountDetails.custmer;
    return new Promise((resolve) => {
      this.https.post(curl, body, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
}
