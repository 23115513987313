import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Http, Headers, RequestOptions } from '@angular/http';
import { CryptoService } from '../crypto/crypto.service';
@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  baseurl = environment.baseurl;

  constructor(
    public http: Http,
    private crypto: CryptoService,
  ) {}
  async getNotificationSettings(user_id, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          user_id: user_id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + environment.GET_NOTIFICATION_SETTING;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve(err);
        },
      );
    });
  }
  async setNotificationSettings(
    user_id,
    oberdue,
    live_events,
    instructor,
    chat,
    token,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          user_id: user_id,
          oberdue: oberdue ? 1 : 0,
          live_events: live_events ? 1 : 0,
          instructor: instructor ? 1 : 0,
          chat: chat ? 1 : 0,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + environment.SET_NOTIFICATION_SETTING;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve(err);
        },
      );
    });
  }
  async getPopupNotification(user_id, cohort_id, fire_time, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          user_id: user_id,
          cohort_id: cohort_id,
          fire_time: fire_time,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + environment.GET_POPUP_NOTIFICATION;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve(err);
        },
      );
    });
  }
  async markReadPopupNotification(user_id, cohort_id, fire_time, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          user_id: user_id,
          cohort_id: cohort_id,
          fire_time: fire_time,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + environment.READ_POPUP_NOTIFICATION;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve(err);
        },
      );
    });
  }
  async getAllNotification(user_id, cohort_id, fire_time, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          user_id: user_id,
          cohort_id: cohort_id,
          fire_time: fire_time,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + environment.GET_ALL_NOTIFICATION;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve(err);
        },
      );
    });
  }
}
