import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { environment } from 'src/environments/environment';
import { CryptoService } from '../crypto/crypto.service';

@Injectable({
  providedIn: 'root',
})
export class ScheduleserviceService {
  baseurl = environment.baseurl;
  constructor(
    public http: Http,
    private crypto: CryptoService,
  ) {}
  async GetScheduleData(token, userId) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          user_id: userId,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + environment.SCHEDULETIME_BY_USER;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve(err);
        },
      );
    });
  }

  async getAllComponentTime(token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + environment.GET_SELF_PACED_STUDY_TIME;
      this.http.post(url, options, { headers: headers }).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }

  async schedulesData(
    token,
    userId,
    cer_id,
    cohort_id,
    method_id,
    method_type,
    material_type,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          user_id: userId,
          certi_id: cer_id,
          cohort_id: cohort_id,
          method_id: method_id,
          method_type: method_type,
          material_type: material_type,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      if (method_type == 'hybrid') {
        var url = this.baseurl + 'points/hybrid_schedules_test';
      } else {
        var url = this.baseurl + 'points/schedules_test';
      }
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve(err);
        },
      );
    });
  }

  async SelfschedulesData(token, method_id, method_type, user_id, cohort_id) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          method_id: method_id,
          method_type: method_type,
          user_id: user_id,
          cohort_id: cohort_id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + 'points/selfpaced_schedules_test';
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve(err);
        },
      );
    });
  }
  async getSelfSchedule(userId, cer_id, cohort_id, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          user_id: userId,
          certi_id: cer_id,
          cohort_id: cohort_id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + environment.GET_SELF_SCHEDULE;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve(err);
        },
      );
    });
  }
  async generateSelfSchedule(
    id,
    IsSelfSchedule,
    userId: number,
    cer_id: number,
    exam_date: string,
    sun: number,
    mon: number,
    tues: number,
    wed: number,
    thurs: number,
    fri: number,
    sat: number,
    hsun: number,
    hmon: number,
    htues: number,
    hwed: number,
    hthurs: number,
    hfri: number,
    hsat: number,
    balance: number,
    sprint: number,
    insane: number,
    cohort_id: string,
    study_date: string,
    token: string,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          user_id: userId,
          certi_id: cer_id,
          exam_date: exam_date,
          sun: sun,
          mon: mon,
          tues: tues,
          wed: wed,
          thurs: thurs,
          fri: fri,
          sat: sat,
          hsun: hsun,
          hmon: hmon,
          htues: htues,
          hwed: hwed,
          hthurs: hthurs,
          hfri: hfri,
          hsat: hsat,
          cohort_id: cohort_id,
          study_date: study_date,
          btn_insane: insane,
          btn_sprint: sprint,
          btn_balance: balance,
        }),
      ),
    };
    if (IsSelfSchedule) data['id'] = id;
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + environment.SAVE_SELF_SCHEDULE;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve(err);
        },
      );
    });
  }

  async updateexamdate(UpdateExamdate, token) {
    var data = {
      data: this.crypto.encrypt(JSON.stringify(UpdateExamdate)),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + environment.UPDATE_EXAMDATE;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve(err);
        },
      );
    });
  }
  async getExpiryDate(userId, course_type, cohort_id, method_id, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          user_id: userId,
          course_type: course_type,
          cohort_id: cohort_id,
          method_id: method_id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + environment.GET_EXPIRYDATE;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve(err);
        },
      );
    });
  }

  async BaselineData(Baseline, token) {
    var data = {
      data: this.crypto.encrypt(JSON.stringify(Baseline)),
    };

    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + environment.GET_BASELINE_KA;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve(err);
        },
      );
    });
  }
  async SaveSelfpacedUserData(SelfpacedUserData, token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + environment.SAVESELFPACED_USER_SCHEDULE;
      this.http.post(url, SelfpacedUserData, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve(err);
        },
      );
    });
  }
  async SaveSelfpacedVideoData(SelfPacedUservideo, token) {
    var data = {
      data: this.crypto.encrypt(JSON.stringify(SelfPacedUservideo)),
    };

    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + environment.SelfPaced_video_Data;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve(err);
        },
      );
    });
  }
  async downloadMaterial(material_url: string, material_name: string = '') {
    function sanitizeFileName(fileName) {
      const invalidChars = /[^a-zA-Z0-9_\-]/g;
      let sanitized = fileName.replace(invalidChars, '_');
      sanitized = sanitized.replace(/^_+|_+$/g, '');
      return sanitized || 'unnamed_file';
    }

    var xhr = new XMLHttpRequest();
    xhr.open('GET', material_url, true);
    xhr.responseType = 'blob';
    xhr.onload = function () {
      var urlCreator = window.URL || window.webkitURL;
      var imageUrl = urlCreator.createObjectURL(this.response);
      var tag = document.createElement('a');
      tag.href = imageUrl;
      tag.download = sanitizeFileName(material_name);
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
    };
    xhr.send();
  }
}
