import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServicesService } from '../../../services.service';
import { AuthserviceService } from '../../../authservice.service';
import { TakesurveyService } from '../../../../app/services/takesurvey/takesurvey.service';

@Component({
  selector: 'app-friendcolleague',
  templateUrl: './friendcolleague.component.html',
  styleUrls: ['./friendcolleague.component.scss'],
})
export class FriendcolleagueComponent implements OnInit {
  SelectedButtonValue: any;
  InputArray = [
    { id: 1, checked: false },
    { id: 2, checked: false },
    { id: 3, checked: false },
    { id: 4, checked: false },
    { id: 5, checked: false },
    { id: 6, checked: false },
    { id: 7, checked: false },
    { id: 8, checked: false },
    { id: 9, checked: false },
    { id: 10, checked: false },
  ];

  constructor(
    public TakesurveyService: TakesurveyService,
    public ActiveRoute: ActivatedRoute,
    public service: ServicesService,
    public auth: AuthserviceService,
    public spinner: NgxSpinnerService,
    public router: Router,
  ) {}

  ngOnInit(): void {
    this.CheckFriendColleagueValue();
  }

  radioChange(item) {
    this.InputArray.forEach((element) => {
      element.checked = false;
    });
    this.SelectedButtonValue = item.id;
    item.checked = true;
  }

  checkValidation() {
    let Exit = this.InputArray.filter((x) => x.checked);
    if (Exit.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  async UpdateFriendColleagueValue() {
    this.InputArray.forEach((element) => {
      if (element.checked) {
        this.SelectedButtonValue = element.id;
      }
    });
    try {
      await this.spinner.show();
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            await this.spinner.show();
            var token = session.getIdToken().getJwtToken();
            var cohort_id = localStorage.getItem('cohort_id');
            var user_id = localStorage.getItem('CisspUserId');
            let data = {
              user_id: user_id,
              cohort_id: cohort_id,
              friend_colleague: this.SelectedButtonValue,
            };
            await this.TakesurveyService.UpdateSurveyActivity(data, token).then(
              async (data) => {
                if (data[0].status == 200) {
                  var list = JSON.parse(<string>data[0].json);
                  await this.router.navigate(['/surveythankyou']);
                }
                await this.spinner.hide();
              },
            );
          }
        });
    } catch (e) {
      await this.spinner.hide();
      this.service.ShowError(e.meassage);
    }
  }

  async CheckFriendColleagueValue() {
    try {
      await this.spinner.show();
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            await this.spinner.show();
            var token = session.getIdToken().getJwtToken();
            var cohort_id = localStorage.getItem('cohort_id');
            var user_id = localStorage.getItem('CisspUserId');
            await this.TakesurveyService.GetSurveyActivity(
              user_id,
              cohort_id,
              token,
            ).then(async (data) => {
              if (data[0].status == 200) {
                var list = JSON.parse(<string>data[0].json);
                this.SelectedButtonValue = list.data[0].friend_colleague;
                this.InputArray.forEach((element) => {
                  if (element.id == this.SelectedButtonValue) {
                    element.checked = true;
                  }
                });
              }
              await this.spinner.hide();
            });
          }
        });
    } catch (e) {
      await this.spinner.hide();
      this.service.ShowError(e.meassage);
    }
  }
}
