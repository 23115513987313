import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthserviceService } from '../authservice.service';
import { CryptoService } from '../services/crypto/crypto.service';
@Component({
  selector: 'app-certificate',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.scss'],
})
export class CertificateComponent implements OnInit {
  certificateDetails: any;
  expire_Date: any;
  cohort_name: any;
  constructor(
    public ActiveRoute: ActivatedRoute,
    public spinner: NgxSpinnerService,
    public router: Router,
    public auth: AuthserviceService,
    private crypto: CryptoService,
  ) {
    try {
      this.auth.getAuthenticatedUser().getSession((err, session) => {
        if (err) {
          this.spinner.hide();
          this.router.navigate(['/login']);
        } else {
          this.ActiveRoute.params.subscribe(async (params) => {
            try {
              this.spinner.hide();
              this.certificateDetails = JSON.parse(
                this.crypto.dec(params['certificate']),
              ).name
                ? JSON.parse(this.crypto.dec(params['certificate'])).name
                : '';
              this.expire_Date = JSON.parse(
                this.crypto.dec(params['certificate']),
              ).expire_Date
                ? JSON.parse(this.crypto.dec(params['certificate'])).expire_Date
                : '';
              this.cohort_name = JSON.parse(
                this.crypto.dec(params['certificate']),
              ).cohort_name
                ? JSON.parse(this.crypto.dec(params['certificate'])).cohort_name
                : '';
            } catch (error) {
              this.spinner.hide();
            }
          });
        }
      });
    } catch (error) {
      this.router.navigate(['/login']);
    }
  }
  ngOnInit(): void {}
}
