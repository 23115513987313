import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServicesService } from '../../../services.service';
import { AuthserviceService } from '../../../authservice.service';
import { TakesurveyService } from '../../../../app/services/takesurvey/takesurvey.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-feelaboutclass',
  templateUrl: './feelaboutclass.component.html',
  styleUrls: ['./feelaboutclass.component.scss'],
})
export class FeelaboutclassComponent implements OnInit {
  SelectedButtonValue: any;
  InputArray = [
    { id: 1, Name: 'Way too slow', checked: false },
    { id: 2, Name: 'Too slow', checked: false },
    { id: 3, Name: 'About the right pace', checked: false },
    { id: 4, Name: 'Too fast', checked: false },
    { id: 5, Name: 'Way to fast', checked: false },
  ];

  constructor(
    public TakesurveyService: TakesurveyService,
    public ActiveRoute: ActivatedRoute,
    public service: ServicesService,
    public auth: AuthserviceService,
    public spinner: NgxSpinnerService,
    public router: Router,
    private location: Location,
  ) {}

  ngOnInit(): void {
    this.CheckFeelAboutValue();
  }

  radioChange(item) {
    this.InputArray.forEach((element) => {
      element.checked = false;
    });
    this.SelectedButtonValue = item.Name;
    item.checked = true;
  }

  checkValidation() {
    let Exit = this.InputArray.filter((x) => x.checked);
    if (Exit.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  async UpdateFeelAboutValue() {
    this.InputArray.forEach((element) => {
      if (element.checked) {
        this.SelectedButtonValue = element.Name;
      }
    });
    try {
      await this.spinner.show();
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            await this.spinner.show();
            var token = session.getIdToken().getJwtToken();
            var cohort_id = localStorage.getItem('cohort_id');
            var user_id = localStorage.getItem('CisspUserId');
            let data = {
              user_id: user_id,
              cohort_id: cohort_id,
              about_class: this.SelectedButtonValue,
            };
            await this.TakesurveyService.UpdateSurveyActivity(data, token).then(
              async (data) => {
                if (data[0].status == 200) {
                  var list = JSON.parse(<string>data[0].json);
                  await this.router.navigate(['/prepareexam']);
                }
                await this.spinner.hide();
              },
            );
          }
        });
    } catch (e) {
      await this.spinner.hide();
      this.service.ShowError(e.meassage);
    }
  }

  async CheckFeelAboutValue() {
    try {
      await this.spinner.show();
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            await this.spinner.show();
            var token = session.getIdToken().getJwtToken();
            var cohort_id = localStorage.getItem('cohort_id');
            var user_id = localStorage.getItem('CisspUserId');
            await this.TakesurveyService.GetSurveyActivity(
              user_id,
              cohort_id,
              token,
            ).then(async (data) => {
              if (data[0].status == 200) {
                var list = JSON.parse(<string>data[0].json);
                this.SelectedButtonValue = list.data[0].about_class;
                this.InputArray.forEach((element) => {
                  if (element.Name == this.SelectedButtonValue) {
                    element.checked = true;
                  }
                });
              }
              await this.spinner.hide();
            });
          }
        });
    } catch (e) {
      await this.spinner.hide();
      this.service.ShowError(e.meassage);
    }
  }
  back() {
    this.location.back();
  }
}
