import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServicesService } from '../services.service';
import { AuthserviceService } from '../authservice.service';
import { LoginflowService } from '../data/loginflow.service';
import { CryptoService } from '../services/crypto/crypto.service';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.scss'],
})
export class ForgotpasswordComponent implements OnInit {
  public AuthForm: FormGroup;
  allow_access: any;
  email: any;
  constructor(
    public service: ServicesService,
    public auth: AuthserviceService,
    public spinner: NgxSpinnerService,
    public fb: FormBuilder,
    public router: Router,
    public LoginflowService: LoginflowService,
    private crypto: CryptoService,
  ) {}

  ngOnInit() {
    this.AuthForm = this.fb.group({
      email: ['', Validators.required],
    });
  }

  async forgotPassword() {
    let pattern = new RegExp(
      '^[_A-Za-z0-9-]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9-]+)*((\\.[A-Za-z]{2,6})$)',
    );
    if (pattern.test(this.email)) {
      this.spinner.show();
      await this.LoginflowService.versions_getUserByEmail(this.email).then(
        async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            if (list.data.length > 0) {
              this.allow_access = list.data[0].allow_access
                ? list.data[0].allow_access
                : 0;
              if (this.allow_access == 0 || list.data[0].roll_id != 3) {
                this.service.ShowError(
                  'You are not allowed to access this portal. Kindly contact customer support.',
                );
                this.spinner.hide();
                return;
              }
              let expire = false;
              if (list.data[0].expire_password == 1) {
                await this.auth
                  .GetUserRequest(this.email)
                  .then(async (data) => {
                    if (data[0].StatusCode == 0) {
                      expire = true;
                    } else {
                      if (
                        data[0].Data.UserStatus &&
                        data[0].Data.UserStatus == 'CONFIRMED'
                      ) {
                        var updateOnboardingUser = {
                          email: this.email,
                          expire_password: '0',
                        };
                        await this.LoginflowService.updateUserByEmail(
                          updateOnboardingUser,
                        );
                      } else {
                        expire = true;
                      }
                    }
                  });
              }
              if (expire) {
                var userdata = {
                  email: list.data[0].email,
                };
                var datas = this.crypto.enc(JSON.stringify(userdata));
                this.router.navigate([`/pages/forcechangepassword/${datas}`]);
                return;
              }
            }
            if (list.data.length > 0) {
              await this.auth
                .forgotPassword(list.data[0].email)
                .then(async (data) => {
                  if (data[0].StatusCode == 1) {
                    let user = this.email;
                    let msg = data[0].message;
                    let cohort = 'Reset Password';
                    let email_type = 'Reset Password';
                    await this.addFailedEmail(user, cohort, msg, email_type, 1);
                    await this.addActivityTracker(
                      0,
                      this.email,
                      0,
                      'Forgot Password',
                      'Forgot Password - Forgot password code sent successfully',
                    );
                    this.spinner.hide();
                    var datas = btoa(JSON.stringify(this.email));
                    datas = encodeURIComponent(datas);
                    this.router.navigate([`/resetpassauth/${datas}`]);
                  } else {
                    if (
                      data[0].message ==
                      'Username/client id combination not found.'
                    ) {
                      let user = this.email;
                      let msg = 'No user found';
                      let cohort = 'Reset Password';
                      let email_type = 'Reset Password';
                      await this.addFailedEmail(
                        user,
                        cohort,
                        msg,
                        email_type,
                        0,
                      );
                      this.spinner.hide();

                      this.service.ShowError('No user found');
                    } else if (
                      data[0].message ==
                      'User password cannot be reset in the current state.'
                    ) {
                      let user = this.email;
                      let msg =
                        'You have not completed the onboarding flow. Please check email for temp password.';
                      let cohort = 'Reset Password';
                      let email_type = 'Reset Password';
                      await this.addFailedEmail(
                        user,
                        cohort,
                        msg,
                        email_type,
                        0,
                      );
                      await this.addActivityTracker(
                        0,
                        this.email,
                        0,
                        'Forgot Password',
                        'Forgot Password - ' + msg,
                      );
                      this.spinner.hide();

                      this.service.ShowError(
                        'You have not completed the onboarding flow. Please check email for temp password.',
                      );
                    } else {
                      let user = this.email;
                      let msg = data[0].message;
                      let cohort = 'Reset Password failed';
                      let email_type = 'Reset Password';
                      await this.addFailedEmail(
                        user,
                        cohort,
                        msg,
                        email_type,
                        0,
                      );
                      await this.addActivityTracker(
                        0,
                        this.email,
                        0,
                        'Forgot Password',
                        'Forgot Password - ' + msg,
                      );
                      this.spinner.hide();

                      this.service.ShowError(data[0].message);
                    }
                  }
                });
            } else {
              this.spinner.hide();
              this.service.ShowError('No user found');
            }
          }
        },
      );
    } else {
      this.service.ShowError('email provided is invalid');
    }
  }

  async addFailedEmail(user, cohort, msg, email_type, mail_success) {
    await this.service
      .addFailedEmail(user, cohort, msg, email_type, mail_success, '')
      .then(async (data) => {});
  }
  async addActivityTracker(user_id, email, cohort_id, type, message) {
    await this.service.addActivityTracker(
      user_id,
      email,
      cohort_id,
      type,
      message,
    );
  }
}
