import { Injectable } from '@angular/core';
import * as crypto from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class CryptoService {
  secretKey = 'bS5GY5cpzZn!JL8gjD!F^Eah91tTJZ!L';

  constructor() {}

  encrypt(text: string) {
    return crypto.AES.encrypt(text, this.secretKey).toString();
  }

  enc(plainText) {
    var b64 = crypto.AES.encrypt(
      plainText.toString(),
      this.secretKey,
    ).toString();
    var e64 = crypto.enc.Base64.parse(b64);
    var eHex = e64.toString(crypto.enc.Hex);
    return eHex;
  }

  dec(cipherText) {
    var reb64 = crypto.enc.Hex.parse(cipherText);
    var bytes = reb64.toString(crypto.enc.Base64);
    var decrypt = crypto.AES.decrypt(bytes, this.secretKey);
    var plain = decrypt.toString(crypto.enc.Utf8);
    return plain;
  }
}
