import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Http, Headers, RequestOptions } from '@angular/http';

import { environment } from 'src/environments/environment';

import { ToastrService } from 'ngx-toastr';
import { CryptoService } from '../services/crypto/crypto.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class LoginflowService {
  baseurl = environment.baseurl;
  private loginUserData: any = {};
  constructor(
    public http: Http,
    public HttpClient: HttpClient,
    private toastr: ToastrService,
    private crypto: CryptoService,
    private router: Router,
  ) {}

  loginold(user) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      let options = new RequestOptions({ headers: headers });
      this.http
        .post(
          'https://teach.dstncert.com/flashcard.php?task=login',
          user,
          options,
        )
        .subscribe(
          (res) => {
            resolve([{ status: res.status, json: res['_body'] }]);
          },
          (err) => {
            resolve([{ status: err.status, json: err['_body'] }]);
          },
        );
    });
  }
  async UpdateUseremail(email, pass_rest_code, password) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          email: email,
          pass_rest_code: pass_rest_code,
          password: password,
        }),
      ),
    };
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return new Promise((resolve) => {
      this.http
        .post(this.baseurl + environment.USER_UPDATE_BY_EMAIL, data, options)
        .subscribe(
          (res) => {
            resolve([{ data: res, StatusCode: 1, status: res.status }]);
          },
          (err) => {
            resolve([{ data: err, StatusCode: 0, status: err.status }]);
          },
        );
    });
  }
  async UsersFilter(email, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          email: email,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + environment.USER_FILTER;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  public GetIpAddress() {
    let url = 'http://api.ipify.org/?format=json';
    return new Promise((resolve, reject) => {
      this.http.get(url, {}).subscribe(
        (data) => {
          resolve(data['_body']);
        },
        (err) => {
          resolve(err['_body']);
        },
      );
    });
  }
  async loginlog(user_id, ipaddress, browser, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          user_id: user_id,
          ipaddress: ipaddress,
          browser: browser,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + environment.LOGINLOGS_ADD;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  versions_getUserById(id) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          id: id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + environment.VERSIONS_GETUSER_BYID;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  loginLogsBy(user_id) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          user_id: user_id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + 'loginlogs/by';
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }

  versions_getUserByEmail(email) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          email: email,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + environment.VERSIONS_GETUSER_BYEMAIL;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  fetch_user_payment(email) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          email: email,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + environment.FETCH_PAY;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async UpdateTempemail(email, password) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          email: email,
          password: password,
          is_temp: '0',
        }),
      ),
    };
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return new Promise((resolve) => {
      this.http
        .post(this.baseurl + environment.USER_UPDATE_BY_EMAIL, data, options)
        .subscribe(
          (res) => {
            resolve([{ data: res, StatusCode: 1, status: res.status }]);
          },
          (err) => {
            resolve([{ data: err, StatusCode: 0, status: err.status }]);
          },
        );
    });
  }
  async UpdateTempallow_access(email, allow_access) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          email: email,
          allow_access: allow_access,
        }),
      ),
    };
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return new Promise((resolve) => {
      this.http
        .post(this.baseurl + environment.USER_UPDATE_BY_EMAIL, data, options)
        .subscribe(
          (res) => {
            resolve([{ data: res, StatusCode: 1, status: res.status }]);
          },
          (err) => {
            resolve([{ data: err, StatusCode: 0, status: err.status }]);
          },
        );
    });
  }
  async UpdateUsernameEmail(email, name) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          email: email,
          aliasname: name,
        }),
      ),
    };
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return new Promise((resolve) => {
      this.http
        .post(this.baseurl + environment.USER_UPDATE_BY_EMAIL, data, options)
        .subscribe(
          (res) => {
            resolve([
              { data: res['_body'], StatusCode: 1, status: res.status },
            ]);
          },
          (err) => {
            resolve([
              { data: err['_body'], StatusCode: 0, status: err.status },
            ]);
          },
        );
    });
  }
  async UpdatedefaultCohortEmail(email, cohort_id) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          email: email,
          course_id: cohort_id,
        }),
      ),
    };
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return new Promise((resolve) => {
      this.http
        .post(this.baseurl + environment.USER_UPDATE_BY_EMAIL, data, options)
        .subscribe(
          (res) => {
            resolve([
              { data: res['_body'], StatusCode: 1, status: res.status },
            ]);
          },
          (err) => {
            resolve([
              { data: err['_body'], StatusCode: 0, status: err.status },
            ]);
          },
        );
    });
  }
  async UpdateUserTimeZoneEmail(email, time_zone) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          email: email,
          user_time_zone: time_zone,
        }),
      ),
    };
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return new Promise((resolve) => {
      this.http
        .post(this.baseurl + environment.USER_UPDATE_BY_EMAIL, data, options)
        .subscribe(
          (res) => {
            resolve([
              { data: res['_body'], StatusCode: 1, status: res.status },
            ]);
          },
          (err) => {
            resolve([
              { data: err['_body'], StatusCode: 0, status: err.status },
            ]);
          },
        );
    });
  }
  async addUser(
    name,
    email,
    roll_id,
    password,
    permission: number,
    pass_rest_code,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          name: name,
          email: email,
          roll_id: roll_id,
          password: password,
          permission: permission,
          email_verified_at: false,
          pass_rest_code: pass_rest_code,
        }),
      ),
    };

    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + environment.USER_ADD;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async ProfileUpdated(avatar_url, id) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          avatar_url: avatar_url,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + environment.USER + this.crypto.enc(id);
      this.http.put(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async UpdateUserOptIn(opt_in, id) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          opt_in: opt_in,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + environment.USER + this.crypto.enc(id);
      this.http.put(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async authqrcode() {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          qr_size: 300,
        }),
      ),
    };
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.append('X-Authy-API-Key', 'KXfz5wj1EYmUE23ouF9u2AyXalGJt5SQ');
    let options = new RequestOptions({ headers: headers });
    var url = 'https://api.authy.com/protected/json/users/311247545/secret';
    return new Promise((resolve) => {
      this.http.post(url, data, { headers: headers }).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }

  secret_qrcode(email) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          email: email,
        }),
      ),
    };
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    let options = new RequestOptions({ headers: headers });

    return new Promise((resolve) => {
      this.http
        .post(this.baseurl + environment.AUTHY_SECRET, data, options)
        .subscribe(
          (res) => {
            resolve([{ status: res.status, json: res['_body'] }]);
          },
          (err) => {
            resolve([{ status: err.status, json: err['_body'] }]);
          },
        );
    });
  }
  verify(code, email) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          code: code,
          email: email,
        }),
      ),
    };
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return new Promise((resolve) => {
      this.http
        .post(this.baseurl + environment.AUTHY_VERIFY, data, options)
        .subscribe(
          (res) => {
            resolve([{ status: res.status, json: res['_body'] }]);
          },
          (err) => {
            resolve([{ status: err.status, json: err['_body'] }]);
          },
        );
    });
  }
  async updateUser(id, name, Nickname, schedule_date, token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + environment.USERUPDATE_ID + this.crypto.enc(id);
      var data = {
        data: this.crypto.encrypt(
          JSON.stringify({
            name: name,
            aliasname: Nickname,
            schedule_date: schedule_date,
          }),
        ),
      };
      this.http.put(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  updateUserWelcomeFlow(email, welcome_flow, cohort_id = '') {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          email: email,
          welcome_flow: welcome_flow,
          cohort_id: cohort_id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + 'authy/update_student_welcome_flow';
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  getAllAlias() {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + environment.ALL_ALIASNAME;
      this.http.post(url, {}, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  updateTwoFactTab(id, two_fact_tab) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          id: id,
          two_fact_tab: two_fact_tab,
        }),
      ),
    };
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return new Promise((resolve) => {
      this.http
        .post(this.baseurl + environment.UPDATE_TWO_FACT_TAB, data, options)
        .subscribe(
          (res) => {
            resolve([{ status: res.status, json: res['_body'] }]);
          },
          (err) => {
            resolve([{ status: err.status, json: err['_body'] }]);
          },
        );
    });
  }
  saveAuthyDetails(user_id, email, password, scanned_code, qr_code) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          user_id: user_id,
          email: email,
          authy_token: this.crypto.enc(password),
          scanned_code: scanned_code,
        }),
      ),
      qr_code: qr_code,
    };
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return new Promise((resolve) => {
      this.http
        .post(this.baseurl + environment.ADD_AUTHY, data, options)
        .subscribe(
          (res) => {
            resolve([{ status: res.status, json: res['_body'] }]);
          },
          (err) => {
            resolve([{ status: err.status, json: err['_body'] }]);
          },
        );
    });
  }
  generateSelfSchedule(
    id,
    IsSelfSchedule,
    userId: number,
    cer_id: number,
    exam_date: string,
    sun: number,
    mon: number,
    tues: number,
    wed: number,
    thurs: number,
    fri: number,
    sat: number,
    hsun: number,
    hmon: number,
    htues: number,
    hwed: number,
    hthurs: number,
    hfri: number,
    hsat: number,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          user_id: userId,
          certi_id: cer_id,
          exam_date: exam_date,
          sun: sun,
          mon: mon,
          tues: tues,
          wed: wed,
          thurs: thurs,
          fri: fri,
          sat: sat,
          hsun: hsun,
          hmon: hmon,
          htues: htues,
          hwed: hwed,
          hthurs: hthurs,
          hfri: hfri,
          hsat: hsat,
        }),
      ),
    };
    if (IsSelfSchedule) data['id'] = id;
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + environment.SAVE_SELF_SCHEDULE_WELCOMEFLOW;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve(err);
        },
      );
    });
  }
  async saveExamDate(user_id, cer_id, cohort_id, exam_date, token: string) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          user_id: user_id,
          certi_id: cer_id,
          exam_date: exam_date,
          cohort_id: cohort_id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + environment.SAVE_SELF_SCHEDULE;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve(err);
        },
      );
    });
  }
  get_expirydate_onboarding(get_expirydate) {
    var data = {
      data: this.crypto.encrypt(JSON.stringify(get_expirydate)),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + 'authy/get_expirydate_onboarding';
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }

  add_onboarding_flow(onboardingflowdata) {
    var data = {
      data: this.crypto.encrypt(JSON.stringify(onboardingflowdata)),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + environment.ADD_ONBORDING;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }

  get_onboarding_flow(getonboardingflowdata) {
    var data = {
      data: this.crypto.encrypt(JSON.stringify(getonboardingflowdata)),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + environment.GET_ONBORDING;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }

  update_onboarding_flow(updateonboardingflowdata) {
    var data = {
      data: this.crypto.encrypt(JSON.stringify(updateonboardingflowdata)),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + environment.UPDATE_ONBORDING;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }

  updateUserByEmail(updateUserByEmaildata) {
    var data = {
      data: this.crypto.encrypt(JSON.stringify(updateUserByEmaildata)),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + environment.USER_UPDATE_BY_EMAIL;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  overdue_notification_stop(overdue_notification_data, token) {
    var data = {
      data: this.crypto.encrypt(JSON.stringify(overdue_notification_data)),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + environment.OVERDUE_NOTIFICATION_STOP;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }

  setLoginUserData(
    userData: string,
    email: string,
    courseData: { cohort_id: number; certi_id: number },
  ) {
    this.loginUserData = { userData, email, courseData };
  }

  getLoginUserData(): any {
    const loginUserData = this.loginUserData;
    this.clearLoginUserData();
    return loginUserData;
  }

  clearLoginUserData() {
    this.loginUserData = {};
  }
}
