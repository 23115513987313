<div class="logo-div">
  <img src="../../../assets/images/sidebar-logo.png" alt="Logo" />
</div>
<div class="survey-password servey-center">
  <h4 style="text-align: center">
    Please enter the <span style="color: #d7779b">activation code</span> we just
    emailed you
  </h4>
  <div class="form" [formGroup]="LoginForm">
    <div class="input-form mb-20">
      <input
        formControlName="oldpassword"
        type="password"
        [(ngModel)]="oldpassword"
        class="input"
        placeholder="Activation code"
        required
      />
      <span class="svglock">
        <svg
          width="14"
          height="21"
          viewBox="0 0 14 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7 20.0311C3.13409 20.0311 0 16.9477 0 13.1452C0 11.6761 0.476 10.321 1.27273 9.2037V5.19335C1.27273 2.32534 3.83664 0 7 0C10.1634 0 12.7273 2.32534 12.7273 5.19335V9.20307C13.524 10.321 14 11.6761 14 13.1452C14 16.9477 10.8659 20.0311 7 20.0311ZM11.4545 5.17144C11.4545 3.00697 9.46018 1.25186 7 1.25186C4.53982 1.25186 2.54545 3.00697 2.54545 5.17144V7.87484C3.759 6.88149 5.29836 6.25994 7 6.25994C8.70227 6.25994 10.241 6.88149 11.4545 7.87422V5.17144ZM7 7.5118C3.83727 7.5118 1.27273 10.0337 1.27273 13.1452C1.27273 16.2567 3.83664 18.7786 7 18.7786C10.1634 18.7786 12.7273 16.2567 12.7273 13.1452C12.7273 10.0337 10.1627 7.5118 7 7.5118ZM7 15.0224C6.64873 15.0224 6.36364 14.7426 6.36364 14.397V11.8939C6.36364 11.5478 6.64809 11.268 7 11.268C7.35191 11.268 7.63636 11.5478 7.63636 11.8939V14.397C7.63636 14.7426 7.35127 15.0224 7 15.0224Z"
            fill="#7066AB"
          />
        </svg>
      </span>
    </div>
    <h4 style="text-align: center">
      Protect, your account with something you know, by creating a
      <span style="color: #d7779b">password</span>.
    </h4>
    <div class="input-form mb-20">
      <input
        formControlName="password"
        type="password"
        (keyup)="PasswordCheck()"
        [(ngModel)]="password"
        class="input"
        placeholder="New Password"
        required
      />
      <span class="svglock">
        <svg
          width="14"
          height="21"
          viewBox="0 0 14 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7 20.0311C3.13409 20.0311 0 16.9477 0 13.1452C0 11.6761 0.476 10.321 1.27273 9.2037V5.19335C1.27273 2.32534 3.83664 0 7 0C10.1634 0 12.7273 2.32534 12.7273 5.19335V9.20307C13.524 10.321 14 11.6761 14 13.1452C14 16.9477 10.8659 20.0311 7 20.0311ZM11.4545 5.17144C11.4545 3.00697 9.46018 1.25186 7 1.25186C4.53982 1.25186 2.54545 3.00697 2.54545 5.17144V7.87484C3.759 6.88149 5.29836 6.25994 7 6.25994C8.70227 6.25994 10.241 6.88149 11.4545 7.87422V5.17144ZM7 7.5118C3.83727 7.5118 1.27273 10.0337 1.27273 13.1452C1.27273 16.2567 3.83664 18.7786 7 18.7786C10.1634 18.7786 12.7273 16.2567 12.7273 13.1452C12.7273 10.0337 10.1627 7.5118 7 7.5118ZM7 15.0224C6.64873 15.0224 6.36364 14.7426 6.36364 14.397V11.8939C6.36364 11.5478 6.64809 11.268 7 11.268C7.35191 11.268 7.63636 11.5478 7.63636 11.8939V14.397C7.63636 14.7426 7.35127 15.0224 7 15.0224Z"
            fill="#7066AB"
          />
        </svg>
      </span>
    </div>
    <div class="input-form mb-10">
      <input
        name="Confirmpass"
        [type]="changepasstype"
        formControlName="Confirmpass"
        [(ngModel)]="Confirmpass"
        class="input"
        placeholder="Confirm New Password"
        required
      />
      <span
        class="material-icons"
        *ngIf="changepasstype == 'password'"
        style="cursor: pointer"
        (click)="showpasschek('text')"
      >
        visibility_off
      </span>
      <span
        class="material-icons"
        *ngIf="changepasstype == 'text'"
        style="cursor: pointer"
        (click)="showpasschek('password')"
      >
        visibility
      </span>
    </div>
    <div class="strength">
      <div class="strengthpro" *ngIf="showCriteria">
        <p>
          Strength: <span>{{ PassMsg }}</span>
        </p>
        <div class="progress">
          <div class="filler" [ngStyle]="{ 'width.%': PassWidth }"></div>
        </div>
        <div class="stronginfo">
          <p>Please use at least:</p>
          <ul>
            <li>
              <span class="svglock" *ngIf="!PassLength">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.2"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9 0C13.9706 0 18 4.02944 18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0ZM9 1.3C4.74741 1.3 1.3 4.74741 1.3 9C1.3 13.2526 4.74741 16.7 9 16.7C13.2526 16.7 16.7 13.2526 16.7 9C16.7 4.74741 13.2526 1.3 9 1.3ZM4.98231 8.5842C5.2541 8.30833 5.69806 8.30502 5.97393 8.57681L8.02702 10.5689L12.316 6.19627C12.5908 5.9234 13.0348 5.92496 13.3076 6.19976C13.5805 6.47456 13.579 6.91854 13.3042 7.19141L8.52303 12.0527C8.25036 12.3235 7.81057 12.3243 7.53684 12.0546L4.9897 9.57582C4.71383 9.30403 4.71052 8.86007 4.98231 8.5842Z"
                    fill="white"
                  />
                </svg>
              </span>
              <span class="svglock" *ngIf="PassLength">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9 0C13.9706 0 18 4.02944 18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0ZM9 1.3C4.74741 1.3 1.3 4.74741 1.3 9C1.3 13.2526 4.74741 16.7 9 16.7C13.2526 16.7 16.7 13.2526 16.7 9C16.7 4.74741 13.2526 1.3 9 1.3ZM4.98231 8.5842C5.2541 8.30833 5.69806 8.30502 5.97393 8.57681L8.02702 10.5689L12.316 6.19627C12.5908 5.9234 13.0348 5.92496 13.3076 6.19976C13.5805 6.47456 13.579 6.91854 13.3042 7.19141L8.52303 12.0527C8.25036 12.3235 7.81057 12.3243 7.53684 12.0546L4.9897 9.57582C4.71383 9.30403 4.71052 8.86007 4.98231 8.5842Z"
                    fill="#944BEB"
                  />
                </svg> </span
              >8 Characters
            </li>

            <li>
              <span class="svglock" *ngIf="!Number">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.2"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9 0C13.9706 0 18 4.02944 18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0ZM9 1.3C4.74741 1.3 1.3 4.74741 1.3 9C1.3 13.2526 4.74741 16.7 9 16.7C13.2526 16.7 16.7 13.2526 16.7 9C16.7 4.74741 13.2526 1.3 9 1.3ZM4.98231 8.5842C5.2541 8.30833 5.69806 8.30502 5.97393 8.57681L8.02702 10.5689L12.316 6.19627C12.5908 5.9234 13.0348 5.92496 13.3076 6.19976C13.5805 6.47456 13.579 6.91854 13.3042 7.19141L8.52303 12.0527C8.25036 12.3235 7.81057 12.3243 7.53684 12.0546L4.9897 9.57582C4.71383 9.30403 4.71052 8.86007 4.98231 8.5842Z"
                    fill="white"
                  />
                </svg>
              </span>
              <span class="svglock" *ngIf="Number">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9 0C13.9706 0 18 4.02944 18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0ZM9 1.3C4.74741 1.3 1.3 4.74741 1.3 9C1.3 13.2526 4.74741 16.7 9 16.7C13.2526 16.7 16.7 13.2526 16.7 9C16.7 4.74741 13.2526 1.3 9 1.3ZM4.98231 8.5842C5.2541 8.30833 5.69806 8.30502 5.97393 8.57681L8.02702 10.5689L12.316 6.19627C12.5908 5.9234 13.0348 5.92496 13.3076 6.19976C13.5805 6.47456 13.579 6.91854 13.3042 7.19141L8.52303 12.0527C8.25036 12.3235 7.81057 12.3243 7.53684 12.0546L4.9897 9.57582C4.71383 9.30403 4.71052 8.86007 4.98231 8.5842Z"
                    fill="#944BEB"
                  />
                </svg>
              </span>
              1 Number
            </li>
            <li>
              <span class="svglock" *ngIf="!IsSpecial">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.2"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9 0C13.9706 0 18 4.02944 18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0ZM9 1.3C4.74741 1.3 1.3 4.74741 1.3 9C1.3 13.2526 4.74741 16.7 9 16.7C13.2526 16.7 16.7 13.2526 16.7 9C16.7 4.74741 13.2526 1.3 9 1.3ZM4.98231 8.5842C5.2541 8.30833 5.69806 8.30502 5.97393 8.57681L8.02702 10.5689L12.316 6.19627C12.5908 5.9234 13.0348 5.92496 13.3076 6.19976C13.5805 6.47456 13.579 6.91854 13.3042 7.19141L8.52303 12.0527C8.25036 12.3235 7.81057 12.3243 7.53684 12.0546L4.9897 9.57582C4.71383 9.30403 4.71052 8.86007 4.98231 8.5842Z"
                    fill="white"
                  />
                </svg>
              </span>
              <span class="svglock" *ngIf="IsSpecial">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9 0C13.9706 0 18 4.02944 18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0ZM9 1.3C4.74741 1.3 1.3 4.74741 1.3 9C1.3 13.2526 4.74741 16.7 9 16.7C13.2526 16.7 16.7 13.2526 16.7 9C16.7 4.74741 13.2526 1.3 9 1.3ZM4.98231 8.5842C5.2541 8.30833 5.69806 8.30502 5.97393 8.57681L8.02702 10.5689L12.316 6.19627C12.5908 5.9234 13.0348 5.92496 13.3076 6.19976C13.5805 6.47456 13.579 6.91854 13.3042 7.19141L8.52303 12.0527C8.25036 12.3235 7.81057 12.3243 7.53684 12.0546L4.9897 9.57582C4.71383 9.30403 4.71052 8.86007 4.98231 8.5842Z"
                    fill="#944BEB"
                  />
                </svg>
              </span>
              1 Special character
            </li>
            <li>
              <span class="svglock" *ngIf="!IsUpper">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.2"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9 0C13.9706 0 18 4.02944 18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0ZM9 1.3C4.74741 1.3 1.3 4.74741 1.3 9C1.3 13.2526 4.74741 16.7 9 16.7C13.2526 16.7 16.7 13.2526 16.7 9C16.7 4.74741 13.2526 1.3 9 1.3ZM4.98231 8.5842C5.2541 8.30833 5.69806 8.30502 5.97393 8.57681L8.02702 10.5689L12.316 6.19627C12.5908 5.9234 13.0348 5.92496 13.3076 6.19976C13.5805 6.47456 13.579 6.91854 13.3042 7.19141L8.52303 12.0527C8.25036 12.3235 7.81057 12.3243 7.53684 12.0546L4.9897 9.57582C4.71383 9.30403 4.71052 8.86007 4.98231 8.5842Z"
                    fill="white"
                  />
                </svg>
              </span>
              <span class="svglock" *ngIf="IsUpper">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9 0C13.9706 0 18 4.02944 18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0ZM9 1.3C4.74741 1.3 1.3 4.74741 1.3 9C1.3 13.2526 4.74741 16.7 9 16.7C13.2526 16.7 16.7 13.2526 16.7 9C16.7 4.74741 13.2526 1.3 9 1.3ZM4.98231 8.5842C5.2541 8.30833 5.69806 8.30502 5.97393 8.57681L8.02702 10.5689L12.316 6.19627C12.5908 5.9234 13.0348 5.92496 13.3076 6.19976C13.5805 6.47456 13.579 6.91854 13.3042 7.19141L8.52303 12.0527C8.25036 12.3235 7.81057 12.3243 7.53684 12.0546L4.9897 9.57582C4.71383 9.30403 4.71052 8.86007 4.98231 8.5842Z"
                    fill="#944BEB"
                  />
                </svg>
              </span>
              1 Uppercase letter
            </li>
            <li>
              <span class="svglock" *ngIf="!IsSmall">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.2"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9 0C13.9706 0 18 4.02944 18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0ZM9 1.3C4.74741 1.3 1.3 4.74741 1.3 9C1.3 13.2526 4.74741 16.7 9 16.7C13.2526 16.7 16.7 13.2526 16.7 9C16.7 4.74741 13.2526 1.3 9 1.3ZM4.98231 8.5842C5.2541 8.30833 5.69806 8.30502 5.97393 8.57681L8.02702 10.5689L12.316 6.19627C12.5908 5.9234 13.0348 5.92496 13.3076 6.19976C13.5805 6.47456 13.579 6.91854 13.3042 7.19141L8.52303 12.0527C8.25036 12.3235 7.81057 12.3243 7.53684 12.0546L4.9897 9.57582C4.71383 9.30403 4.71052 8.86007 4.98231 8.5842Z"
                    fill="white"
                  />
                </svg>
              </span>
              <span class="svglock" *ngIf="IsSmall">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9 0C13.9706 0 18 4.02944 18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0ZM9 1.3C4.74741 1.3 1.3 4.74741 1.3 9C1.3 13.2526 4.74741 16.7 9 16.7C13.2526 16.7 16.7 13.2526 16.7 9C16.7 4.74741 13.2526 1.3 9 1.3ZM4.98231 8.5842C5.2541 8.30833 5.69806 8.30502 5.97393 8.57681L8.02702 10.5689L12.316 6.19627C12.5908 5.9234 13.0348 5.92496 13.3076 6.19976C13.5805 6.47456 13.579 6.91854 13.3042 7.19141L8.52303 12.0527C8.25036 12.3235 7.81057 12.3243 7.53684 12.0546L4.9897 9.57582C4.71383 9.30403 4.71052 8.86007 4.98231 8.5842Z"
                    fill="#944BEB"
                  />
                </svg>
              </span>
              1 lowercase letter
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="form-button-next">
  <button
    type="button"
    [disabled]="!LoginForm.valid"
    class="frm-next-btn"
    (click)="resetpassword()"
  >
    Continue
  </button>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
