import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServicesService } from '../services.service';
import { AuthserviceService } from '../authservice.service';
import { ActivatedRoute } from '@angular/router';
import { LoginflowService } from '../data/loginflow.service';

@Component({
  selector: 'app-forgotpassold',
  templateUrl: './forgotpassold.component.html',
  styleUrls: ['./forgotpassold.component.scss'],
})
export class ForgotpassoldComponent implements OnInit {
  newpass: any;
  Authcode: any;
  data: any;
  public AuthForm: FormGroup;
  constructor(
    public LoginflowService: LoginflowService,
    public ActiveRoute: ActivatedRoute,
    public service: ServicesService,
    public auth: AuthserviceService,
    public spinner: NgxSpinnerService,
    public fb: FormBuilder,
    public router: Router,
  ) {
    this.ActiveRoute.params.subscribe(async (params) => {
      try {
        this.data = JSON.parse(atob(decodeURIComponent(params['data'])));
      } catch (error) {
        this.router.navigate(['/login']);
      }
    });
  }

  ngOnInit(): void {
    this.AuthForm = this.fb.group({
      newpass: ['', Validators.required],
      Authcode: ['', Validators.required],
    });
  }
  async ConfimCode() {
    await this.spinner.show();
    await this.LoginflowService.versions_getUserByEmail(this.data.email).then(
      async (data) => {
        if (data[0].status == 200) {
          var list = JSON.parse(<string>data[0].json);
          if (list.data.length > 0) {
            this.data.email = list.data[0].email;
            await this.auth
              .forgotPasswordAuthcode(
                this.data.email,
                this.Authcode,
                this.newpass,
              )
              .then(async (data) => {
                if (data[0].StatusCode == 1) {
                  await this.LoginflowService.UpdateUseremail(
                    this.data.email,
                    '0',
                    this.newpass,
                  ).then(async (changepas) => {
                    if (changepas[0].StatusCode == 1) {
                      var userdata = {
                        email: this.data.email,
                        password: this.newpass,
                      };
                      var datas = btoa(JSON.stringify(userdata));
                      datas = encodeURIComponent(datas);
                      await this.spinner.hide();

                      this.router.navigate([`/onetimepsas/${datas}`]);
                    } else {
                      this.spinner.hide();
                      this.service.ShowError(changepas[0].message);
                    }
                  });
                } else {
                  this.spinner.hide();
                  this.service.ShowError(data[0].message);
                }
              });
          } else {
            this.service.ShowError('User not found');
            await this.spinner.hide();
          }
        } else {
          this.service.ShowError(data[0].message);
          await this.spinner.hide();
        }
      },
    );
  }
}
