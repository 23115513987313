<div class="img-center">
  <img src="../../../../assets/images/sidebar-logo.png" alt="" />
</div>
<div class="servey-center step-3 second-last-stage">
  <h4>How likely are you to recommend this class to a friend or colleague?</h4>
  <div class="ow likely">
    <span class="likely-not">Not at all likely</span>
    <span class="likely-extremely">Extremely likely</span>
  </div>
  <div class="option-wrapper">
    <div class="ow" *ngFor="let item of InputArray">
      <input
        type="radio"
        (change)="radioChange(item)"
        name="FriendCollagueButton"
        [checked]="item.checked"
      />
      <label
        ><span>{{ item.id }}</span></label
      >
    </div>
    <div class="ow"></div>
  </div>
</div>
<div class="form-button-next">
  <button type="button" class="frm-prev-btn" [routerLink]="['/trainingclass']">
    <span class="material-icons">arrow_back</span>
  </button>
  <button
    type="button"
    class="frm-next-btn"
    (click)="UpdateFriendColleagueValue()"
    [disabled]="checkValidation()"
  >
    Submit Your Response
  </button>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
