import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

@Injectable({
  providedIn: 'root',
})
export class WebsocketService {
  URL = 'wss://51jkqsbxbe.execute-api.us-east-2.amazonaws.com/prod';

  WebSocket: WebSocket;
  constructor() {}
  Connect() {
    this.WebSocket = new WebSocket(this.URL);
    this.WebSocket.onmessage = function (event) {};
  }
  OnOpen() {
    return new Promise((resolve) => {
      this.WebSocket.onopen = function (event) {
        resolve(event);
      };
    });
  }
  DoSend(Message) {
    this.WebSocket.send(JSON.stringify(Message));
    return new Promise((resolve) => {
      this.WebSocket.onmessage = function (event) {
        resolve(event.data);
      };
    });
  }
  OnMessage(): Observable<any> {
    return new Observable((observer) => {
      this.WebSocket.onmessage = function (event) {
        observer.next(event.data);
      };
    });
  }
}
