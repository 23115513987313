<div class="discord-modal-container">
  <h2 class="title">
    Connect with your fellow students and our <br />
    teachers to discuss and learn on our Discord.
  </h2>

  <div class="description">
    (Please do not share this invite link with anyone.)
    <br />
    Looking forward to seeing you on Discord and learning together!
  </div>

  <div class="open-link-button">
    <button (click)="openDiscordLink()">Join our Discord</button>
  </div>

  <div class="close-button" (click)="closeButton()">x</div>
</div>
