import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthserviceService } from 'src/app/authservice.service';
import { LoginflowService } from 'src/app/data/loginflow.service';
import { ServicesService } from 'src/app/services.service';
import * as momentZone from 'moment-timezone';
import { TZone } from 'moment-timezone-picker';

@Component({
  selector: 'app-timezoneset',
  templateUrl: './timezoneset.component.html',
  styleUrls: ['./timezoneset.component.scss'],
})
export class TimezonesetComponent implements OnInit {
  public LoginForm: FormGroup;
  errorshow = false;
  perfectShow: boolean = false;
  data: any;
  AllAlias = [];
  CurrentName = '';
  time_zone: any;
  allow_access: number = 0;
  constructor(
    public LoginflowService: LoginflowService,
    public ActiveRoute: ActivatedRoute,
    public fb: FormBuilder,
    public auth: AuthserviceService,
    public service: ServicesService,
    public spinner: NgxSpinnerService,
    public router: Router,
  ) {
    this.ActiveRoute.params.subscribe(async (params) => {
      try {
        this.data = JSON.parse(atob(decodeURIComponent(params['data'])));
        await this.LoginflowService.versions_getUserByEmail(
          this.data.email,
        ).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.time_zone = list.data[0].user_time_zone
              ? JSON.parse(list.data[0].user_time_zone)
              : this.formatZone(momentZone.tz.guess(true));
            this.allow_access = list.data[0].allow_access
              ? list.data[0].allow_access
              : 0;
          }
        });
        this.spinner.hide();
      } catch (error) {
        this.router.navigate(['/login']);
      }
    });
  }
  async ngOnInit() {}
  async alisnamechange() {
    if (
      this.time_zone == undefined ||
      this.time_zone == '' ||
      this.time_zone == null
    ) {
      return;
    }
    this.time_zone = this.time_zone;
    if (
      this.time_zone != undefined &&
      this.time_zone != '' &&
      this.time_zone != null
    ) {
      this.spinner.show();
      await this.LoginflowService.UpdateUserTimeZoneEmail(
        this.data.email,
        JSON.stringify(this.time_zone),
      ).then(async (changepas) => {
        if (changepas[0].StatusCode == 1) {
          var list = JSON.parse(<string>changepas[0].data);
          localStorage.setItem(
            'userdetails',
            JSON.stringify(<string>list.data),
          );
          localStorage.setItem('CisspUserId', JSON.stringify(list.data.id));
          this.spinner.hide();
          var userdata = {
            email: this.data.email,
            password: localStorage.getItem('domaindata')
              ? JSON.parse(atob(localStorage.getItem('domaindata')))
              : this.data.password,
            allow_access: this.allow_access,
            cohort_id: this.data.cohort_id,
          };
          var email = btoa(JSON.stringify(userdata));
          email = encodeURIComponent(email);
          let wel_flow =
            list.data.welcome_flow != undefined ||
            list.data.welcome_flow != null
              ? parseInt(list.data.welcome_flow)
              : 0;
          if (wel_flow > 4) {
            if (wel_flow == 2) {
              this.router.navigate([`/alias/${email}`]);
              return;
            } else if (wel_flow == 3) {
              this.router.navigate([`/timezone/${email}`]);
              return;
            } else if (wel_flow == 4) {
              this.router.navigate([`/protectpass/${email}`]);
              return;
            } else if (wel_flow == 5) {
              this.router.navigate([`/onetimepsas/${email}`]);
              return;
            } else if (wel_flow == 6) {
              this.router.navigate([`/userimage/${email}`]);
              return;
            } else if (wel_flow == 7) {
              this.router.navigate([`/selfpacedvideo/${email}`]);
              return;
            } else if (wel_flow == 8) {
              this.router.navigate([`/customschedule/${email}`]);
              return;
            } else if (wel_flow == 9) {
              this.router.navigate([`/competition/${email}`]);
              return;
            } else if (wel_flow == 10) {
              this.router.navigate([`/learning/${email}`]);
              return;
            } else if (wel_flow == 11) {
              this.router.navigate([`/thankyou/${email}`]);
              return;
            }
          }
          await this.LoginflowService.updateUserWelcomeFlow(
            this.data.email,
            '4',
            this.data.cohort_id,
          ).then((data) => {
            var datas = btoa(JSON.stringify(userdata));
            datas = encodeURIComponent(datas);
            this.router.navigate([`/protectpass/${datas}`]);
          });
        } else {
          this.spinner.hide();
          var list = JSON.parse(<string>changepas[0].data);
          if (list.message == 'Validation error') {
            this.errorshow = true;
          } else {
            this.service.ShowError(list.message);
          }
        }
      });
    }
  }
  retry() {
    window.location.reload();
  }
  formatZone(zone: string): TZone {
    const utc: string = momentZone.tz(zone).format('Z');
    const abbr: string = momentZone.tz(zone).zoneAbbr();
    return {
      name: `${zone} (${utc})`,
      nameValue: zone,
      timeValue: utc,
      group: zone.split('/', 1)[0],
      abbr: abbr,
    };
  }
}
