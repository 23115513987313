import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServicesService } from '../../services.service';
import { AuthserviceService } from '../../authservice.service';
import { ActivatedRoute } from '@angular/router';
import { LoginflowService } from '../../data/loginflow.service';

@Component({
  selector: 'app-onetimepass',
  templateUrl: './onetimepass.component.html',
  styleUrls: ['./onetimepass.component.scss'],
})
export class OnetimepassComponent implements OnInit {
  data: any;
  code: any;
  dataqr: any;
  AuthyShow = false;
  elementType: 'url';
  value: any = '';
  public LoginForm: FormGroup;
  devicecode: any;
  newdata: any;
  constructor(
    public LoginflowService: LoginflowService,
    public ActiveRoute: ActivatedRoute,
    public service: ServicesService,
    public auth: AuthserviceService,
    public spinner: NgxSpinnerService,
    public fb: FormBuilder,
    public router: Router,
  ) {
    this.spinner.hide();
    this.ActiveRoute.params.subscribe(async (params) => {
      try {
        this.data = JSON.parse(atob(decodeURIComponent(params['data'])));
      } catch (error) {
        this.router.navigate(['/login']);
      }
    });
  }
  async ngOnInit() {
    this.LoginForm = this.fb.group({
      code: ['', Validators.required],
    });
    try {
      await this.spinner.show();
      var password = window.localStorage.getItem('domaindata');
      if (password) {
        var set = atob(password);
        var passwordset = JSON.parse(<string>set);
      }
      if (passwordset && this.data.email) {
        await this.auth
          .Getqrcode(this.data.email, passwordset)
          .then((dataset) => {
            if (dataset && dataset[0].StatusCode == 1) {
              this.data.password = passwordset;
              this.value = dataset[0].code;
              this.devicecode = dataset[0].devicecode;
              this.newdata = dataset;
              this.spinner.hide();
            } else if (dataset && dataset[0].StatusCode == 2) {
              this.data.password = passwordset;
              let two_fact_tab = '1';
              var userdata = {
                email: this.data.email,
                password: this.data.password,
                cohort_id: this.data.cohort_id,
              };

              let id = localStorage.getItem('CisspUserId');
              this.LoginflowService.updateTwoFactTab(id, two_fact_tab).then(
                (response) => {},
              );
              this.LoginflowService.updateUserWelcomeFlow(
                this.data.email,
                '6',
                this.data.cohort_id,
              ).then((data) => {
                var datas = btoa(JSON.stringify(userdata));
                datas = encodeURIComponent(datas);
                this.router.navigate([`/userimage/${datas}`]);
              });
              this.spinner.hide();
            } else {
              this.router.navigate(['/login']);
              this.spinner.hide();
            }
          });
      } else {
        this.router.navigate(['/login']);
        this.spinner.hide();
      }
    } catch {
      this.router.navigate(['/login']);
      this.spinner.hide();
    }
  }
  async thankyou() {
    if (this.code) {
      if (this.code.trim() && this.code.length == 6) {
        this.spinner.show();
        let id = localStorage.getItem('CisspUserId');
        const base64Img =
          document.getElementsByClassName('qrcodeas')[0].children[0]['src'];
        await this.LoginflowService.saveAuthyDetails(
          id,
          this.data.email,
          this.data.password,
          this.code,
          base64Img,
        ).then((response) => {});
        await this.auth
          .verify(
            this.data.email,
            this.data.password,
            this.code,
            this.newdata[0].data,
          )
          .then(async (set) => {
            if (set[0].StatusCode == 1) {
              await this.auth.ResetMFASetup(this.data.email, '');
              var userdata = {
                email: this.data.email,
                password: this.data.password,
                cohort_id: this.data.cohort_id,
              };
              let two_fact_tab = '1';
              await this.LoginflowService.updateTwoFactTab(
                id,
                two_fact_tab,
              ).then((response) => {});
              await this.LoginflowService.UpdateTempallow_access(
                this.data.email,
                1,
              );
              await this.LoginflowService.updateUserWelcomeFlow(
                this.data.email,
                '6',
                this.data.cohort_id,
              ).then((data) => {
                var datas = btoa(JSON.stringify(userdata));
                datas = encodeURIComponent(datas);
                this.router.navigate([`/userimage/${datas}`]);
              });
              await this.spinner.hide();
            } else {
              await this.spinner.hide();
              this.service.ShowError(
                'You have entered Invalid code. Please try again with valid code.',
              );
            }
          });
        await this.spinner.hide();
      } else {
        await this.spinner.hide();
        this.service.ShowError(
          'Please enter the 6-digit one-time password that has been generated by your app',
        );
      }
    } else {
      this.service.ShowError(
        'Please enter the 6-digit one-time password that has been generated by your app ',
      );
    }
    await this.spinner.hide();
  }
}
