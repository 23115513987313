import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServicesService } from '../../../services.service';
import { AuthserviceService } from '../../../authservice.service';
import { TakesurveyService } from '../../../../app/services/takesurvey/takesurvey.service';

@Component({
  selector: 'app-passexam',
  templateUrl: './passexam.component.html',
  styleUrls: ['./passexam.component.scss'],
})
export class PassexamComponent implements OnInit {
  SelectedButtonValue: any;
  InputArray = [
    { id: 1, type: 'Yes', checked: false },
    { id: 0, type: 'No', checked: false },
  ];
  cohort_name: any;

  constructor(
    public TakesurveyService: TakesurveyService,
    public ActiveRoute: ActivatedRoute,
    public service: ServicesService,
    public auth: AuthserviceService,
    public spinner: NgxSpinnerService,
    public router: Router,
  ) {
    this.ActiveRoute.params.subscribe(async (params) => {
      try {
        var data = JSON.parse(atob(decodeURIComponent(params['data'])));
        localStorage.setItem('cohort_id', data.cohort_id);
        localStorage.setItem('CisspUserId', data.user_id);
        this.cohort_name = data.cohort_name;
      } catch (error) {}
    });
  }

  ngOnInit(): void {
    var setlogin = this.auth.isLoggedIn();
    if (!setlogin) {
      this.router.navigate(['/login']);
    }
    this.CheckPassExamValue();
  }

  radioChange(item) {
    this.InputArray.forEach((element) => {
      element.checked = false;
    });
    this.SelectedButtonValue = item.id;
    item.checked = true;
  }

  checkValidation() {
    let Exit = this.InputArray.filter((x) => x.checked);
    if (Exit.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  async UpdatePassExamValue() {
    this.InputArray.forEach((element) => {
      if (element.checked) {
        this.SelectedButtonValue = element.id;
      }
    });
    try {
      await this.spinner.show();
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            await this.spinner.show();
            var token = session.getIdToken().getJwtToken();
            var cohort_id = localStorage.getItem('cohort_id');
            var user_id = localStorage.getItem('CisspUserId');
            let data = {
              user_id: user_id,
              cohort_id: cohort_id,
              pass_exam: this.SelectedButtonValue,
            };
            await this.TakesurveyService.UpdateSurveyActivity(data, token).then(
              async (data) => {
                if (data[0].status == 200) {
                  var list = JSON.parse(<string>data[0].json);
                  if (list.data.pass_exam == '1') {
                    await this.router.navigate(['/aboutclass']);
                  } else {
                    await this.router.navigate(['/domainresult']);
                  }
                }
                await this.spinner.hide();
              },
            );
          }
        });
    } catch (e) {
      await this.spinner.hide();
      this.service.ShowError(e.meassage);
    }
  }

  async CheckPassExamValue() {
    try {
      await this.spinner.show();
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            await this.spinner.show();
            var token = session.getIdToken().getJwtToken();
            var cohort_id = localStorage.getItem('cohort_id');
            var user_id = localStorage.getItem('CisspUserId');
            await this.TakesurveyService.GetSurveyActivity(
              user_id,
              cohort_id,
              token,
            ).then(async (data) => {
              if (data[0].status == 200) {
                var list = JSON.parse(<string>data[0].json);
                this.SelectedButtonValue = list.data[0].pass_exam;
                this.InputArray.forEach((element) => {
                  if (element.id == this.SelectedButtonValue) {
                    element.checked = true;
                  }
                });
              }
              await this.spinner.hide();
            });
          }
        });
    } catch (e) {
      await this.spinner.hide();
      this.service.ShowError(e.meassage);
    }
  }
}
