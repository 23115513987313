import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthserviceService } from 'src/app/authservice.service';
import { LoginflowService } from 'src/app/data/loginflow.service';
import { ServicesService } from 'src/app/services.service';
import { CourseserviceService } from 'src/app/services/course/courseservice.service';
import { CryptoService } from 'src/app/services/crypto/crypto.service';
import { ConfirmedValidator } from 'src/app/validation';

@Component({
  selector: 'app-forcechangepasswordc',
  templateUrl: './forcechangepasswordc.component.html',
  styleUrls: ['./forcechangepasswordc.component.scss'],
})
export class ForcechangepasswordcComponent implements OnInit {
  public LoginForm: FormGroup;
  password: any;
  Confirmpass: any;
  PassLength: boolean = false;
  strangth = 0;
  IsSmall: boolean = false;
  IsUpper: boolean = false;
  Number: boolean = false;
  IsSpecial: boolean = false;
  Checkpassword: boolean = false;
  flashcardPassword: any;
  PassWidth = 0;
  PassMsg = 'Poor';
  oldpassword: any;
  data: any;
  changepasstype = 'password';
  showCriteria: boolean = false;
  allow_access: number = 0;
  flaseCarduser: boolean = false;
  db_user_id: any;
  list1: any;
  email: any;
  courselist = [];
  constructor(
    public LoginflowService: LoginflowService,
    public ActiveRoute: ActivatedRoute,
    public fb: FormBuilder,
    public auth: AuthserviceService,
    public service: ServicesService,
    public spinner: NgxSpinnerService,
    public router: Router,
    public route: ActivatedRoute,
    public CourseserviceService: CourseserviceService,
    private crypto: CryptoService,
    private cookieService: CookieService,
  ) {
    this.ActiveRoute.params.subscribe(async (params) => {
      try {
        this.data = JSON.parse(
          this.crypto.dec(decodeURIComponent(params['data'])),
        );
        this.data.email = this.data.email;
        await this.LoginflowService.versions_getUserByEmail(
          this.data.email,
        ).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            this.allow_access = list.data[0].allow_access
              ? list.data[0].allow_access
              : 0;
            this.db_user_id = list.data[0].id;
          }
        });
        this.spinner.hide();
      } catch (error) {
        this.router.navigate(['/login']);
      }
    });
    this.password = '';
    this.Confirmpass = '';
  }
  showpass(item) {
    this.changepasstype = item;
  }
  async ngOnInit() {
    this.LoginForm = this.fb.group(
      {
        password: ['', Validators.required],
        Confirmpass: ['', Validators.required],
        oldpassword: ['', Validators.required],
      },
      {
        validator: ConfirmedValidator('password', 'Confirmpass'),
      },
    );
    await this.auth.CreateUser(this.data.email).then(async (data) => {
      if (data[0].StatusCode == 1) {
        let user = this.data.email;
        let msg = data[0].message;
        let cohort = 'Reverification OTP';
        let email_type = 'Reverification OTP';
        await this.addFailedEmail(user, cohort, msg, email_type, 1);
        await this.spinner.hide();
      } else {
        let user = this.data.email;
        let msg = data[0].message;
        let cohort = 'ReVerification OTP';
        let email_type = 'ReVerification OTP';
        await this.addFailedEmail(user, cohort, msg, email_type, 0);
        await this.spinner.hide();
      }
    });
  }

  get f() {
    return this.LoginForm.controls;
  }

  confirmcheck() {
    if (
      this.password &&
      this.Confirmpass &&
      this.password == this.Confirmpass
    ) {
      this.Checkpassword = true;
    } else {
      this.Checkpassword = false;
    }
  }
  PasswordCheck() {
    if (this.password.length > 0) {
      this.showCriteria = true;
      this.PassWidth = 0;
      this.IsSmall = false;
      this.IsUpper = false;
      this.Number = false;
      this.IsSpecial = false;
      this.PassLength = false;
      this.Checkpassword = false;
      this.PassMsg = 'Poor';
      var cnt = 0;
      if (/[a-z]/.test(this.password)) {
        this.IsSmall = true;
      }
      if (this.password.length >= 8) {
        this.PassLength = true;
      }
      if (
        this.password &&
        this.Confirmpass &&
        this.password == this.Confirmpass
      ) {
        this.Checkpassword = true;
      } else {
        this.Checkpassword = false;
      }

      if (/[A-Z]/.test(this.password)) {
        this.IsUpper = true;
      }

      if (/[0-9]/.test(this.password)) {
        this.Number = true;
      }
      if (/[^a-zA-Z0-9]/.test(this.password)) {
        this.IsSpecial = true;
      }

      if (this.PassLength) {
        this.PassWidth += 20;
      }
      if (this.IsSmall) {
        this.PassWidth += 20;
      }
      if (this.IsUpper) {
        this.PassWidth += 20;
      }
      if (this.Number) {
        this.PassWidth += 20;
      }
      if (this.IsSpecial) {
        this.PassWidth += 20;
      }

      if (this.PassWidth == 20 || this.PassWidth == 40) {
        this.PassMsg = 'Poor';
      }
      if (this.PassWidth == 60 || this.PassWidth == 80) {
        this.PassMsg = 'Good';
      }
      if (this.PassWidth == 100) {
        this.PassMsg = 'Strong';
      }
    } else {
      this.showCriteria = false;
    }
  }

  async resetpassword() {
    try {
      if (this.LoginForm.valid) {
        if (this.PassWidth === 100) {
          await this.spinner.show();
          var email;
          var data = this.data.email;
          email = this.data.email;
          this.email = this.data.email;
          await this.LoginflowService.versions_getUserByEmail(this.email).then(
            async (data) => {
              if (data[0].status == 200) {
                var list = JSON.parse(<string>data[0].json);
                if (list.data.length > 0) {
                  this.email = list.data[0].email;
                  await this.auth
                    .TempsignInprotecttes(
                      email,
                      this.oldpassword,
                      this.password,
                    )
                    .then(async (resetdata) => {
                      if (resetdata[0].StatusCode == 1) {
                        await this.auth
                          .getAuthenticatedUser()
                          .getSession(async (err, session) => {
                            if (err) {
                              this.spinner.hide();
                              this.router.navigate(['/login']);
                            } else {
                              var token = session.getIdToken().getJwtToken();
                              if (resetdata[0].StatusCode == 1) {
                                await this.LoginflowService.UsersFilter(
                                  this.email,
                                  token,
                                ).then(async (user) => {
                                  if (user[0].status == 200) {
                                    var list = JSON.parse(<string>user[0].json);
                                    this.list1 = list;
                                    var updateOnboardingUser = {
                                      email: this.email,
                                      expire_password: '0',
                                    };
                                    await this.LoginflowService.updateUserByEmail(
                                      updateOnboardingUser,
                                    ).then(async (userRes) => {});
                                    await this.LoginflowService.saveAuthyDetails(
                                      list.data.id,
                                      this.data.email,
                                      this.password,
                                      0,
                                      0,
                                    ).then((response) => {});

                                    if (list.data.allow_access == 1) {
                                      this.LoginflowService.loginlog(
                                        list.data.id,
                                        '',
                                        '',
                                        '',
                                      );
                                      localStorage.setItem(
                                        'CisspUserId',
                                        JSON.stringify(list.data.id),
                                      );
                                      localStorage.setItem(
                                        'userdetails',
                                        JSON.stringify(list.data),
                                      );
                                      localStorage.setItem(
                                        'cohort_id',
                                        list.data.onboard_cohort
                                          ? list.data.onboard_cohort
                                          : list.data.course_id,
                                      );
                                      var userdata = {
                                        email: this.email,
                                        password: this.password,
                                        cohort_id: list.data.onboard_cohort
                                          ? list.data.onboard_cohort
                                          : list.data.course_id,
                                      };
                                      var passwordsession = btoa(
                                        JSON.stringify(this.password),
                                      );
                                      window.localStorage.setItem(
                                        'domaindata',
                                        passwordsession,
                                      );

                                      var email = btoa(
                                        JSON.stringify(userdata),
                                      );
                                      email = encodeURIComponent(email);
                                      var uri =
                                        this.route.snapshot.queryParams[
                                          'returnUrl'
                                        ] || null;

                                      if (uri) {
                                        localStorage.setItem(
                                          'CisspUserId',
                                          JSON.stringify(list.data.id),
                                        );
                                        localStorage.setItem(
                                          'userdetails',
                                          JSON.stringify(list.data),
                                        );
                                        window.localStorage.setItem(
                                          'logintrueStudent',
                                          'true',
                                        );
                                        this.cookieService.set(
                                          'cd399e84-2103-11ec-9621-0242ac130002',
                                          'a9fff10f-d50b-4e35-af0d-3ec922d1b53e',
                                        );
                                        await this.listCourses(
                                          list.data.id,
                                          uri,
                                        );
                                      } else {
                                        if (list.data.welcome_flow == 2) {
                                          this.router.navigate([
                                            `/alias/${email}`,
                                          ]);
                                        } else if (
                                          list.data.welcome_flow == 3
                                        ) {
                                          this.router.navigate([
                                            `/timezone/${email}`,
                                          ]);
                                        } else if (
                                          list.data.welcome_flow == 4
                                        ) {
                                          this.router.navigate([
                                            `/protectpass/${email}`,
                                          ]);
                                        } else if (
                                          list.data.welcome_flow == 5
                                        ) {
                                          this.router.navigate([
                                            `/onetimepsas/${email}`,
                                          ]);
                                        } else if (
                                          list.data.welcome_flow == 6
                                        ) {
                                          this.router.navigate([
                                            `/userimage/${email}`,
                                          ]);
                                        } else if (
                                          list.data.welcome_flow == 7
                                        ) {
                                          this.router.navigate([
                                            `/selfpacedvideo/${email}`,
                                          ]);
                                        } else if (
                                          list.data.welcome_flow == 8
                                        ) {
                                          this.router.navigate([
                                            `/customschedule/${email}`,
                                          ]);
                                        } else if (
                                          list.data.welcome_flow == 9
                                        ) {
                                          this.router.navigate([
                                            `/competition/${email}`,
                                          ]);
                                        } else if (
                                          list.data.welcome_flow == 10
                                        ) {
                                          this.router.navigate([
                                            `/learning/${email}`,
                                          ]);
                                        } else if (
                                          list.data.welcome_flow == 11
                                        ) {
                                          this.router.navigate([
                                            `/thankyou/${email}`,
                                          ]);
                                        } else {
                                          window.localStorage.setItem(
                                            'logintrueStudent',
                                            'true',
                                          );
                                          this.cookieService.set(
                                            'cd399e84-2103-11ec-9621-0242ac130002',
                                            'a9fff10f-d50b-4e35-af0d-3ec922d1b53e',
                                          );
                                          this.service.ShowSuccess(
                                            resetdata[0].message,
                                          );
                                          if (list.data.course_id == null) {
                                            this.router.navigate([
                                              'enrolledcourse',
                                            ]);
                                          } else {
                                            if (list.data.show_resources == 1) {
                                              this.service.instructionHint =
                                                false;
                                            } else {
                                              this.service.instructionHint =
                                                true;
                                            }
                                            await this.listCoursesNew(
                                              list.data.id,
                                            );
                                          }
                                          await this.spinner.hide();
                                        }
                                      }
                                    } else {
                                      this.spinner.hide();
                                      this.service.ShowError(
                                        'You are not allowed to access this portal. Kindly contact customer support.',
                                      );
                                      await this.addActivityTracker(
                                        0,
                                        this.email,
                                        0,
                                        'Login Failed',
                                        'Login failed - Not allowed to access this portal',
                                      );
                                    }
                                  } else {
                                    await this.spinner.hide();

                                    this.service.ShowError(
                                      'Encountered server error!',
                                    );
                                  }
                                });
                              }
                            }
                          });
                      } else {
                        if (
                          resetdata[0].message ==
                          'Incorrect username or password.'
                        ) {
                          this.service.ShowError(
                            "Please enter a correct activation code or enter the old password that you have set during the first onboarding process. Incase, you don't remember please try the forget password flow (This only applies when you have completed the reset password flow at-least once.)",
                          );
                        } else {
                          this.service.ShowError(resetdata[0].message);
                        }
                        this.spinner.hide();
                      }
                    });
                } else {
                  this.service.ShowError('User not found');
                  await this.spinner.hide();
                }
              } else {
                this.service.ShowError(data[0].message);
                await this.spinner.hide();
              }
            },
          );
          await this.spinner.hide();
        } else {
          await this.spinner.hide();
        }
      }
    } catch (e) {
      this.spinner.hide();
    }
  }
  async FlashCardPassword() {}

  async addFailedEmail(user, cohort, msg, email_type, mail_success) {
    await this.service
      .addFailedEmail(user, cohort, msg, email_type, mail_success, '')
      .then(async (data) => {});
  }
  async addActivityTracker(user_id, email, cohort_id, type, message) {
    await this.service.addActivityTracker(
      user_id,
      email,
      cohort_id,
      type,
      message,
    );
  }

  async update_onboarding_flow() {
    await this.LoginflowService.versions_getUserByEmail(this.data.email).then(
      async (data) => {
        if (data[0].status == 200) {
          var list = JSON.parse(<string>data[0].json);
          this.db_user_id = list.data[0].id;
          var onboardigdata = {
            user_id: this.db_user_id,
            cohort_id: this.data.cohort_id,
            is_expire: '1',
            welcome_flow: '5',
            link_expire_on: new Date(),
          };
          await this.LoginflowService.update_onboarding_flow(
            onboardigdata,
          ).then((onboardingFlowRes) => {
            if (onboardingFlowRes[0].status == 200) {
              var list = JSON.parse(<string>onboardingFlowRes[0].json);
            } else {
              this.router.navigate(['/login']);
            }
          });
        }
      },
    );
  }
  async listCourses(userid, url) {
    this.courselist = [];
    try {
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();
            await this.CourseserviceService.knowassquecource(
              userid,
              token,
            ).then(async (data) => {
              if (data[0].status == 200) {
                var list = JSON.parse(<string>data[0].json);
                this.courselist = list.data;
                if (this.courselist.length > 1) {
                  this.router.navigate(['enrolledcourse'], {
                    queryParams: { returnUrl: url },
                  });
                } else {
                  await this.CertificationClick(this.courselist[0], url);
                }
              }
              await this.spinner.hide();
            });
          }
        });
    } catch (e) {
      await this.spinner.hide();
      this.service.ShowError(e.meassage);
    }
  }
  async CertificationClick(item, url) {
    this.service.courseid = item.certi_id;
    await this.getResult(item.certi_id, item.cohort_id);
    var chort_id_check = localStorage.getItem('cohort_id');
    localStorage.setItem('CisspCourseId', item.certi_id);
    localStorage.setItem('cohort_id', item.cohort_id);
    localStorage.setItem('overall', item.percentage);
    localStorage.setItem('method', item.method_id);
    localStorage.setItem(
      'course_type',
      item.course_type ? item.course_type : '',
    );
    window.localStorage.setItem('accessitem', JSON.stringify(item.access));
    await this.router.navigateByUrl(url);
  }
  async getResult(cid, cohort_id) {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        let user_id = localStorage.getItem('CisspUserId');
        this.spinner.show();
        await this.CourseserviceService.getDashboardPercentage(
          user_id,
          cid,
          cohort_id,
          token,
        ).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            let domainsData = list.data;
            let sum = 0;
            let i = 0;
            let w = [9, 11, 12, 12, 12, 12, 12, 12];
            domainsData.forEach((element) => {
              element.weight = w[i];
              i++;
              sum += element.totalPro;
            });
            localStorage.setItem('domainsData', JSON.stringify(domainsData));
            this.spinner.hide();
          } else {
            this.spinner.hide();
          }
        });
      }
    });
  }

  async listCoursesNew(userid) {
    this.courselist = [];
    try {
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();
            await this.CourseserviceService.knowassquecource(
              userid,
              token,
            ).then(async (data) => {
              if (data[0].status == 200) {
                var list = JSON.parse(<string>data[0].json);
                this.courselist = list.data;
                let courselistNew = [];
                this.courselist.forEach((element) => {
                  if (element.cohort_id == this.list1.data.course_id) {
                    courselistNew.push(element);
                  }
                });
                if (courselistNew.length > 0)
                  await this.CertificationClickNew(courselistNew[0]);
                else this.router.navigate(['enrolledcourse']);
                await this.spinner.hide();
              } else if (data[0].status == 502) {
                this.listCoursesNew(userid);
              } else {
                await this.spinner.hide();
              }
            });
          }
        });
    } catch (e) {
      await this.spinner.hide();
      this.service.ShowError(e.meassage);
    }
  }
  async CertificationClickNew(item) {
    this.service.courseid = item.certi_id;
    await this.getResult(item.certi_id, item.cohort_id);
    localStorage.setItem('CisspCourseId', item.certi_id);
    localStorage.setItem('cohort_id', item.cohort_id);
    localStorage.setItem('overall', item.percentage);
    localStorage.setItem('method', item.method_id);
    localStorage.setItem(
      'course_type',
      item.course_type ? item.course_type : '',
    );
    window.localStorage.setItem('accessitem', JSON.stringify(item.access));
    await this.router.navigate(['sidenav/dashboard']);
  }
}
