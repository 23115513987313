<div class="main-wrapper">
  <h1>Just a moment...</h1>
  <div class="slider">
    <div class="line"></div>
    <div class="break dot1"></div>
    <div class="break dot2"></div>
    <div class="break dot3"></div>
  </div>
  <p>We're fetching your details</p>
</div>
