<div *ngIf="service.status == 'ONLINE'">
  <div class="auth">
    <div class="login-form">
      <div class="logo">
        <img src="../../assets/cissplogo.png" alt="CISSP" />
      </div>
      <h3>
        <span>Reset Password</span>
      </h3>
      <form class="form" [formGroup]="AuthForm">
        <p class="verifi-text">Verification Code</p>
        <p class="verifi-text-one">
          Please check your email for the verification code
        </p>
        <div class="input-form">
          <input
            type="password"
            name="auth"
            formControlName="Authcode"
            [(ngModel)]="Authcode"
            class="input"
            placeholder="Verification Code"
          />
          <span class="material-icons"> more_horiz </span>
        </div>
        <span>
          <a (click)="resend()" class="forget">Resend verification code </a>
        </span>
        <p class="verifi-text-two">New Password</p>

        <div class="input-form mb-20">
          <input
            type="password"
            name="password"
            formControlName="newpass"
            [(ngModel)]="newpass"
            class="input"
            placeholder="New Password"
            (keyup)="PasswordCheck()"
          />
          <span class="material-icons"> more_horiz </span>
        </div>
        <div class="input-form mb-20">
          <input
            name="Confirmpass"
            [type]="changepasstype"
            (keyup)="PasswordCheck()"
            formControlName="Confirmpass"
            [(ngModel)]="Confirmpass"
            class="input"
            placeholder="Verify Password"
            required
          />
          <span
            class="material-icons-outlined"
            *ngIf="changepasstype == 'password'"
            style="cursor: pointer"
            (click)="showpass('text')"
          >
            visibility</span
          >
          <span
            class="material-icons-outlined"
            *ngIf="changepasstype == 'text'"
            style="cursor: pointer"
            (click)="showpass('password')"
          >
            visibility_off
          </span>
        </div>
        <div class="strength">
          <div class="strengthpro" *ngIf="showCriteria">
            <p>
              Strength: <span>{{ PassMsg }}</span>
            </p>
            <div class="progress">
              <div class="filler" [ngStyle]="{ 'width.%': PassWidth }"></div>
            </div>
            <div class="stronginfo">
              <p>Please use at least:</p>
              <ul>
                <li>
                  <span class="svglock" *ngIf="!PassLength">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.2"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M9 0C13.9706 0 18 4.02944 18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0ZM9 1.3C4.74741 1.3 1.3 4.74741 1.3 9C1.3 13.2526 4.74741 16.7 9 16.7C13.2526 16.7 16.7 13.2526 16.7 9C16.7 4.74741 13.2526 1.3 9 1.3ZM4.98231 8.5842C5.2541 8.30833 5.69806 8.30502 5.97393 8.57681L8.02702 10.5689L12.316 6.19627C12.5908 5.9234 13.0348 5.92496 13.3076 6.19976C13.5805 6.47456 13.579 6.91854 13.3042 7.19141L8.52303 12.0527C8.25036 12.3235 7.81057 12.3243 7.53684 12.0546L4.9897 9.57582C4.71383 9.30403 4.71052 8.86007 4.98231 8.5842Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                  <span class="svglock" *ngIf="PassLength">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M9 0C13.9706 0 18 4.02944 18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0ZM9 1.3C4.74741 1.3 1.3 4.74741 1.3 9C1.3 13.2526 4.74741 16.7 9 16.7C13.2526 16.7 16.7 13.2526 16.7 9C16.7 4.74741 13.2526 1.3 9 1.3ZM4.98231 8.5842C5.2541 8.30833 5.69806 8.30502 5.97393 8.57681L8.02702 10.5689L12.316 6.19627C12.5908 5.9234 13.0348 5.92496 13.3076 6.19976C13.5805 6.47456 13.579 6.91854 13.3042 7.19141L8.52303 12.0527C8.25036 12.3235 7.81057 12.3243 7.53684 12.0546L4.9897 9.57582C4.71383 9.30403 4.71052 8.86007 4.98231 8.5842Z"
                        fill="#944BEB"
                      />
                    </svg> </span
                  >8 Characters
                </li>

                <li>
                  <span class="svglock" *ngIf="!Number">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.2"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M9 0C13.9706 0 18 4.02944 18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0ZM9 1.3C4.74741 1.3 1.3 4.74741 1.3 9C1.3 13.2526 4.74741 16.7 9 16.7C13.2526 16.7 16.7 13.2526 16.7 9C16.7 4.74741 13.2526 1.3 9 1.3ZM4.98231 8.5842C5.2541 8.30833 5.69806 8.30502 5.97393 8.57681L8.02702 10.5689L12.316 6.19627C12.5908 5.9234 13.0348 5.92496 13.3076 6.19976C13.5805 6.47456 13.579 6.91854 13.3042 7.19141L8.52303 12.0527C8.25036 12.3235 7.81057 12.3243 7.53684 12.0546L4.9897 9.57582C4.71383 9.30403 4.71052 8.86007 4.98231 8.5842Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                  <span class="svglock" *ngIf="Number">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M9 0C13.9706 0 18 4.02944 18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0ZM9 1.3C4.74741 1.3 1.3 4.74741 1.3 9C1.3 13.2526 4.74741 16.7 9 16.7C13.2526 16.7 16.7 13.2526 16.7 9C16.7 4.74741 13.2526 1.3 9 1.3ZM4.98231 8.5842C5.2541 8.30833 5.69806 8.30502 5.97393 8.57681L8.02702 10.5689L12.316 6.19627C12.5908 5.9234 13.0348 5.92496 13.3076 6.19976C13.5805 6.47456 13.579 6.91854 13.3042 7.19141L8.52303 12.0527C8.25036 12.3235 7.81057 12.3243 7.53684 12.0546L4.9897 9.57582C4.71383 9.30403 4.71052 8.86007 4.98231 8.5842Z"
                        fill="#944BEB"
                      />
                    </svg>
                  </span>
                  1 Number
                </li>
                <li>
                  <span class="svglock" *ngIf="!IsSpecial">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.2"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M9 0C13.9706 0 18 4.02944 18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0ZM9 1.3C4.74741 1.3 1.3 4.74741 1.3 9C1.3 13.2526 4.74741 16.7 9 16.7C13.2526 16.7 16.7 13.2526 16.7 9C16.7 4.74741 13.2526 1.3 9 1.3ZM4.98231 8.5842C5.2541 8.30833 5.69806 8.30502 5.97393 8.57681L8.02702 10.5689L12.316 6.19627C12.5908 5.9234 13.0348 5.92496 13.3076 6.19976C13.5805 6.47456 13.579 6.91854 13.3042 7.19141L8.52303 12.0527C8.25036 12.3235 7.81057 12.3243 7.53684 12.0546L4.9897 9.57582C4.71383 9.30403 4.71052 8.86007 4.98231 8.5842Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                  <span class="svglock" *ngIf="IsSpecial">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M9 0C13.9706 0 18 4.02944 18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0ZM9 1.3C4.74741 1.3 1.3 4.74741 1.3 9C1.3 13.2526 4.74741 16.7 9 16.7C13.2526 16.7 16.7 13.2526 16.7 9C16.7 4.74741 13.2526 1.3 9 1.3ZM4.98231 8.5842C5.2541 8.30833 5.69806 8.30502 5.97393 8.57681L8.02702 10.5689L12.316 6.19627C12.5908 5.9234 13.0348 5.92496 13.3076 6.19976C13.5805 6.47456 13.579 6.91854 13.3042 7.19141L8.52303 12.0527C8.25036 12.3235 7.81057 12.3243 7.53684 12.0546L4.9897 9.57582C4.71383 9.30403 4.71052 8.86007 4.98231 8.5842Z"
                        fill="#944BEB"
                      />
                    </svg>
                  </span>
                  1 Special character
                </li>
                <li>
                  <span class="svglock" *ngIf="!IsUpper">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.2"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M9 0C13.9706 0 18 4.02944 18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0ZM9 1.3C4.74741 1.3 1.3 4.74741 1.3 9C1.3 13.2526 4.74741 16.7 9 16.7C13.2526 16.7 16.7 13.2526 16.7 9C16.7 4.74741 13.2526 1.3 9 1.3ZM4.98231 8.5842C5.2541 8.30833 5.69806 8.30502 5.97393 8.57681L8.02702 10.5689L12.316 6.19627C12.5908 5.9234 13.0348 5.92496 13.3076 6.19976C13.5805 6.47456 13.579 6.91854 13.3042 7.19141L8.52303 12.0527C8.25036 12.3235 7.81057 12.3243 7.53684 12.0546L4.9897 9.57582C4.71383 9.30403 4.71052 8.86007 4.98231 8.5842Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                  <span class="svglock" *ngIf="IsUpper">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M9 0C13.9706 0 18 4.02944 18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0ZM9 1.3C4.74741 1.3 1.3 4.74741 1.3 9C1.3 13.2526 4.74741 16.7 9 16.7C13.2526 16.7 16.7 13.2526 16.7 9C16.7 4.74741 13.2526 1.3 9 1.3ZM4.98231 8.5842C5.2541 8.30833 5.69806 8.30502 5.97393 8.57681L8.02702 10.5689L12.316 6.19627C12.5908 5.9234 13.0348 5.92496 13.3076 6.19976C13.5805 6.47456 13.579 6.91854 13.3042 7.19141L8.52303 12.0527C8.25036 12.3235 7.81057 12.3243 7.53684 12.0546L4.9897 9.57582C4.71383 9.30403 4.71052 8.86007 4.98231 8.5842Z"
                        fill="#944BEB"
                      />
                    </svg>
                  </span>
                  1 Uppercase letter
                </li>
                <li>
                  <span class="svglock" *ngIf="!IsSmall">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.2"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M9 0C13.9706 0 18 4.02944 18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0ZM9 1.3C4.74741 1.3 1.3 4.74741 1.3 9C1.3 13.2526 4.74741 16.7 9 16.7C13.2526 16.7 16.7 13.2526 16.7 9C16.7 4.74741 13.2526 1.3 9 1.3ZM4.98231 8.5842C5.2541 8.30833 5.69806 8.30502 5.97393 8.57681L8.02702 10.5689L12.316 6.19627C12.5908 5.9234 13.0348 5.92496 13.3076 6.19976C13.5805 6.47456 13.579 6.91854 13.3042 7.19141L8.52303 12.0527C8.25036 12.3235 7.81057 12.3243 7.53684 12.0546L4.9897 9.57582C4.71383 9.30403 4.71052 8.86007 4.98231 8.5842Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                  <span class="svglock" *ngIf="IsSmall">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M9 0C13.9706 0 18 4.02944 18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0ZM9 1.3C4.74741 1.3 1.3 4.74741 1.3 9C1.3 13.2526 4.74741 16.7 9 16.7C13.2526 16.7 16.7 13.2526 16.7 9C16.7 4.74741 13.2526 1.3 9 1.3ZM4.98231 8.5842C5.2541 8.30833 5.69806 8.30502 5.97393 8.57681L8.02702 10.5689L12.316 6.19627C12.5908 5.9234 13.0348 5.92496 13.3076 6.19976C13.5805 6.47456 13.579 6.91854 13.3042 7.19141L8.52303 12.0527C8.25036 12.3235 7.81057 12.3243 7.53684 12.0546L4.9897 9.57582C4.71383 9.30403 4.71052 8.86007 4.98231 8.5842Z"
                        fill="#944BEB"
                      />
                    </svg>
                  </span>
                  1 lowercase letter
                </li>
                <li>
                  <span class="svglock" *ngIf="!Checkpassword">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.2"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M9 0C13.9706 0 18 4.02944 18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0ZM9 1.3C4.74741 1.3 1.3 4.74741 1.3 9C1.3 13.2526 4.74741 16.7 9 16.7C13.2526 16.7 16.7 13.2526 16.7 9C16.7 4.74741 13.2526 1.3 9 1.3ZM4.98231 8.5842C5.2541 8.30833 5.69806 8.30502 5.97393 8.57681L8.02702 10.5689L12.316 6.19627C12.5908 5.9234 13.0348 5.92496 13.3076 6.19976C13.5805 6.47456 13.579 6.91854 13.3042 7.19141L8.52303 12.0527C8.25036 12.3235 7.81057 12.3243 7.53684 12.0546L4.9897 9.57582C4.71383 9.30403 4.71052 8.86007 4.98231 8.5842Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                  <span class="svglock" *ngIf="Checkpassword">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M9 0C13.9706 0 18 4.02944 18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0ZM9 1.3C4.74741 1.3 1.3 4.74741 1.3 9C1.3 13.2526 4.74741 16.7 9 16.7C13.2526 16.7 16.7 13.2526 16.7 9C16.7 4.74741 13.2526 1.3 9 1.3ZM4.98231 8.5842C5.2541 8.30833 5.69806 8.30502 5.97393 8.57681L8.02702 10.5689L12.316 6.19627C12.5908 5.9234 13.0348 5.92496 13.3076 6.19976C13.5805 6.47456 13.579 6.91854 13.3042 7.19141L8.52303 12.0527C8.25036 12.3235 7.81057 12.3243 7.53684 12.0546L4.9897 9.57582C4.71383 9.30403 4.71052 8.86007 4.98231 8.5842Z"
                        fill="#944BEB"
                      />
                    </svg>
                  </span>
                  <span class="passtwo-confi"
                    >New Password and Confirm password fields same</span
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </form>
      <button
        type="submit"
        class="submit"
        [disabled]="PassWidth < 100"
        (click)="ConfimCode()"
      >
        Update Password
      </button>
    </div>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
<app-noconnection></app-noconnection>
