import { Component, OnInit } from '@angular/core';
import { ServicesService } from '../services.service';
import { AuthserviceService } from '../authservice.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { LoginflowService } from '../data/loginflow.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  public SignupForm: FormGroup;
  Username: any;
  email: any;
  password: any;
  confirmpass: any;
  constructor(
    public LoginflowService: LoginflowService,
    public service: ServicesService,
    public auth: AuthserviceService,
    public spinner: NgxSpinnerService,
    public fb: FormBuilder,
    public router: Router,
  ) {}

  ngOnInit() {
    this.SignupForm = this.fb.group({
      Username: ['', Validators.required],
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(
            '^[_A-Za-z0-9-]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9-]+)*((\\.[A-Za-z]{2,4})$)',
          ),
        ]),
      ],
      password: ['', Validators.required],
      confirmpass: ['', Validators.required],
    });
  }
}
