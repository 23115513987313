import { WebsocketService } from './../services/websocket.service';
import { Component, OnInit } from '@angular/core';
import { ServicesService } from '../services.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthserviceService } from '../authservice.service';
import { LivequeserviceService } from '../services/livequestion/livequeservice.service';

@Component({
  selector: 'app-projector',
  templateUrl: './projector.component.html',
  styleUrls: ['./projector.component.scss'],
})
export class ProjectorComponent implements OnInit {
  TestStart: boolean = false;
  Questions = [];
  SingleQue = [];
  QuestionKey = 0;
  TestId: any;
  ShowAnswer: boolean = false;
  AnswerButton = 'Show Answer';

  constructor(
    public socket: WebsocketService,
    public auth: AuthserviceService,
    public service: ServicesService,
    public spinner: NgxSpinnerService,
    public ActiveRoute: ActivatedRoute,
    public router: Router,
    public LivequeserviceService: LivequeserviceService,
  ) {
    this.service.headershow = true;
    this.service.SearchValue = true;

    this.service.title = '';
    var access = window.localStorage.getItem('accessitem');
    if (access) {
      var set = JSON.parse(<string>access);
      if (set) {
        this.service.access.CATaccess = set.CAT_access ? true : false;
        this.service.access.Knowaccess = set.KA_access ? true : false;
        this.service.access.Practiceaccess = set.PT_access ? true : false;
        this.service.access.livechat_access = set.livechat_access
          ? true
          : false;
      }
    }

    this.ActiveRoute.params.subscribe(async (params) => {
      try {
        this.TestId = JSON.parse(atob(decodeURIComponent(params['id'])));
        this.getAllLiveQuestionTestWise(this.TestId.id);
      } catch (error) {
        this.router.navigate(['login']);
      }
    });
  }

  ngOnInit(): void {}
  SocketConnect() {
    let MessageArr = {
      action: 'message',
      msg: {
        action: 'connected',
        user_id: this.TestId.user_id,
        test_id: this.TestId.id,
        roll_id: 1,
        q_id: this.SingleQue[0].id,
        sr_no: 1,
      },
    };
    this.socket.Connect();
    this.socket
      .OnOpen()
      .then((result) => {
        this.socket
          .DoSend(MessageArr)
          .then((result) => {
            let msg = {
              action: 'message',
              msg: {
                action: 'nextq',
                user_id: this.TestId.user_id,
                test_id: 1,
                roll_id: 1,
                q_id: this.SingleQue[0].id,
                sr_no: this.SingleQue[0].QuestionNo,
              },
            };
            this.socket.DoSend(msg);
          })
          .catch((err) => {});
      })
      .catch((err) => {});
  }
  startTest() {
    this.TestStart = true;
    this.SocketConnect();
  }
  async getAllLiveQuestionTestWise(TestId) {
    this.spinner.show();
    await this.LivequeserviceService.GetLiveQuestionTestWise(TestId).then(
      async (data) => {
        if (data[0].status == 200) {
          var list = JSON.parse(<string>data[0].json);
          this.Questions = list.data.questions;
          if (this.Questions.length > 0) {
            let No = 1;
            this.Questions.forEach((element) => {
              element['QuestionNo'] = No++;
            });
            this.SingleQue.push(this.Questions[this.QuestionKey]);
          }
        }
      },
    );
    this.spinner.hide();
  }

  nextQuestion() {
    this.SingleQue = [];
    this.ShowAnswer = false;
    this.AnswerButton = 'Show Answer';
    this.QuestionKey++;
    if (this.QuestionKey < this.Questions.length) {
      this.SingleQue.push(this.Questions[this.QuestionKey]);
    }
    let MessageArr = {
      action: 'message',
      msg: {
        action: 'nextq',
        user_id: this.TestId.user_id,
        test_id: this.TestId.id,
        roll_id: 1,
        q_id: this.SingleQue[0].id,
        sr_no: this.SingleQue[0].QuestionNo,
      },
    };
    this.socket.DoSend(MessageArr).then((result) => {});
  }
  showAnswer() {
    this.ShowAnswer = !this.ShowAnswer;
    this.ShowAnswer
      ? (this.AnswerButton = 'Hide Answer')
      : (this.AnswerButton = 'Show Answer');

    let MessageArr = {
      action: 'message',
      msg: {
        action: 'showans',
        user_id: this.TestId.user_id,
        test_id: this.TestId.id,
        roll_id: 1,
        q_id: this.SingleQue[0].id,
        sr_no: this.SingleQue[0].QuestionNo,
        answer: this.SingleQue[0].answer,
      },
    };
    this.socket.DoSend(MessageArr);
  }
  finishTest() {
    let MessageArr = {
      action: 'message',
      msg: {
        action: 'showans',
        user_id: this.TestId.user_id,
        test_id: this.TestId.id,
        roll_id: 1,
        q_id: this.SingleQue[0].id,
        sr_no: 'true',
        answer: 'true',
      },
    };
    this.socket.DoSend(MessageArr);
  }
}
