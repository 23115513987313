<div class="main-report-div" id="componentID">
  <div class="main-cartificate" id="printable">
    <div class="head-div">
      <div class="title">
        <h2>Certificate of Completion</h2>
      </div>
    </div>
    <div class="label">
      <div class="label-img">
        <img src="../../assets/Label.png" alt="Label" />
        <h1>40 <br /><span>CPE Hours</span></h1>
      </div>
    </div>
    <div class="body-div">
      <div class="student-name">
        <p>This certificate is awarded to</p>
        <h1>{{ certificateDetails }}</h1>
        <span>for successfully completing </span>
        <h3>{{ cohort_name }}</h3>
      </div>
    </div>
    <div class="footer-div">
      <div class="date">
        <h1>{{ expire_Date }}</h1>
        <span>Date of Completion</span>
      </div>
      <div class="logo">
        <img src="../../assets/footer-logo.png" alt="logo" />
      </div>
      <div class="student-names">
        <h1><img src="../../assets/certificate/sign.png" /></h1>
        <span>John Berti & Rob Witcher</span>
      </div>
    </div>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
