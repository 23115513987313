<div class="div-header">
  <div class="logo-div">
    <img src="../../../assets/images/sidebar-logo.png" alt="Logo" />
  </div>
</div>
<div class="div-body">
  <div class="tabs">
    <div class="tab">
      <h2 class="heading">Set the ideal class schedule</h2>
      <h3 class="heading2">How busy is your life?</h3>
      <div class="wayToBusy">
        <div class="indicatore">
          <span class="busy">Way too busy</span>
          <img
            src="../../../assets/images/svg/indicatore.svg"
            class="line"
            alt=""
          />
          <span class="time">I have time</span>
        </div>
      </div>
      <div class="container">
        <div
          class="box"
          [ngClass]="{ activeOption: balance == 1 }"
          (click)="selectOption('balance')"
        >
          <img
            src="../../../assets/images/svg/schedule_radioon.svg"
            class="true material-icons-outlined"
            alt=""
          />
          <img
            src="../../../assets/images/svg/schedule_radiooff.svg"
            class="none material-icons-outlined"
            alt=""
          />
          <span><b>Balance</b></span>
          <span class="dot">------------</span>
          <span><b>2 hrs</b> per day</span>
          <span class="dot">------------</span>
          <span><b>&#8764;8 week</b> course</span>
        </div>
        <div
          class="box"
          [ngClass]="{ activeOption: sprint == 1 }"
          (click)="selectOption('sprint')"
        >
          <img
            src="../../../assets/images/svg/schedule_radioon.svg"
            class="true material-icons-outlined"
            alt=""
          />
          <img
            src="../../../assets/images/svg/schedule_radiooff.svg"
            class="none material-icons-outlined"
            alt=""
          />
          <span><b>Sprint</b></span>
          <span class="dot">------------</span>
          <span><b>4 hrs</b> per day</span>
          <span class="dot">------------</span>
          <span><b>&#8764;4 week</b> course</span>
        </div>
        <div
          class="box"
          [ngClass]="{ activeOption: insane == 1 }"
          (click)="selectOption('insane')"
        >
          <img
            src="../../../assets/images/svg/schedule_radioon.svg"
            class="true material-icons-outlined"
            alt=""
          />
          <img
            src="../../../assets/images/svg/schedule_radiooff.svg"
            class="none material-icons-outlined"
            alt=""
          />
          <span><b>Insane</b></span>
          <span class="dot">------------</span>
          <span><b>14 hrs</b> per day</span>
          <span class="dot">------------</span>
          <span><b>&#8764;10 day</b> bootcamp</span>
        </div>
      </div>
      <input
        type="checkbox"
        id="weekchck1"
        [(ngModel)]="SelfCheckBox"
        (click)="customScheduleClick()"
      />
      <label
        class="tab-label"
        for="weekchck1"
        [ngClass]="{ CustomeOption: custome == 1 }"
        (click)="SelectCustome('custome')"
      >
        <img
          src="../../../assets/images/svg/schedule_radioon.svg"
          class="true"
          alt=""
        />
        <img
          src="../../../assets/images/svg/schedule_radiooff.svg"
          class="none"
          alt=""
        />
        Create your own custom schedule
      </label>
      <div class="tab-content">
        <div class="custom-schedule">
          <div class="exam-date">
            <div class="title">
              <span>When do you want to start studying?</span>
            </div>
            <div class="select-Edate">
              <div class="name-input calendar-image">
                <mat-form-field appearance="fill">
                  <input
                    matInput
                    [matDatepicker]="picker2"
                    [min]="HeaderStartDate"
                    [max]="ExpiryDate"
                    placeholder="Start Date"
                    [(ngModel)]="study_date"
                    (dateChange)="selectDate()"
                    disabled
                    class="placeholder-date"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker2"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker2 disabled="false"></mat-datepicker>
                </mat-form-field>
                <span>
                  <img
                    src="../../../assets/images/svg/Calendar.svg"
                    class="calendar-icon"
                    alt=""
                  />
                </span>
              </div>
              <div class="tooltip">
                <span class="material-icons-outlined">info</span>
                <div class="tooltip-detail">
                  <p>
                    You can edit this date at anytime and create an updated
                    course schedule for yourself.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="exam-date">
            <div class="title">
              <span>Your planned exam date?</span>
            </div>
            <div class="select-Edate">
              <div class="name-input calendar-image">
                <mat-form-field appearance="fill">
                  <input
                    matInput
                    [matDatepicker]="picker1"
                    [min]="study_date"
                    [max]="ExpiryDate"
                    placeholder="Exam Date"
                    [(ngModel)]="exam_date"
                    (dateChange)="selectDate()"
                    disabled
                    class="placeholder-date"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="picker1"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker1 disabled="false"></mat-datepicker>
                </mat-form-field>
                <span>
                  <img
                    src="../../../assets/images/svg/Calendar.svg"
                    class="calendar-icon"
                    alt=""
                  />
                </span>
              </div>
              <div class="tooltip">
                <span class="material-icons-outlined">info</span>
                <div class="tooltip-detail">
                  <p>
                    If you don't have your exam booked, you can use an
                    approximate date of when you would like to take the exam.
                  </p>
                  <p>
                    You can edit this date at anytime and create an updated
                    course schedule for yourself.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="date-hours">
            <div class="exam-date">
              <div class="title">
                <span>Which days of the week do you want to study?</span>
              </div>
              <div class="select-week-name">
                <div
                  class="week-box"
                  [ngClass]="{ activeWeekName: sun == 1 }"
                  (click)="selectDay('sun')"
                >
                  <span class="week-name">Sunday</span>
                  <span class="true material-icons-outlined">
                    check_circle
                  </span>
                  <span class="none material-icons-outlined">
                    radio_button_unchecked
                  </span>
                </div>
                <div
                  class="week-box"
                  [ngClass]="{ activeWeekName: mon == 1 }"
                  (click)="selectDay('mon')"
                >
                  <span class="week-name">Monday</span>
                  <span class="true material-icons-outlined">
                    check_circle
                  </span>
                  <span class="none material-icons-outlined">
                    radio_button_unchecked
                  </span>
                </div>
                <div
                  class="week-box"
                  [ngClass]="{ activeWeekName: tues == 1 }"
                  (click)="selectDay('tues')"
                >
                  <span class="week-name">Tuesday</span>
                  <span class="true material-icons-outlined">
                    check_circle
                  </span>
                  <span class="none material-icons-outlined">
                    radio_button_unchecked
                  </span>
                </div>
                <div
                  class="week-box"
                  [ngClass]="{ activeWeekName: wed == 1 }"
                  (click)="selectDay('wed')"
                >
                  <span class="week-name">Wednesday</span>
                  <span class="true material-icons-outlined">
                    check_circle
                  </span>
                  <span class="none material-icons-outlined">
                    radio_button_unchecked
                  </span>
                </div>
                <div
                  class="week-box"
                  [ngClass]="{ activeWeekName: thurs == 1 }"
                  (click)="selectDay('thurs')"
                >
                  <span class="week-name">Thursday</span>
                  <span class="true material-icons-outlined">
                    check_circle
                  </span>
                  <span class="none material-icons-outlined">
                    radio_button_unchecked
                  </span>
                </div>
                <div
                  class="week-box"
                  [ngClass]="{ activeWeekName: fri == 1 }"
                  (click)="selectDay('fri')"
                >
                  <span class="week-name">Friday</span>
                  <span class="true material-icons-outlined">
                    check_circle
                  </span>
                  <span class="none material-icons-outlined">
                    radio_button_unchecked
                  </span>
                </div>
                <div
                  class="week-box"
                  [ngClass]="{ activeWeekName: sat == 1 }"
                  (click)="selectDay('sat')"
                >
                  <span class="week-name">Saturday</span>
                  <span class="true material-icons-outlined">
                    check_circle
                  </span>
                  <span class="none material-icons-outlined">
                    radio_button_unchecked
                  </span>
                </div>
              </div>
            </div>
            <div class="exam-date">
              <div class="title">
                <span>How many hours per day do you want to study?</span>
              </div>
              <div class="select-week-name select-hours">
                <div class="week-box" [ngClass]="{ activeHours: sun == 1 }">
                  <span class="hours-hrs">HRS</span>
                  <input
                    type="number"
                    [disabled]="sun == 0"
                    [(ngModel)]="hsun"
                    (keyup)="validateNumber($event, 'sun')"
                  />
                </div>
                <div class="week-box" [ngClass]="{ activeHours: mon == 1 }">
                  <span class="hours-hrs">HRS</span>
                  <input
                    type="number"
                    [disabled]="mon == 0"
                    [(ngModel)]="hmon"
                    (keyup)="validateNumber($event, 'mon')"
                  />
                </div>
                <div class="week-box" [ngClass]="{ activeHours: tues == 1 }">
                  <span class="hours-hrs">HRS</span>
                  <input
                    type="number"
                    [disabled]="tues == 0"
                    [(ngModel)]="htues"
                    (keyup)="validateNumber($event, 'tues')"
                  />
                </div>
                <div class="week-box" [ngClass]="{ activeHours: wed == 1 }">
                  <span class="hours-hrs">HRS</span>
                  <input
                    type="number"
                    [disabled]="wed == 0"
                    [(ngModel)]="hwed"
                    (keyup)="validateNumber($event, 'wed')"
                  />
                </div>
                <div class="week-box" [ngClass]="{ activeHours: thurs == 1 }">
                  <span class="hours-hrs">HRS</span>
                  <input
                    type="number"
                    [disabled]="thurs == 0"
                    [(ngModel)]="hthurs"
                    (keyup)="validateNumber($event, 'thurs')"
                  />
                </div>
                <div class="week-box" [ngClass]="{ activeHours: fri == 1 }">
                  <span class="hours-hrs">HRS</span>
                  <input
                    type="number"
                    [disabled]="fri == 0"
                    [(ngModel)]="hfri"
                    (keyup)="validateNumber($event, 'fri')"
                  />
                </div>
                <div class="week-box" [ngClass]="{ activeHours: sat == 1 }">
                  <span class="hours-hrs">HRS</span>
                  <input
                    type="number"
                    [disabled]="sat == 0"
                    [(ngModel)]="hsat"
                    (keyup)="validateNumber($event, 'sat')"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="message" *ngIf="ShowInvalidDate">
            <p class="invalidDate">
              <span>Uhh-ohh!</span> Planned exam date cannot be before start
              study date.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="div-footer">
  <div class="exam-footer">
    <button
      class="submitBtn"
      (click)="generateSchedule()"
      [disabled]="misMatchSchedule"
    >
      Continue
    </button>
  </div>
</div>

<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
